import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Input, Pagination } from 'antd';
import { MdSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/apiClient';
import { IoIosSearch } from 'react-icons/io';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalVerBoloes from '../ModalVerBoloes';
import ModalVerBoloesPrint from '../ModalVerBoloesPrint';
import VerApotas from '../VerApotas';
import styles from './style.module.scss';

const Compras = ({ arrayApostas, setCardSelected, setShowTableBoloes, handleSelect, scrollToSection, comprasRef }) => {
  const [usuarioData, setUsuarioData] = useState([]);
  const pageSize = 10; // Número de itens por página
  const [loading, setLoading] = useState(false);
  const [regra, setRegra] = useState();
  const [cotas, setCotas] = useState();
  const [apostasQuantidade, setApostasQuantidade] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bolaoComprado, setBolaoComprado] = useState([]);
  const [modalVerApostas, setModalVerApostas] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual

  const navigate = useNavigate();
  
  // Criar referência para a tabela
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/get_compras/${pageIndex}`);
        if (response?.data) {
          const compras = response.data.compras;
          const total = response.data.total;
          const perPage = response.data.per_page;

          setUsuarioData(compras);
          setTotalItems(total);
          setPerPage(perPage);
        } else {
          toast.error('Erro ao buscar dados: resposta inválida');
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
        toast.error('Erro ao buscar dados');
        setLoading(false);
      }
    };

    fetchData();
  }, [pageIndex]);

  useEffect(() => {
    // Rolagem para a tabela quando o componente é montado ou o estado muda
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [tableRef]);

  const changePage = () => {
    setShowTableBoloes(true);
    setCardSelected('padrao');
    handleSelect('padrao');
    scrollToSection(comprasRef)
  };

  const columns = [
    {
      title: 'Ver Bolões',
      dataIndex: 'ver',
      key: '',
      render: (value, record) => (record.produto === 'Bolões' ? record.status === 'cancelled' ? <></> : <Button icon={<MdSearch />} onClick={changePage}></Button> : <></>),
    },
    {
      title: 'Data',
      dataIndex: 'data_pedido',
      key: 'data_pedido',
      render: (text) => moment(text).format('DD/MM/YY'),
    },
    {
      title: 'Descrição',
      dataIndex: 'produto',
      key: 'produto',
    },
    {
      title: 'Compra',
      dataIndex: 'grupo',
      key: 'grupo',
      render: (value) => `${value || 'Saldo'}`,
    },
    {
      title: 'Quantidade',
      dataIndex: 'cotas',
      key: 'cotas',
      render: (value) => `${value > 0 ? value : 'Saldo'}`,
    },
    {
      title: 'Valor cota',
      dataIndex: 'valor_cota',
      key: 'valor_cota',
      render: (value, record) => {
        return record.valor_cota ? record.valor_cota?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$0,00';
      },
    },
    {
      title: 'Desconto',
      dataIndex: 'valor_desconto',
      key: 'valor_desconto',
      render: (value, record) => {
        if (record.produto !== 'Bolões') return 'R$0,00';
        const porcentagem_desconto = ((record.total_produto - record.total_compra) / record.total_produto) * 100;
        let valorTotalCotas = parseFloat(record.cotas) * record.valor_cota;

        let valorDesconto = valorTotalCotas * (porcentagem_desconto / 100);

        let valorCompra = valorDesconto;
        const valorCompraFormatted = valorCompra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        return valorCompraFormatted || 'R$0,00';
      },
    },
    {
      title: 'Valor total',
      dataIndex: 'total',
      key: 'total',
      render: (value, record) => {
        if (record.produto !== 'Bolões') return record.total_compra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

        const porcentagem_desconto = ((record.total_produto - record.total_compra) / record.total_produto) * 100;

        let valorTotalCotas = parseFloat(record.cotas) * record.valor_cota;
        let valorComDesconto = valorTotalCotas - valorTotalCotas * (porcentagem_desconto / 100);

        let valorCompra = valorComDesconto;

        return valorCompra.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
    },
    {
      title: 'Pagamento',
      dataIndex: 'tipo_pagamento',
      key: 'tipo_pagamento',
      render: (value) => {
        const paymentMethods = {
          saldo: 'Saldo',
          credit_card: 'Cartão de crédito',
          pix: 'Pix',
          boleto: 'Boleto',
        };
        return paymentMethods[value] || value;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        if (value === 'approved' || value === 4) {
          return <p className={styles.aprovado}>Aprovado</p>;
        } else if (value === 'pending') {
          return <p className={styles.pendente}>Pendente</p>;
        } else if (value === 'cancelled' || value === 2) {
          return <p className={styles.cancelado}>Cancelado</p>;
        } else {
          return <p className={styles.cancelado}>{value}</p>;
        }
      },
    },
    
  ];

  const formatPrize = (value) => {
    if (!value) return;
    let numberPart,
      wordPart = '';
    if (value >= 1000000) {
      numberPart = (value / 1000000).toFixed(1);
      wordPart = numberPart > 1 ? 'Milhões' : 'Milhão';
    } else if (value >= 1000) {
      numberPart = (value / 1000).toFixed(1);
      wordPart = 'Mil';
    } else {
      numberPart = value.toString();
    }
    return { number: numberPart, word: wordPart };
  };

  const closeModal = () => setIsModalVisible(false);
  const closeModalApostas = () => setModalVerApostas(false);

  const coresTitulo = {
    'MEGA SENA': '#29705e',
    'MEGA-SENA': '#29705e',
    LOTOFÁCIL: '#9915ba',
    'LOTOFÁCIL INDEPENDÊNCIA': '#9915ba',
    QUINA: '#06387c',
    LOTOMANIA: '#f78100',
    TIMEMANIA: '#01d001',
    'DUPLA SENA': '#a61324',
    'DUPLA SENA DE PÁSCOA': '#6b000c',
    'DUPLA-SENA': '#a61324',
    'DIA DE SORTE': '#cb852b',
    'MEGA DA VIRADA': '#228f34',
    'LOTOFÁCIL FINAL ZERO': '#4f034a',
    'LOTOFÁCIL DA INDEPENDÊNCIA': '#4f034a',
    'SUPER SETE': '#A8CF44',
    'QUINA SÃO JOÃO': '#001999',
  };

  const [apostasBuscarBolao, setApostasBuscarBolao] = useState([]);
  const [concursoSelecionado, setConcursoSelecionado] = useState(false);

  const modalSetConcursoAndState = async (concurso) => {
    setModalVerApostas(true);
    try {
      const apostas = await api.get(`/boloes_get/${concurso.jogo_id}`);
      const resultados = apostas.data.boloes;
      const filteredBoloesArray = resultados.filter((b) => b.codigo === concurso.codigo);
      setApostasBuscarBolao(filteredBoloesArray);
    } catch (error) {
      console.error('Erro ao buscar dados', error);
    }
    setConcursoSelecionado(concurso);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (searchValue.trim() === '') {
      // Se o campo de pesquisa estiver vazio, mostre os dados padrão
      setFilteredData(usuarioData);
    } else {
      handleSearch(searchValue);
    }
  }, [searchValue, currentPage, usuarioData]);

  const handleSearch = async (searchValue) => {
    setLoading(true);
    try {
      // Buscar apostas filtradas
      const responseApostas = await api.get(`/get_apostas_cliente/0/${searchValue}`);
      console.log('responseApostas', responseApostas)
      setFilteredData(responseApostas.data.apostas || []);
      setTotalItems(responseApostas.data.total || 0);
    } catch (error) {
      console.error('Erro ao buscar dados filtrados:', error.message);
      toast.error('Erro ao buscar dados filtrados');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Suas compras</h1>

      <Input
        className={styles.filterInput}
        size="large"
        prefix={<IoIosSearch />}
        placeholder="Pesquisar"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onPressEnter={() => handleSearch(searchValue)} // Atualizar ao pressionar Enter
      />
      
      {/* Adicionando a referência ao contêiner da tabela */}
      <div ref={tableRef}>
        <Table
          className={styles.tabelaResgate}
          loading={loading}
          dataSource={filteredData.length > 0 ? filteredData : usuarioData}
          columns={columns}
          pagination={false}
          rowKey='compra_id'
        />
      </div>

      <Pagination
        current={pageIndex} // Atualiza a página atual
        total={totalItems}
        pageSize={perPage}
        onChange={handleChangePage} // Altera a página quando clicado
      />

      <ModalVerBoloesPrint
        nome={bolaoComprado.loteria_nome}
        color={coresTitulo[bolaoComprado.loteria_nome]}
        regra={regra}
        concurso={bolaoComprado.concurso}
        dataSorteio={bolaoComprado.data_sorteio}
        valor={`${formatPrize(bolaoComprado.premiacao_loteria)?.number} ${formatPrize(bolaoComprado.premiacao_loteria)?.word}`}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        cotas={cotas}
        setModalVerApostas={setModalVerApostas}
        urlImpressao={bolaoComprado.printCard}
        quantidadeApostas={apostasQuantidade}
      />
      <VerApotas
        concursoSelecionado={bolaoComprado.concurso}
        color={coresTitulo[bolaoComprado.loteria_nome]}
        bolaoName={bolaoComprado.loteria_nome}
        apostas={apostasBuscarBolao}
        isModalVisible={modalVerApostas}
        closeModal={closeModalApostas}
      />
    </div>
  );
};

export default Compras;
