import React from 'react';
import styles from './style.module.scss';
import { Button } from 'antd';
import { MdShoppingCart } from 'react-icons/md';
import { FaUser, FaRegCheckCircle } from 'react-icons/fa';
import { IoCardOutline } from 'react-icons/io5';

const LabelStepsCarrinho = ({ step, onChangeStep, labelConta = true, labelCarrinho = true }) => {
  return (
    <div className={styles.container}>
      {labelCarrinho && (
        // <button >
          <h2  onClick={() => onChangeStep(0)} className={`${styles.containerStep} ${step === 0 ? styles.stepSelected : ''}`}>
            <MdShoppingCart /> Carrinho
          </h2>
        // </button>
      )}
      {labelConta && (
        <h2  onClick={() => onChangeStep(1)} className={`${styles.containerStep} ${step === 1 ? styles.stepSelected : ''}`}>
          <FaUser /> Minha conta
        </h2>
      )}
      <h2 className={`${styles.containerStep} ${step === 2 ? styles.stepSelected : ''}`}>
        <IoCardOutline /> Pagamento
      </h2>
      <h2 className={`${styles.containerStep} ${step === 3 ? styles.stepSelected : ''}`}>
        <FaRegCheckCircle /> Conclusão
      </h2>
    </div>
  );
};

export default LabelStepsCarrinho;
