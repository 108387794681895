import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { Button, Col, Row, Spin, Badge, message } from 'antd';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/apiClient';

const BoloesHome = ({ boloes }) => {
  const navigate = useNavigate();

  const [dataRanking, setDataRankin] = useState([]);

  useEffect(() => {
    fetchRanking();
  }, []);

  const fetchRanking = async () => {
    try {
      const response = await api.get('/jogos_especial_get');
      setDataRankin(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const hexToRGBA = (hex, opacity) => {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const isToday = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);

    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
  };

  const formatPrize = (value) => {
    let numberPart;
    let wordPart;

    if (value >= 1000000) {
      numberPart = (value / 1000000).toFixed(1);
      wordPart = numberPart > 1 ? 'Milhões' : 'Milhão';
    } else if (value >= 1000) {
      numberPart = (value / 1000).toFixed(1);
      numberPart = numberPart.endsWith('.0') ? numberPart.slice(0, -2) : numberPart;
      wordPart = 'Mil';
    } else {
      numberPart = value.toString();
      wordPart = '';
    }

    return { number: numberPart, word: wordPart };
  };

  const handleBetClick = (id) => {
    navigate(`/bolao/${id}`);
  };

  // Ordena os bolões para que os especiais apareçam primeiro
  const sortedBoloes = boloes.sort((a, b) => b.especial - a.especial);

  return (
    <div className={styles.container}>
      <h1>Participe dos bolões mais inteligentes e fique ainda mais perto do seu sonho!</h1>
      <h3>Nossos bolões possuem alta chance de premiação e estão alinhados com as estatísticas de cada loteria. Escolha o seu e faça sua aposta!</h3>

      {/* <div>
        <div>
          <div className={styles.containerRanking}>
            <div className={styles.containerRankingHeader}>
              <div className={styles.bolaoRanking}>
                <h1>LOTOFÁCIL INDEPENDÊNCIA</h1>
                <h2>200</h2>
                <h2>Milhoes</h2>
                <div className={styles.ticket}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
                    <path d="M-7.86805e-07 18C2.38695 18 4.67613 17.0518 6.36396 15.364C8.05179 13.6761 9 11.3869 9 9C9 6.61305 8.05179 4.32387 6.36396 2.63604C4.67613 0.948212 2.38695 7.13537e-08 1.35896e-06 -3.93402e-07L-3.93402e-07 9L-7.86805e-07 18Z" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <ellipse cx="5.5" cy="5.5" rx="5.5" ry="5.5" transform="rotate(90 5.5 5.5)" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <ellipse cx="5.5" cy="5.5" rx="5.5" ry="5.5" transform="rotate(90 5.5 5.5)" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <ellipse cx="5.5" cy="5.5" rx="5.5" ry="5.5" transform="rotate(90 5.5 5.5)" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <ellipse cx="5.5" cy="5.5" rx="5.5" ry="5.5" transform="rotate(90 5.5 5.5)" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <ellipse cx="5.5" cy="5.5" rx="5.5" ry="5.5" transform="rotate(90 5.5 5.5)" fill="white" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none">
                    <path d="M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 1.8021e-07 6.61305 0 9C-1.8021e-07 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18L9 9V0Z" fill="white" />
                  </svg>
                </div>

                <h3>Participe agora</h3>
              </div>
              <div className={styles.descricaoRanking}>
                <div className={styles.rowRankingTitulo}>
                  <h2>Lotofácil Independência </h2>
                  <h2>R$ 1.000 de PIX</h2>
                  <h2>R$ 1.000 de SALDO</h2>
                </div>
                <h2>Aposte na <strong>Lotofácil Independência</strong>  e ganhe muito mais com a Tribo. Com nosso sistema de "Ranking" você pode ganhar <strong>R$ 1.000,00 em PIX e R$ 1.000,00 em SALDO</strong>, é bem simples:</h2>
                <ul>
                  <li>Você acompanha em tempo real todo o Ranking.</li>
                  <li>Quem já havia adquirido os bolões da LOTOFÁCIL INDEPENDÊNCIA antes dessa promoção também esta concorrendo.</li>
                  <li>Não perde mais tempo e seja o PRIMEIRO nesse Ranking.</li>
                </ul>
              </div>
            </div>
            <h2>RANKING</h2>
            <div className={styles.rowRanking}>
              {dataRanking[0]?.dadosAdicionais.map((item, index) => (
                <div key={index} className={styles.containerPersonRanking}>
                  <h1 className={styles.imagen}>{item.nome.substring(0, 2).toUpperCase()}</h1>
                  <h3>#{item.ordem}</h3>
                  <div>
                    <h4>{item.nome}</h4>
                    <h4>{item.soma_cotas} cotas</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>


        </div>
      </div> */}

      {!boloes ||
        (boloes.length === 0 && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '2rem 0' }}>
            <Spin size='large' />
          </div>
        ))}

      <Row gutter={[24, 24]} className={styles.boloesGrid}>
        {sortedBoloes.map((bolao) => (
          <Col key={bolao.id} xs={24} sm={12} md={8} lg={6} xl={6}>
            {bolao.especial === 1 ? (
              <Badge.Ribbon placement='end' text='ESPECIAL' style={{ top: '50px' }} dot={false}>
                <div className={styles.card} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.1), color: bolao.cor }}>
                  <div className={styles.headerCard} style={{ backgroundColor: bolao.cor }}>
                    <h2 className={styles.cardTitle}>{bolao.nome}</h2>
                  </div>
                  {bolao.acumulou === 1 ? (
                    <p className={styles.acumulado} style={{ borderColor: bolao.cor, color: bolao.cor }}>
                      Acumulado
                    </p>
                  ) : (
                    <p className={styles.acumuladoNaoExiste}></p>
                  )}
                  {isToday(bolao.data_sorteio) ? <p className={styles.bolaoHoje}>Hoje</p> : <p className={styles.bolaoHojeNaoExiste}></p>}
                  <div className={styles.containerValor}>
                    <p className={styles.cardValue} style={{ color: bolao.cor }}>
                      {formatPrize(bolao.premiacao).number}
                    </p>
                    <div className={styles.cardUnit} style={{ color: bolao.cor }}>
                      {formatPrize(bolao.premiacao).word}
                    </div>
                  </div>

                  <div className={styles.containerDadosConc} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.08) }}>
                    <p className={styles.cardDate}>Conc: {bolao.concurso}</p>
                    <span>
                      <FaRegCalendarAlt />
                      {formatDate(bolao.data_sorteio)}
                    </span>
                  </div>
                  <Button type='primary' className={styles.betButton} onClick={() => handleBetClick(bolao.id)}>
                    Ver Bolões
                  </Button>
                </div>
              </Badge.Ribbon>
            ) : (
              <div className={styles.card} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.1), color: bolao.cor }}>
                <div className={styles.headerCard} style={{ backgroundColor: bolao.cor }}>
                  <h2 className={styles.cardTitle}>{bolao.nome}</h2>
                </div>
                {bolao.acumulou === 1 ? (
                  <p className={styles.acumulado} style={{ borderColor: bolao.cor, color: bolao.cor }}>
                    Acumulado
                  </p>
                ) : (
                  <p className={styles.acumuladoNaoExiste}></p>
                )}

                <div className={styles.containerValor}>
                  <p className={styles.cardValue} style={{ color: bolao.cor }}>
                    {formatPrize(bolao.premiacao).number}
                  </p>
                  <div className={styles.cardUnit} style={{ color: bolao.cor }}>
                    {formatPrize(bolao.premiacao).word}
                  </div>
                </div>

                <div className={styles.containerDadosConc} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.08), display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className={styles.cardDate}>Conc: {bolao.concurso}</p>
                  <span>
                    <FaRegCalendarAlt />
                    {formatDate(bolao.data_sorteio)}
                  </span>
                  {isToday(bolao.data_sorteio) ? <p className={styles.bolaoHoje}>Hoje</p> : <p className={styles.bolaoHojeNaoExiste}></p>}
                </div>

                <Button type='primary' className={styles.betButton} onClick={() => handleBetClick(bolao.id)}>
                  Ver Bolões
                </Button>
              </div>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BoloesHome;
