import React from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import { FaRegBuilding } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { GiInjustice } from "react-icons/gi";

const PoliticaPrivacidade = () => {
  return (
    <div className={styles.container}>
      <Row justify='center'>
        <Col span={18}>
          <div>

            <h1><svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
              <path d="M8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16ZM14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM4.9 5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7H4.9V5ZM14 19H2V9H14V19Z" fill="black" />
            </svg> Política de Privacidade e Cookies</h1>
          </div>
          <p>
            A <b>TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO ONLINE EIRELI</b>, pessoa jurídica de direito privado devidamente inscrita junto ao CNPJ/MF sob o nº 33.430.892/0001-10, com sede social na Rua Aredio Santana de Andrade, 764, Bairro Alto
            Boa vista, CEP 38200-000, na cidade de Frutal-MG, doravante designada simplesmente “Tribo da Sorte”, tem por OBJETIVO apresentar a POLÍTICA DE PRIVACIDADE aplicável à sua plataforma, por meio do qual é possível acessar os serviços
            prestados e os produtos oferecidos aos seus Usuários por intermédio de nossa plataforma (website), www.tribodasorte.com.br, no caso “Você”.
          </p>

          <p>Nesta Política explicamos como tratamos os dados pessoais de nossos usuários na forma da legislação.</p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.54 5C6.6 5.89 6.75 6.76 6.99 7.59L5.79 8.79C5.38 7.59 5.12 6.32 5.03 5H6.54ZM16.4 17.02C17.25 17.26 18.12 17.41 19 17.47V18.96C17.68 18.87 16.41 18.61 15.2 18.21L16.4 17.02ZM7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.51C21 15.96 20.55 15.51 20 15.51C18.76 15.51 17.55 15.31 16.43 14.94C16.3307 14.904 16.2256 14.887 16.12 14.89C15.86 14.89 15.61 14.99 15.41 15.18L13.21 17.38C10.3754 15.9304 8.06961 13.6246 6.62 10.79L8.82 8.59C9.1 8.31 9.18 7.92 9.07 7.57C8.69065 6.41806 8.49821 5.2128 8.5 4C8.5 3.45 8.05 3 7.5 3Z" fill="black" />
            </svg>
            <h2>  Quer falar com nosso Canal de Atendimento ao Titular de Dados?</h2>
            <a className={styles.buttonFalar} href='https://api.whatsapp.com/send?phone=5534991294718&amp;text'>
              Clique aqui!
            </a>
          </div>
          <div className={styles.row}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21" fill="none">
              <path d="M8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16ZM14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM4.9 5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7H4.9V5ZM14 19H2V9H14V19Z" fill="black" />
            </svg>
            <h2>  PRIVACIDADE É UM VALOR IMPRESCINDÍVEL PARA NÓS!</h2>
          </div>
          <div className={styles.row}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6.22998 20.23L7.99998 22L18 12L7.99998 2L6.22998 3.77L14.46 12L6.22998 20.23Z" fill="black" />
            </svg>
            <p>Este documento pode ser impresso para fins de consulta, através do comando imprimir nas configurações de seu navegador.</p>
          </div>
          <div className={styles.row}>
            <FaRegBuilding size={20} />
            <h2>RESPONSÁVEL PELO TRATAMENTO:</h2>
          </div>
          <div className={styles.row}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
              <path d="M6.22998 20.23L7.99998 22L18 12L7.99998 2L6.22998 3.77L14.46 12L6.22998 20.23Z" fill="black" />
            </svg>
            <p>
              <b>TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO ONLINE EIRELI</b> CNPJ/MF nº 33.430.892/0001-10, na Rua Aredio Santana de Andrade, 764, Bairro Alto Boa vista, CEP 38200-000, na cidade de Frutal-MG.
            </p>
          </div>

          <h1>Tipos de dados coletados</h1>
          <p>Entre os tipos de Dados Pessoais que este Site coleta, por si mesmo ou através de terceiros, existem os seguintes: Rastreador e Dados de uso.</p>
          <p>Detalhes completos sobre cada tipo de Dados Pessoais coletados são fornecidos nas seções dedicadas desta política de privacidade ou por textos explicativos específicos exibidos antes da coleta de Dados</p>
          <p>Os Dados Pessoais poderão ser fornecidos livremente pelo Usuário, ou, no caso dos Dados de Utilização, coletados automaticamente ao se utilizar este Site.</p>
          <p>A menos que especificado diferentemente todos os Dados solicitados por este Site são obrigatórios e a falta de fornecimento destes Dados poderá impossibilitar este Site de fornecer os seus Serviços.</p>
          <p>Nos casos em que este Site afirmar especificamente que alguns Dados não forem obrigatórios, os Usuários ficam livres para deixarem de comunicar estes Dados sem nenhuma consequência para a disponibilidade ou o funcionamento do Serviço.</p>
          <p>Os Usuários que tiverem dúvidas a respeito de quais Dados Pessoais são obrigatórios estão convidados a entrar em contato com a TRIBODASORTE por meio de seu time de privacidade.</p>
          <p>Quaisquer usos de cookies – ou de outras ferramentas de rastreamento – por este Site ou pelos proprietários de serviços terceiros utilizados por este Site serão para a finalidade de fornecer os Serviços solicitados pelo Usuário, além das demais finalidades descritas no presente documento e na Política de Cookies, se estiver disponível.</p>
          <h1>Quadro de resumos</h1>
          <table>
            <tr>
              <th>Natureza dos dados tratados</th>
              <td>Dados pessoais fornecidos pelo usuário e/ou coletados automaticamente.</td>
            </tr>
            <tr>
              <th>Finalidade como controladora</th>
              <td>Utilizar os dados pessoais para fins de cadastro. *Base legal: Consentimento do titular e cumprimento de contrato.</td>
            </tr>
            <tr>
              <th>Compartilhamento</th>
              <td>Operadores e fornecedores de serviços essenciais para nossas atividades.</td>
            </tr>
            <tr>
              <th>Seus direitos</th>
              <td>Confirmação da existência de tratamento, acesso, correção, desativação, entre outros.</td>
            </tr>
            <tr>
              <th>Contato</th>
              <td>+55 34 9779-8150</td>
            </tr>
          </table>

          <h1>Os tipos de dados que utilizamos</h1>
          <p>A TRIBO poderá coletar as informações inseridas ativamente pelo Usuário no momento do cadastro e, ainda, informações coletadas automaticamente quando da utilização das páginas e da rede, como, por exemplo:</p>
          <ul>
            <li>IP</li>
            <li>Data e hora da conexão</li>
          </ul>
          <p>Há, assim, o tratamento de dois tipos de dados pessoais: (i) aqueles fornecidos pelo próprio Usuário; e (ii) aqueles coletados automaticamente.</p>

          <p><b>(i) Informações fornecidas pelo Usuário:</b></p>

          <p>A TRIBO coleta todas as informações inseridas ativamente pelo Usuário nas Páginas, tais como:</p>
          <ul>
            <li>Nome completo</li>
            <li>E-mail quando do preenchimento de formulários nas Páginas pelo Usuário.</li>
            <li>Data de nascimento</li>
            <li>CPF</li>
            <li>N° de celular</li>
          </ul>
          <p>A TRIBO fará uso dessas informações para promover a divulgação de material publicitário, promoções, descontos, eventos e programas de fidelidade, bem como para realização de pesquisa de satisfação.</p>

          <p><b>(ii) Dados coletados automaticamente:</b></p>

          <p>A TRIBO também coleta uma série de informações de modo automático, tais como: características do dispositivo de acesso, do navegador, IP (com data e hora), origem do IP, informações sobre cliques, páginas acessadas, as páginas seguintes acessadas após a saída das Páginas, ou qualquer termo de procura digitado nos sites ou em referência a estes, dentre outros. Para tal coleta, a TRIBO fará uso de algumas tecnologias padrões, como cookies, pixel tags, beacons e local shared objects, que são utilizadas com o propósito de melhorar a experiência de navegação do Usuário nas Páginas, de acordo com seus hábitos e suas preferências.</p>

          <h1>Compartilhamento de informações</h1>
          <p>Esse ponto é muito importante quando estamos diante de um site de serviços, é fundamental que sejamos transparentes quanto a todo tratamento de dados pessoais feitos pelo site. Pensando nisso a TRIBO, como se preocupa com a segurança e proteção de dados, não pode deixar de especificar de forma bem clara com quem compartilha os seus dados.</p>
          <p>As informações coletadas poderão ser compartilhadas pela TRIBO com:</p>
          <ul>
            <li>Demais empresas parceiras, quando forem necessárias para a adequada prestação dos serviços objeto de suas atividades.</li>
            <li>Mediante decisão judicial.</li>
            <li>Requisição de autoridade competente.</li>
            <li>Provedoras de infraestrutura tecnológica e operacional necessária para as atividades da TRIBO, como intermediadoras de pagamento e provedores de serviço de armazenamento de informações.</li>
          </ul>
          <p>Ainda, esclarecemos que suas informações também poderão ser compartilhadas com empresas provedoras de infraestrutura tecnológica e operacional necessária para as atividades da TRIBO, como intermediadoras de pagamento e provedores de serviço de armazenamento de informações.</p>

          <h1>Como utilizamos seus dados</h1>
          <p>Para melhor entendimento desse cenário a TRIBO criou um ciclo de vida do tratamento dos dados.</p>

          <div class={styles.squareLabel}>
            <h3>O que acontece quando se cadastra no site da TRIBO</h3>
            <br />
            <p>1° VOCÊ imputa os seus dados pessoais no sistema ( nome, e-mail )</p>
            <br />
            <p>2° A TRIBO processa seus dados, ou seja, ordena seus dados dentro do site para os devidos lugares, proporcionando assim uma melhor experiência para você.</p>
            <br />
            <p> 3° Integrado ao site da TRIBO temos as plataformas financeiras, que processam seus dados e confirmam seu pagamento.</p>
            <br />
            <p>4º A TRIBO armazena seus dados, para você ter experiências incríveis no próximo acesso !!! </p>
          </div>

          <p>
            <br />
            As informações coletadas pela TRIBO têm como finalidade o estabelecimento de vínculo contratual ou a gestão, administração, prestação, ampliação e melhoramento das Páginas ao Usuário, adequando-as às suas preferências e aos seus gostos,
            bem como a criação de novos serviços e produtos a serem oferecidos aos Usuários.<br />
          </p>
          <p>As informações coletadas poderão ser utilizadas:</p>
          <div class='margin-left'>
            <p>i) Prestação de serviço fornecido pela plataforma</p>
            <p>ii) Intermediadoras de pagamento</p>
          </div>
          <h1>Como utilizamos os cookies</h1>

          <div class='in-privacy-text'>
            <p>
              Os cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita as Páginas da TRIBO. Geralmente, um cookie contém o nome do site que o originou, seu tempo de vida e um valor, que é gerado
              aleatoriamente.
            </p>
            <p>
              A TRIBO utiliza cookies para facilitar o uso e melhor adaptar as Páginas aos seus interesses e necessidades, bem como para compilarmos informações sobre a utilização de nossos sites e serviços, auxiliando a melhorar suas estruturas e
              seus conteúdos. Os cookies também podem ser utilizados para acelerar suas atividades e experiências futuras nas Páginas.<br />
            </p>
            <table>
              <thead>
                <tr>
                  <th>Tipos de Cookies</th>
                  <th>O que eles fazem?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Necessários</td>
                  <td> Esses cookies são essenciais para que as Páginas da TRIBO carreguem corretamente e permitem que você navegue em nossos sites e faça uso de todas as funcionalidades. </td>
                </tr>
                <tr>
                  <td>Desempenho</td>
                  <td>
                    <br />
                    Esses cookies permitem que as Páginas da TRIBO se lembrem de suas escolhas, para proporcionar uma experiência mais personalizada. Também, possibilitam que os Usuários assistam a vídeos e utilizem ferramentas sociais, campos para
                    comentários, fóruns, entre outros.<br />
                  </td>
                </tr>
              </tbody>
            </table>
            <p> Após o Usuário consentir para a utilização de cookies, quando do uso das Páginas da TRIBO, são armazenados em cookie em seu dispositivo para lembrar disso na próxima sessão. </p>
            <p>
              A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos cookies, pelo que deverá apagar os cookies das Páginas da TRIBO utilizando as configurações de seu navegador de preferência. Para mais informações sobre como
              proceder em relação à gestão dos cookies nos navegadores:<br />
            </p>
            <br />
            <ul style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '18px'
            }}>
              <li>
                <a className={styles.linkInternet} href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies'>Internet Explorer</a>
              </li>
              <li>
                <a className={styles.linkInternet} href='https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam'>Mozilla Firefox</a>
              </li>
              <li>
                <a className={styles.linkInternet} href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pt-BR'>Google Chrome</a>
              </li>
              <li>
                <a className={styles.linkInternet} href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac'>Safari</a>
              </li>
            </ul>
            <br />
            <p> Por fim, lembramos que, caso o Usuário não aceite alguns cookies das Páginas da TRIBO, certos serviços poderão não funcionar de maneira ideal. </p>
          </div>

          <h1>Como mantemos seus dados seguros</h1>
          <p>A TRIBO armazenará as informações coletadas em suas Páginas em servidores próprios ou por ela contratados.</p>
          <p>A TRIBO utiliza os meios razoáveis de mercado e legalmente requeridos para preservar a privacidade dos dados coletados em suas Páginas.</p>
          <p>Desta forma, adota as seguintes precauções, em observância às diretrizes sobre padrões de segurança estabelecidas no Decreto nº 8.771/2016, tais como:</p>
          <ul>
            <li>A TRIBO utiliza os métodos padrão e de mercado para criptografar e anonimizar os dados coletados;</li>
            <li>A TRIBO somente autoriza o acesso nos sistemas de pessoas previamente estabelecidas.</li>
            <li>Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será responsabilizado nos moldes da legislação brasileira.</li>
            <li>Manutenção do inventário indicando momento, duração, identidade do funcionário, ou do responsável pelo acesso e o arquivo objeto, com base nos registros de conexão e de acesso a aplicações, conforme determinado no artigo 13 do Decreto nº 8.771/2016.</li>
            <li>Armazenamento feito na AWS</li>

          </ul>


          <h1>Retenção das informações coletadas</h1>
          <p>As informações coletadas pela TRIBO por meio das Páginas serão automaticamente excluídas de seus servidores quando deixarem de ser úteis para os fins para os quais foram coletadas, ou quando o <b>usuário solicitar a desativação de seus dados pessoais.</b></p>
          <div className={styles.row}>
            <FaRegClock size={25} />
            <p>O tempo máximo de armazenamento após 5 anos para dados os dados pessoais coletados pela TRIBO.</p>
          </div>
          <p>Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou regulatória, transferência a terceiro – desde que respeitados os requisitos de tratamento de dados – e uso exclusivo da TRIBO, vedado seu acesso por terceiro, desde que anonimizadas.</p>
          <h1>Quais são seus direitos</h1>
          <p>Em cumprimento à regulamentação aplicável, no que diz respeito ao tratamento de dados pessoais, a TRIBO respeita e garante ao Usuário, a possibilidade de apresentação de solicitações baseadas nos seguintes direitos.</p>
          <ul>
            <li>Confirmação da existência de tratamento;</li>
            <li>O acesso aos dados;</li>
            <li>A correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade;</li>
            <li>A portabilidade de seus dados a outro fornecedor de serviço ou produto, mediante requisição expressa pelo Usuário;</li>
            <li>A eliminação dos dados tratados com consentimento do Usuário;</li>
            <li>A obtenção de informações sobre as entidades públicas ou privadas com as quais a TRIBO compartilhou seus dados;a informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as consequências, em caso de negativa;</li>
            <li>A revogação do consentimento.</li>
          </ul>

          <h1>Como entrar em contato com a TRIBO?</h1>
          <p>A TRIBO preza pela proximidade com o usuário, pensando nisso, temos um canal de atendimento específico para tratar sobre dúvidas referentes a privacidade e proteção e dados do site.</p>
          <a className={styles.buttonContato} href='https://api.whatsapp.com/send?phone=5534991294718&amp;text'>Clique aqui!</a>

          <h2><GiInjustice /> Legislação</h2>
          <p>Esta Política será regida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou Países, sendo competente o foro de domicílio do Usuário para dirimir qualquer dúvida decorrente deste documento.</p>
        </Col>
      </Row>
    </div>
  );
};

export default PoliticaPrivacidade;
