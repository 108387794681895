import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Skeleton, Button, Input } from 'antd';
import styles from './style.module.scss';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { LuTicket } from 'react-icons/lu';
import { MdSearch, MdSkipNext } from 'react-icons/md';
import { GrNext } from 'react-icons/gr';
import { GrPrevious } from 'react-icons/gr';
import { FaClover } from 'react-icons/fa6';

import axios from 'axios';

const Resultados = () => {
  const [todosResultados, setTodosResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [currentLoteria, setCurrentLoteria] = useState('');
  const [currentConcurso, setCurrentConcurso] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [valorInicialConcurso, setValorInicialConcurso] = useState(0);
  const token = 'tYkFLWuWl0HCB2e';

  const formatarComoMoeda = (valor) => valor?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  const formatDate = (dateString) => dateString;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://apiloterias.com.br/app/v2/resultado?loteria=ultimos&token=${token}`);
        let dados = response.data;
        console.log('dados', dados);
        // dados = dados.filter((item) => item.loteria !== 'LOTECA');

        setTodosResultados(dados);
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchConcurso = async (loteria, concurso) => {
    try {
      const response = await axios.get(`https://apiloterias.com.br/app/v2/resultado?loteria=${loteria}&token=${token}&concurso=${concurso}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar concurso', error);
      return null;
    }
  };

  const showModal = async (nome, concurso) => {
    setValorInicialConcurso(concurso);
    console.log('nome', nome);
    const resultado = await fetchConcurso(nome, concurso);
    console.log('Resultado do Concurso:', resultado);
    if (resultado) {
      setModalContent(resultado);
      setCurrentLoteria(nome);
      setCurrentConcurso(concurso);
    } else {
      alert('Erro ao buscar dados do concurso');
    }
  };

  useEffect(() => {
    if (modalContent) {
      console.log('Conteúdo do Modal atualizado:', modalContent);
      setIsModalVisible(true);
    }
  }, [modalContent]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePrevious = async () => {
    const previousConcurso = currentConcurso - 1;
    const resultado = await fetchConcurso(currentLoteria, previousConcurso);
    if (resultado) {
      setModalContent(resultado);
      setCurrentConcurso(previousConcurso);
    } else {
      alert('Erro ao buscar dados do concurso anterior');
    }
  };

  const handleNext = async () => {
    if (valorInicialConcurso > currentConcurso) {
      const nextConcurso = parseInt(currentConcurso) + 1;
      const resultado = await fetchConcurso(currentLoteria, nextConcurso);
      if (resultado) {
        setModalContent(resultado);
        setCurrentConcurso(nextConcurso);
      } else {
        alert('Erro ao buscar dados do próximo concurso');
      }
    }
  };

  const handleSearch = async () => {
    const resultado = await fetchConcurso(currentLoteria, searchQuery);
    if (resultado) {
      setModalContent(resultado);
      setCurrentConcurso(parseInt(searchQuery, 10));
    } else {
      alert('Concurso não encontrado');
    }
  };

  const coresTitulo = {
    'MEGA SENA': '#29705e',
    'MEGA-SENA': '#29705e',
    LOTOFÁCIL: '#9915ba',
    QUINA: '#06387c',
    LOTOMANIA: '#f78100',
    TIMEMANIA: '#01d001',
    'DUPLA SENA': '#a61324',
    DUPLASENA: '#a61324',
    'DUPLA SENA DE PÁSCOA': '#6b000c',
    'DUPLA-SENA': '#a61324',
    'DIA DE SORTE': '#cb852b',
    'MEGA DA VIRADA': '#228f34',
    'LOTOFÁCIL FINAL ZERO': '#4f034a',
    'LOTOFÁCIL DA INDEPENDÊNCIA': '#4f034a',
    'SUPER SETE': '#A8CF44',
    'QUINA SÃO JOÃO': '#001999',
    'MAIS MILIONÁRIA': '#001999',
    FEDERAL: '#001999',
    LOTECA: '#fb1f00',
  };

  const hexToRGBA = (hex, opacity) => {
    if (!hex) return '#000';
    hex = hex?.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const normalizeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '')
      .replace(/-/g, '')
      .toLowerCase();
  };

  return (
    <div className={styles.container}>
      <Row gutter={[26, 26]}>
        {isLoading ? (
          <Col span={24}>
            <Skeleton active block />
          </Col>
        ) : (
          todosResultados.map((item, index) => {
            return (
              <Col key={index} span={8} className={styles.rows}>
                <div className={styles.card} style={{ borderColor: coresTitulo[item.loteria] }} onClick={() => showModal(normalizeString(item.loteria), item.resultado.numero_concurso)}>
                  <h1 style={{ color: coresTitulo[item.loteria] }}>{item.loteria === 'MAIS MILIONÁRIA' ? '+MILIONÁRIA' : item.loteria}</h1>
                  <h3>
                    {formatDate(item.resultado.data_concurso)} | Conc. {item.resultado.numero_concurso}
                  </h3>
                  <p>Estimativa do próximo concurso:</p>
                  <h2>{formatarComoMoeda(item.resultado.valor_estimado_proximo_concurso)}</h2>
                  <h3>Data: {formatDate(item.resultado.data_proximo_concurso)}</h3>
                  <div className={styles.seeMore} style={{ background: coresTitulo[item.loteria] }}>
                    <MdSearch />
                  </div>
                </div>
              </Col>
            );
          })
        )}
      </Row>
      <Modal
        title={
          <h1 className={styles.header} style={{ background: coresTitulo[modalContent?.nome] }}>
            {modalContent?.nome === 'MAIS MILIONÁRIA' ? '+MILIONÁRIA' : modalContent?.nome}
          </h1>
        }
        width={900}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.containerModal}
        footer={null}
      >
        {modalContent ? (
          <>
            <div className={styles.containerTotal}>
              <Row className={styles.boloesGrid}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className={styles.containerInput}>
                    <Input.Search placeholder='Buscar pelo número do concurso' enterButton size='large' onSearch={handleSearch} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className={styles.input} />
                  </div>
                </Col>
                <Col xs={24} sm={18} md={24} lg={24} xl={24}>
                  <div className={styles.leftContainer}>
                    <h3
                      className={styles.textSvg}
                      style={{
                        borderColor: coresTitulo[modalContent?.nome],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                      }}
                    >
                      <FaRegCalendarAlt style={{ color: coresTitulo[modalContent?.nome] }} />
                      <p style={{ color: coresTitulo[modalContent?.nome] }}>Sorteio {formatDate(modalContent?.data_concurso)}</p>
                    </h3>
                    <div
                      className={styles.textSvg}
                      style={{
                        borderColor: coresTitulo[modalContent?.nome],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                      }}
                    >
                      <LuTicket style={{ color: coresTitulo[modalContent?.nome] }} />
                      <p style={{ color: coresTitulo[modalContent?.nome] }}>Concurso {modalContent?.numero_concurso}</p>
                    </div>
                    <div className={styles.valoresSorteados}>
                      {modalContent?.dezenas_2 ? (
                        <div className={styles.boloesDuplaSena}>
                          <div
                            className={styles.containerDuplaSena}
                            style={{
                              borderColor: coresTitulo[modalContent?.nome],
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                            }}
                          >
                            <h3 className={styles.numeroSorteios} style={{ color: coresTitulo[modalContent?.nome] }}>
                              1º Sorteio
                            </h3>
                            <div className={styles.containerSorteios}>
                              <Button key='back' className={styles.buttonPrevious} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handlePrevious}>
                                <GrPrevious />
                              </Button>
                              <div className={styles.boloesGridSorteadosDupla}>
                                {modalContent?.dezenas.map((item, index) => (
                                  <div key={index} className={styles.divSeparada} style={{ background: coresTitulo[modalContent?.nome] }}>
                                    <h3>{item}</h3>
                                  </div>
                                ))}

                                {modalContent?.complemento && (
                                  <div className={styles.mesSorte}>
                                    <h3 style={{ background: coresTitulo[modalContent?.nome] }}>{modalContent?.complemento}</h3>
                                  </div>
                                )}
                              </div>
                              <Button key='submit' className={styles.buttonNext} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handleNext}>
                                <GrNext />
                              </Button>
                            </div>
                          </div>
                          <div
                            className={styles.containerDuplaSena}
                            style={{
                              borderColor: coresTitulo[modalContent?.nome],
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                            }}
                          >
                            <h3 className={styles.numeroSorteios} style={{ color: coresTitulo[modalContent?.nome] }}>
                              2º Sorteio
                            </h3>

                            <div className={styles.boloesGridSorteadosDupla}>
                              {modalContent?.dezenas_2.map((item, index) => (
                                <div key={index} className={styles.divSeparada} style={{ background: coresTitulo[modalContent?.nome] }}>
                                  <h3>{item}</h3>
                                </div>
                              ))}

                              {modalContent?.complemento && (
                                <div className={styles.mesSorte}>
                                  <h3 style={{ background: coresTitulo[modalContent?.nome] }}>{modalContent?.complemento}</h3>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            alignItems: 'center',
                            textAlign: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            borderColor: coresTitulo[modalContent?.nome],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                          }}
                        >
                          {modalContent?.resultado_equipes_esportivas && modalContent.resultado_equipes_esportivas.length > 0 ? (
                            <div className={styles.tabelaResultado}>
                              <div>
                                <Button key='back' className={styles.buttonPrevious} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handlePrevious}>
                                  <GrPrevious />
                                </Button>
                              </div>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Jogo</th>
                                    <th>Coluna 1</th>
                                    <th>x</th>
                                    <th>Coluna 2</th>
                                    <th>Data</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {modalContent?.resultado_equipes_esportivas.map((jogo, index) => (
                                    <tr key={index}>
                                      <td>{jogo.jogo}</td>
                                      <td style={{ backgroundColor: jogo.coluna_um ? '#FF0000' : 'transparent', color: jogo.coluna_um ? '#FFFFFF' : '#000000' }}>{jogo.gol_time1}</td>
                                      <td>x</td>
                                      <td style={{ backgroundColor: jogo.coluna_dois ? '#FF0000' : 'transparent', color: jogo.coluna_dois ? '#FFFFFF' : '#000000' }}>{jogo.gol_time2}</td>
                                      <td>{jogo.dia_da_semana}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div>
                                <Button key='submit' className={styles.buttonNext} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handleNext}>
                                  <GrNext />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <h3 className={styles.numeroSorteios} style={{ color: coresTitulo[modalContent?.nome] }}>
                                Sorteio
                              </h3>
                              <div className={styles.boloesGridSorteados}>
                                <div>
                                  <Button key='back' className={styles.buttonPrevious} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handlePrevious}>
                                    <GrPrevious />
                                  </Button>
                                </div>
                                <div className={styles.boloesContainerCentro}>
                                  {modalContent?.dezenas.map((item, index) => (
                                    <div key={index} className={styles.divSeparada} style={{ background: coresTitulo[modalContent?.nome] }}>
                                      <h3>{item}</h3>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  <Button key='submit' className={styles.buttonNext} style={{ background: coresTitulo[modalContent?.nome] }} onClick={handleNext}>
                                    <GrNext />
                                  </Button>
                                </div>
                                {modalContent?.complemento && (
                                  <div className={styles.mesSorte}>
                                    <h3 style={{ background: coresTitulo[modalContent?.nome] }}>{modalContent?.complemento}</h3>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {modalContent?.trevos_sorteados && (
                        <div
                          className={styles.containerTrevo}
                          style={{
                            borderColor: coresTitulo[modalContent?.nome],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1),
                          }}
                        >
                          <h1 style={{ color: coresTitulo[modalContent?.nome] }}>Trevos Sorteados:</h1>
                          <div className={styles.trevos}>
                            <div className={styles.trevo}>
                              <FaClover />
                              <h2>{modalContent?.trevos_sorteados[0]}</h2>
                            </div>
                            <div className={styles.trevo}>
                              <FaClover />
                              <h2>{modalContent?.trevos_sorteados[1]}</h2>
                            </div>
                          </div>
                        </div>
                      )}
                      {modalContent?.mes_da_sorte && (
                        <div className={styles.boloesGridSorteados}>
                          <div className={styles.divSeparadaMes} style={{ background: coresTitulo[modalContent?.nome] }}>
                            <h3>{modalContent?.mes_da_sorte}</h3>
                          </div>
                        </div>
                      )}
                      {modalContent?.time_coracao && (
                        <div className={styles.boloesGridSorteados}>
                          <div className={styles.divSeparada} style={{ background: coresTitulo[modalContent?.nome] }}>
                            <h3>{modalContent?.time_coracao}</h3>
                          </div>
                        </div>
                      )}
                    </div>
                    <h3 className={styles.textSvg} style={{ borderColor: coresTitulo[modalContent?.nome], borderWidth: '1px', borderStyle: 'solid', background: hexToRGBA(coresTitulo[modalContent?.nome], 0.1) }}>
                      <FaRegCalendarAlt style={{ color: coresTitulo[modalContent?.nome] }} />
                      <p style={{ color: coresTitulo[modalContent?.nome] }}>Próximo Sorteio: {formatDate(modalContent?.data_proximo_concurso)}</p>
                    </h3>
                    <h2 style={{ textAlign: 'center' }}>Estimativa do próximo concurso:</h2>
                    <div className={styles.acumulouLabel} style={{ backgroundColor: coresTitulo[modalContent?.nome] }}>
                      <h1>{formatarComoMoeda(modalContent?.valor_estimado_proximo_concurso)}</h1>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default Resultados;
