import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Table, Pagination, Input } from 'antd';
import { MdSearch, MdShoppingBag } from 'react-icons/md';
import { BsTrophyFill } from 'react-icons/bs';
import styles from './style.module.scss';
import moment from 'moment';
import { FaTrophy } from 'react-icons/fa';
import ModalVerBoloesPrint from '../ModalVerBoloesPrint';
import VerApotas from '../VerApotas';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import { IoIosSearch } from 'react-icons/io';

const MeusBoloesPremios = ({ showTableBoloes, setShowTableBoloes, showTablePremios, setShowTablePremios }) => {
  const pageSize = 10; // Número de itens por página
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const [loading, setLoading] = useState(false); // Estado para o loading
  const [arrayApostas, setArrayApostas] = useState([]); // Estado para armazenar as apostas
  const [arrayPremios, setArrayPremios] = useState([]); // Estado para armazenar as apostas
  const [totalItems, setTotalItems] = useState(0); // Estado para armazenar o total de itens

  const [regra, setRegra] = useState();
  const [cotas, setCotas] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apostasQuantidade, setApostasQuantidade] = useState(0);

  const [bolaoComprado, setBolaoComprado] = useState({});
  const [modalVerApostas, setModalVerApostas] = useState(false);
  const [numerosSorteados, setNumerosSorteados] = useState();
  const [modalResultados, setModalResultados] = useState([]);
  const [acertos, setAcertos] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataPremios, setFilteredDataPremios] = useState([]);

  const dataToShow = Array.isArray(filteredData) && filteredData.length > 0 ? filteredData : arrayApostas;
  const dataPremiosToShow = Array.isArray(filteredDataPremios) && filteredDataPremios.length > 0 ? filteredDataPremios : arrayPremios;

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    } else {
      if (showTableBoloes) {
        fetchArrayApostas(currentPage - 1);
      } else if (showTablePremios) {
        fetchPremios(currentPage - 1);
      }
    }
  }, [showTableBoloes, showTablePremios, currentPage]);

  const fetchArrayApostas = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/get_apostas_cliente/${page}/null`);
      setArrayApostas(response.data.apostas);
      setTotalItems(response.data.total); // Supondo que a API retorna o total de itens
    } catch (error) {
      console.error('Erro ao buscar array de apostas:', error.message);
      toast.error('Erro ao buscar array de apostas');
    } finally {
      setLoading(false);
    }
  };

  const fetchPremios = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/get_premiacoes_cliente/${page}/null`);
      setArrayPremios(response.data.premiacoes);
      setTotalItems(response.data.total); // Supondo que a API retorna o total de itens
    } catch (error) {
      console.error('Erro ao buscar array de prêmios:', error.message);
      toast.error('Erro ao buscar array de prêmios');
    } finally {
      setLoading(false);
    }
  };

  const fetchBolao = async (bolao_id) => {
    setLoading(true);
    try {
      const response = await api.get(`/get_bolao_aposta/${bolao_id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar dados do bolão:', error.message);
      toast.error('Erro ao buscar dados do bolão');
    } finally {
      setLoading(false);
    }
  };

  const showModal = async (bolao_id, isPremio = false) => {
    try {
      let bolaoSelecionado;
      bolaoSelecionado = await fetchBolao(bolao_id);
      console.log('bolaoSelecionado', bolaoSelecionado);

      if (!bolaoSelecionado) return;

      const arrayAcertos =
        bolaoSelecionado.acertos_faixas_boloes?.map((acerto) => ({
          pontos: acerto.pontos,
          jogos: acerto.quantidade_jogos,
        })) || [];

      const numerosSorteadosString = bolaoSelecionado.resultados?.map((result) => result.sorteados).join(' ') || '';

      setGrupo(bolaoSelecionado.grupo);
      setAcertos(arrayAcertos);
      console.log('arrayAcertos', arrayAcertos);
      setNumerosSorteados(bolaoSelecionado.resultados); // Define a string concatenada
      setRegra(bolaoSelecionado.regra);
      setCotas(bolaoSelecionado.quantidade);
      setApostasQuantidade(bolaoSelecionado.apostas?.length || 0);
      setIsModalVisible(true);
      setBolaoComprado(bolaoSelecionado);
      console.log('bolaoSelecionado apos 2', bolaoSelecionado);
    } catch (e) {
      console.log('e', e.message);
    }
  };

  const columns = [
    {
      title: 'Data do pagamento',
      dataIndex: 'data_pagamento',
      key: 'data_pagamento',
      render: (text) => moment(text).format('DD/MM/YY [às] hh:mm'),
    },
    {
      title: 'Bolão',
      dataIndex: 'grupo',
      key: 'grupo',
    },
    {
      title: 'Loteria',
      dataIndex: 'loteria_nome',
      key: 'loteria_nome',
    },
    {
      title: 'Data do sorteio',
      dataIndex: 'data_sorteio',
      key: 'data_sorteio',
      render: (text) => moment(text).format('DD/MM/YY'),
    },
    {
      title: 'Quantidade',
      dataIndex: 'cotas',
      key: 'cotas',
      render: (value) => (value > 0 ? value : 'Saldo'),
    },
    {
      title: 'Premiação Estimada',
      dataIndex: 'premiacao_loteria',
      key: 'premiacao_loteria',
      render: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status === 1 ? (
          'Concorrendo'
        ) : status === 2 ? (
          'Sorteado'
        ) : status === 4 ? (
          <p style={{ color: '#008000' }}>
            Premiado
            <FaTrophy style={{ marginLeft: '.5rem' }} />
          </p>
        ) : (
          ''
        ),
    },
    {
      title: 'Ver Bolões',
      dataIndex: 'ver',
      key: 'ver',
      render: (value, record) => <Button icon={<MdSearch />} onClick={() => showModal(record.bolao_id)}></Button>,
    },
  ];

  const columnsPremiacao = [
    {
      title: 'Data do sorteio',
      dataIndex: 'data_sorteio',
      key: 'data_sorteio',
      render: (text) => moment(text).format('DD/MM/YY [às] hh:mm'),
    },
    {
      title: 'Bolão',
      dataIndex: 'grupo',
      key: 'grupo',
    },
    {
      title: 'Loteria',
      dataIndex: 'nome_jogo',
      key: 'nome_jogo',
    },
    {
      title: 'Premiação',
      dataIndex: 'premiacao',
      key: 'premiacao',
      render: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Valor por cota',
      dataIndex: 'premio_ganho',
      key: 'premio_ganho',
      render: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Cotas',
      dataIndex: 'cotas_compradas',
      key: 'cotas_compradas',
    },
    {
      title: 'Resumo da premiação',
      dataIndex: 'ver',
      key: 'ver',
      render: (value, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button style={{ margin: 'auto' }} icon={<MdSearch />} onClick={() => showModal(record.bolao_id, true)}></Button>
        </div>
      ),
    },
  ];

  const coresTitulo = {
    'MEGA SENA': '#29705e',
    'MEGA-SENA': '#29705e',
    LOTOFÁCIL: '#9915ba',
    QUINA: '#06387c',
    LOTOMANIA: '#f78100',
    TIMEMANIA: '#01d001',
    'TIME MANIA': '#01d001',
    'TIME-MANIA': '#01d001',
    'DUPLA SENA': '#a61324',
    DUPLASENA: '#a61324',
    'DUPLA SENA DE PÁSCOA': '#6b000c',
    'DUPLA-SENA': '#a61324',
    'DIA DE SORTE': '#cb852b',
    'MEGA DA VIRADA': '#228f34',
    'LOTOFÁCIL FINAL ZERO': '#4f034a',
    'LOTOFÁCIL DA INDEPENDÊNCIA': '#4f034a',
    'LOTOFÁCIL INDEPENDÊNCIA': '#4f034a',
    'SUPER SETE': '#A8CF44',
    'QUINA SÃO JOÃO': '#001999',
  };

  const formatPrize = (value) => {
    if (!value) return;
    let numberPart,
      wordPart = '';
    if (value >= 1000000) {
      numberPart = (value / 1000000).toFixed(1);
      wordPart = numberPart > 1 ? 'Milhões' : 'Milhão';
    } else if (value >= 1000) {
      numberPart = (value / 1000).toFixed(1);
      numberPart = numberPart.endsWith('.0') ? numberPart.slice(0, -2) : numberPart;
      wordPart = 'Mil';
    } else {
      numberPart = value.toString();
    }
    return { number: numberPart, word: wordPart };
  };

  const closeModal = () => setIsModalVisible(false);

  const closeModalApostas = () => setModalVerApostas(false);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const getColor = (name) => {
    console.log('name', name);
    return coresTitulo[name];
  };

  const handleSearch = async (searchValue) => {
    setLoading(true);
    try {
      if (showTableBoloes) {
        const responseApostas = await api.get(`/get_apostas_cliente/1/${searchValue}`);
        setFilteredData(responseApostas.data.apostas);
        setTotalItems(responseApostas.data.total);
      } else if (showTablePremios) {
        const responsePremios = await api.get(`/get_premiacoes_cliente/1/${searchValue}`);
        setFilteredDataPremios(responsePremios.data.premiacoes);
        setTotalItems(responsePremios.data.total);
      }
    } catch (error) {
      console.error('Erro ao buscar dados filtrados:', error.message);
      toast.error('Erro ao buscar dados filtrados');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button
            className={styles.containerSelectView}
            onClick={() => {
              setShowTableBoloes(true);
              setShowTablePremios(false);
              setCurrentPage(1); // Resetar para a primeira página
            }}
          >
            <div className={styles.icon}>
              <MdShoppingBag />
            </div>
            <h1>Meus Bolões</h1>
          </Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button
            className={styles.containerSelectView}
            onClick={() => {
              setShowTableBoloes(false);
              setShowTablePremios(true);
              setCurrentPage(1); // Resetar para a primeira página
            }}
          >
            <div className={styles.icon}>
              <BsTrophyFill />
            </div>
            <h1>Meus Prêmios</h1>
          </Button>
        </Col>
      </Row>
      {showTableBoloes && (
        <>
          <Input
            className={styles.filterInput}
            size='large'
            prefix={<IoIosSearch />}
            placeholder='Pesquisar'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => handleSearch(searchValue)} // Atualizar ao pressionar Enter
          />
          <Table className={styles.tabelaResgate} dataSource={dataToShow} columns={columns} pagination={false} rowKey='compra_id' loading={loading} />
          <Pagination current={currentPage} total={totalItems} pageSize={pageSize} onChange={handleChangePage} />
        </>
      )}
      {showTablePremios && (
        <>
          <Input
            className={styles.filterInput}
            size='large'
            prefix={<IoIosSearch />}
            placeholder='Pesquisar'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => handleSearch(searchValue)} // Atualizar ao pressionar Enter
          />
          <Table className={styles.tabelaResgate} dataSource={dataPremiosToShow} columns={columnsPremiacao} pagination={false} rowKey='premiacao_usuario_id' loading={loading} />
          <Pagination current={currentPage} total={totalItems} pageSize={pageSize} onChange={handleChangePage} />
        </>
      )}
      {isModalVisible && (
        <ModalVerBoloesPrint
          nome={bolaoComprado.loteria_nome}
          color={getColor(bolaoComprado.loteria_nome)}
          regra={regra}
          concurso={bolaoComprado.concurso}
          dataSorteio={bolaoComprado.data_sorteio}
          valor={`${formatPrize(bolaoComprado.premiacao_loteria)?.number} ${formatPrize(bolaoComprado.premiacao_loteria)?.word}`}
          isModalVisible={isModalVisible}
          closeModal={closeModal}
          cotas={cotas}
          setModalVerApostas={setModalVerApostas}
          urlImpressao={bolaoComprado.printCard}
          quantidadeApostas={apostasQuantidade}
          apostas={bolaoComprado.apostas}
          resultados={acertos}
          numerosSorteados={numerosSorteados}
        />
      )}
      {modalVerApostas && (
        <VerApotas
          grupo={grupo}
          concursoSelecionado={bolaoComprado.concurso}
          color={coresTitulo[bolaoComprado.loteria_nome]}
          numerosSorteados={numerosSorteados}
          bolaoName={bolaoComprado.loteria_nome}
          apostas={arrayApostas}
          isModalVisible={modalVerApostas}
          closeModal={closeModalApostas}
        />
      )}
    </div>
  );
};

export default MeusBoloesPremios;
