import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Tabs, Col, Row, Skeleton, message } from 'antd';
import styles from './style.module.scss';
import TabsBolao from '../../Components/TabsBolao';
import CardBoloes from '../../Components/CardBoloes';
import { useCart } from '../../contexts/CartProvider';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import VerApostasBolao from '../../Components/VerApostasBolao';
import { useAuth } from '../../contexts/AuthContext';

const Bolao = () => {
  const { id } = useParams();
  const { addToCart } = useCart();
  const { user, logout, isAuthenticated } = useAuth();
  const [subBoloesSelect, setSubBoloesSelect] = useState([]);
  const [bolaoName, setBolaoName] = useState('Bolão');
  const [apostasBolao, setApostasBolao] = useState([]);
  const [apostaId, setApostaId] = useState();
  const [apostas, setApostas] = useState();
  const [apostasBuscarBolao, setApostasBuscarBolao] = useState();
  const [modalVerApostas, setModalVerApostas] = useState(false);
  const [concursoSelecionado, setConcursoSelecionado] = useState(false);

  const [arrayDeBoloes, setArrayDeBoloes] = useState([]);
  const [boloes, setBoloes] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  const tabsBolaoRef = useRef(null);

  const modalSetConcursoAndState = async (concurso) => {
    setModalVerApostas(true);

    try {
      const apostas = await api.get(`/boloes_get/${concurso.jogo_id}`);
      const resultados = apostas.data.boloes;
      const filteredBoloesArray = resultados.filter((b) => b.codigo === concurso.codigo);
      setApostasBuscarBolao(filteredBoloesArray);
    } catch (error) {
      console.error('Erro ao buscar dados', error);
    }
    setConcursoSelecionado(concurso);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [jogosRes, jogosEspecialRes] = await Promise.all([api.get('/jogos_get'), api.get('/jogos_especial_get')]);

        const jogosEspecial = jogosEspecialRes.data.map((bolao) => ({ ...bolao, especial: 1 }));

        const combinedBoloes = [...jogosRes.data, ...jogosEspecial];

        // Cria um mapa para garantir a unicidade e priorizar bolões especiais
        const bolaoMap = new Map();
        combinedBoloes.forEach((bolao) => {
          if (!bolaoMap.has(bolao.id) || bolao.especial) {
            bolaoMap.set(bolao.id, bolao);
          }
        });

        // Converte o mapa de volta para um array
        const uniqueBoloes = Array.from(bolaoMap.values());

        setBoloes(uniqueBoloes);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (id && boloes.length > 0) {
      const bolao = boloes.find((b) => b.id.toString() === id);
      if (bolao) {
        setBolaoName(bolao.nome);
        setSubBoloesSelect(bolao);
        setActiveKey(bolao.id.toString());
      }
    } else if (boloes.length > 0) {
      setSubBoloesSelect(boloes[0]);
      setActiveKey(boloes[0].id.toString());
    }
  }, [id, boloes]);

  // Novo useEffect para centralizar o elemento TabsBolao
  useEffect(() => {
    if (tabsBolaoRef.current) {
      tabsBolaoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeKey]);

  // Novo useEffect para garantir que o scroll seja feito para o topo quando os skeletons estiverem sendo exibidos
  useEffect(() => {
    if (boloes.length === 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [boloes.length]);

  const handleAddToCart = (nome, subbolao, quantidade, dataSorteio, nomeBolao) => {
    addToCart({
      id: subbolao.id,
      grupo: subbolao.grupo,
      numero_cotas: quantidade,
      quantidade,
      valor: subbolao.valor_cota,
      nomeUsuario: nome,
      addedAt: new Date(),
      nome: nomeBolao,
      status: subbolao.status,
      data_sorteio: dataSorteio,
    });
    toast.success(`Bolão adicionado com sucesso!!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apostas = await api.get('/boloes_get');
        setApostasBolao(apostas.data);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const findAndSetBolao = () => {
      if (apostasBolao && apostasBolao.length > 0) {
        const selectedBolao = apostasBolao.find((aposta) => aposta.jogo_id === Number(id));
        if (selectedBolao) {
          setApostas(selectedBolao.apostas);
          setArrayDeBoloes(selectedBolao);
        } else {
          console.log('Bolão não encontrado com o ID:', id);
        }
      }
    };
    findAndSetBolao();
  }, [id, apostasBolao]);

  const closeModal = () => {
    setModalVerApostas(false);
  };

  const showModal = useCallback(
    (subbolao) => {
      setIsLoading(true);
      setSubBolaoData({
        regra: subbolao.regra,
        cotas: subbolao.cotas,
        subBolaoCart: subbolao,
      });
      setIsModalVisible(true);
      if (subbolao && subbolao.id) {
        setApostaId(subbolao.id);
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?bolaoId=${subbolao.id}`;
        window.history.pushState({ path: newurl }, '', newurl);
      }
      setIsLoading(false);
    },
    [setApostaId]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [subBolaoData, setSubBolaoData] = useState({
    regra: null,
    cotas: null,
    subBolaoCart: null,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataRanking, setDataRankin] = useState([]);
  const [userRanking, setUserRanking] = useState(null);

  let bolaoconcurso = 4127;

  useEffect(() => {
    const fetchRanking = async () => {
      try {
        const response = await api.get('/jogos_especial_get');
        const apostas = await api.get(`/boloes_get/${bolaoconcurso}`);

        console.log('data Ranking', response.data);
        console.log('user ranking', apostas.data);
        setDataRankin(response.data);
        setUserRanking(apostas.data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    };

    fetchRanking();
  }, [bolaoconcurso]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{bolaoName} - Tribo da sorte</title>
      </Helmet>
      <h1>Escolha entre os grupos de bolões</h1>
      {boloes.length === 0 && (
        <>
          <Skeleton.Input active size='large' block style={{ height: '20rem', marginBottom: '3rem' }} />
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Skeleton.Input active size='large' block style={{ height: '20rem' }} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Skeleton.Input active size='large' block style={{ height: '20rem' }} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Skeleton.Input active size='large' block style={{ height: '20rem' }} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Skeleton.Input active size='large' block style={{ height: '20rem' }} />
            </Col>
          </Row>
        </>
      )}

      {/* Referência para o TabsBolao */}
      <div ref={tabsBolaoRef}>
        <TabsBolao boloes={boloes} setSubBoloesSelect={setSubBoloesSelect} activeKey={activeKey} setActiveKey={setActiveKey} />
      </div>

      {modalVerApostas && <VerApostasBolao color={subBoloesSelect.cor} bolaoName={bolaoName} apostas={apostasBuscarBolao} isModalVisible={modalVerApostas} closeModal={closeModal} />}
      {id === '4127' && (
        <div className={styles.containerBolaoRanking}>
          <div className={styles.containerRanking}>
            <h1>
              Olha só como você esta no Ranking de maiores compradores da <strong>LOTOFÁCIL INDEPENDÊNCIA</strong>
            </h1>
            <h1>
              O primeiro colocado vai levar um prêmio de <strong>R$ 1.000,00 no PIX</strong> e mais <strong>R$ 1.000,00 de SALDO</strong>
            </h1>
          </div>

          <div className={styles.rowRanking}>
            <div className={styles.containerPersonRanking}>
              <h1 className={styles.imagenVoce}>Você</h1>
              <h3>#{userRanking?.posicaoUsuarioLogado?.ranking || 0}</h3>
              <div>
                <h4>Você</h4>
                <h3>{userRanking?.posicaoUsuarioLogado?.total_cotas || 0} cotas</h3>
              </div>
            </div>

            {dataRanking[0]?.dadosAdicionais.map((item, index) => (
              <div key={index} className={styles.containerPersonRanking}>
                <h1 className={styles.imagen}>{item.nome.substring(0, 2).toUpperCase()}</h1>
                <h3>#{item.ordem}</h3>
                <div>
                  <h4>{item.nome}</h4>
                  <h4>{item.soma_cotas} cotas</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <CardBoloes
        modalSetConcursoAndState={modalSetConcursoAndState}
        quantidadeApostas={apostas?.length}
        setApostaId={setApostaId}
        boloesArray={boloes}
        setModalVerApostas={setModalVerApostas}
        bolao={subBoloesSelect}
        handleAddToCart={handleAddToCart}
        showModal={showModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subBolaoData={subBolaoData}
        setSubBolaoData={setSubBolaoData}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default Bolao;
