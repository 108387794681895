import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Skeleton, Button, Input } from 'antd';
import styles from './style.module.scss';
import axios from 'axios';
import { api } from '../../services/apiClient';
import { toast, ToastContainer } from 'react-toastify';

const TrocarSenha = () => {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, []);

  const handlePasswordChange = async () => {
    if (!password || !passwordConfirmation) {
      toast.error('Preencha todos os campos!!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    if (password !== passwordConfirmation) {
      toast.error('Senhas não batem', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.post('/password/alterar', {
        token,
        password,
        password_confirmation: passwordConfirmation,
      });
      toast.success('Senha alterada com sucesso', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      toast.error('Erro ao alterar a senha', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Row justify='center'>
        <Col span={12}>
          <h2>Mudar senha</h2>
          <Input type='password' placeholder='Nova senha' value={password} onChange={(e) => setPassword(e.target.value)} className={styles.input} />
          <Input type='password' placeholder='COnfirmar nova senha' value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} className={styles.input} />
          <Button type='primary' onClick={handlePasswordChange} loading={loading} className={styles.buttonEntrar}>
            Mudar senha
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TrocarSenha;
