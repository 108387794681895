import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import { useParams, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import CardInsercaoSaldo from '../../Components/CardInsercaoSaldo';
import { useCart } from '../../contexts/CartProvider';
import LabelStepsCarrinho from '../../Components/LabelStepsCarrinho';
import StepMinhConta from '../../Components/StepMinhConta';
import { AuthProvider, useAuth } from '../../contexts/AuthContext';
import StepPagamento from '../../Components/StepPagamento';
import CompraConcluida from '../../Components/CompraConcluida';

import StepCarrinho from '../../Components/StepCarrinho';

const Saldo = ({ deviceType }) => {
  const [stepSaldo, setStepSaldo] = useState(1);
  const [valorSaldo, setValorSaldo] = useState(0);
  const { items, addToCart, clearCart } = useCart();
  const { isAuthenticated, user } = useAuth();
  const { saldoInserir } = useParams();

  const handleNextStep = () => {
    setStepSaldo(stepSaldo + 1);
    handleAddToCart();
  };

  const handleAddToCart = () => {
    clearCart()
    addToCart({
      id: '62853d9a-8ec9-4233-a8d5-c969b92b29e0',
      grupo: null,
      quantidade: 1,
      valor: valorSaldo / 100,
      nome: 'Saldo',
      addedAt: new Date(),
    });
  };

  const location = useLocation(); // Adiciona o hook useLocation
  const queryParams = new URLSearchParams(location.search);
  const destino = queryParams.get('saldoInserir');
  console.log('destino', destino)

  useEffect(() => {
    const fetchData = async () => {
      const destino = queryParams.get('saldoInserir');
      if (destino && destino > 0) {
        setStepSaldo(2)
      }
    };

    fetchData();
  }, []);

  
  return (
    <div className={styles.container}>
      {stepSaldo === 1 ? (
        <CardInsercaoSaldo valorSaldo={valorSaldo} setValorSaldo={setValorSaldo} handleNextStep={handleNextStep} />
      ) : (
        <div className={styles.containerCarrinho}>
          <LabelStepsCarrinho step={stepSaldo} labelConta={false} labelCarrinho={true} />
          {stepSaldo === 2 ? <StepPagamento showPrePago={false} items={items} handleNextStep={handleNextStep} /> : <CompraConcluida items={items} isFromSaldo={true} />}
        </div>
      )}
    </div>
  );
};

export default Saldo;
