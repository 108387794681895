import React, { useState, useEffect } from 'react';
import { Tabs, Col, Row, Button } from 'antd';
import styles from './style.module.scss';
import { BsPersonFill } from 'react-icons/bs';
import { MdAttachMoney, MdOutlineTrendingUp } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';

const CardsMinhaConta = ({ handleSelect, setShowTableBoloes }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');

  const handleChangePage = () => {
    if (tab === 'compras') {
      handleSelect('padrao');
      setShowTableBoloes(true);
    } else {
      handleSelect('compras');
    }
  };

  return (
    <Row gutter={[16, 16]} justify='center'>
      <Col xs={20} sm={12} md={12} lg={6} xl={6}>
        <Button className={styles.button} onClick={() => handleSelect('perfil')}>
          <div className={styles.containerCard}>
            <div className={styles.icon}>
              <BsPersonFill />
            </div>
            <h1>Meu Perfil</h1>
            <p>Veja as informações da sua conta</p>
          </div>
        </Button>
      </Col>
      <Col xs={20} sm={12} md={12} lg={6} xl={6}>
        <Button className={styles.button} onClick={handleChangePage}>
          <div className={styles.containerCard} style={{ borderColor: '#00897F' }}>
            <div className={styles.icon} style={{ background: '#00897F' }}>
              <MdOutlineTrendingUp />
            </div>
            <h1 style={{ color: '#00897F' }}>{tab === 'compras' ? 'Meus bolões' : 'Minhas compras'}</h1>
            <p>Verifique todos detalhes de suas transações</p>
          </div>
        </Button>
      </Col>
      <Col xs={20} sm={12} md={12} lg={6} xl={6}>
        <Button className={styles.button} onClick={() => handleSelect('saldo')}>
          <div className={styles.containerCard} style={{ borderColor: '#BF811B' }}>
            <div className={styles.icon} style={{ background: '#BF811B' }}>
              <MdAttachMoney />
            </div>
            <h1 style={{ color: '#BF811B' }}>Saldo</h1>
            <p>Verifique todos detalhes do seu saldo</p>
          </div>
        </Button>
      </Col>
      <Col xs={20} sm={12} md={12} lg={6} xl={6}>
        <Button className={styles.button} onClick={() => handleSelect('excluir')}>
          <div className={styles.containerCard} style={{ borderColor: '#EB4143' }}>
            <div className={styles.icon} style={{ background: '#EB4143' }}>
              <FaTrash />
            </div>
            <h1 style={{ color: '#EB4143' }}>Excluir conta</h1>
            <p>Excluir permanentemente sua conta.</p>
          </div>
        </Button>
      </Col>
    </Row>
  );
};

export default CardsMinhaConta;
