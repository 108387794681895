import React, { useState, useRef, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider';
import { useAuth } from '../../contexts/AuthContext';
import { useSaldo } from '../../contexts/SaldoContext';
import styles from './style.module.scss';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { FaPix } from 'react-icons/fa6';
import CreditoPrePago from '../CreditoPrePago';
import CartaoCredito from '../CartaoCredito';
import { toast } from 'react-toastify';
import Pix from '../Pix';
import logo from '../../assets/logo.png';

const StepPagamento = ({ items, handleNextStep, showPrePago = true }) => {
  const { isAuthenticated, user } = useAuth();
  const [couponCode, setCouponCode] = useState('');
  const { validateCoupon } = useCart();
  const { saldo } = useSaldo();

  // Inicializando com "prePago" para ser o método de pagamento padrão
  const [methodPayment, setMethodPayment] = useState('prePago');

  // Criação da referência para o h1
  const titleRef = useRef(null);

  // Efeito para rolar a página até o h1 ao carregar
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const validarCupon = async (isPix = false) => {
    if (couponCode.toLowerCase() === 'pix10') {
      if (isPix) {
        validateCoupon(couponCode);
        return true;
      } else {
        toast.warn(`Cupon não aplicavel a modalidade de pagamento`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        return false;
      }
    }
    const cupomValidado = await validateCoupon(couponCode);
    return cupomValidado;
  };

  const handleMethodPayment = (method) => {
    console.log('method', method)
    setMethodPayment(method);
  };

  const formatarComoMoeda = (valor) => {
    if (!valor) return;

    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <>
      {/* Associando a referência ao h1 */}
      <h1 ref={titleRef} className={styles.titleSelecionePagamento}>
        Selecione a forma de pagamento
      </h1>
      <div className={styles.container}>
        {showPrePago && (
          <button
            disabled={user && user.saldo > 0 ? false : true}
            className={`${methodPayment === 'prePago' ? styles.cardSelected : styles.card}`}
            onClick={() => handleMethodPayment('prePago')}
          >
            <div>
              <img src={logo} alt='logo' />
            </div>
            <h1>Crédito Pré-Pago</h1>
            <h2>Na plataforma</h2>
            <h3>
              <span>Saldo atual:</span> {user ? formatarComoMoeda(saldo) : 'N/A'}
            </h3>
          </button>
        )}
        <button className={`${methodPayment === 'cartao' ? styles.cardSelected : styles.card}`} onClick={() => handleMethodPayment('cartao')}>
          <div>
            <BsFillCreditCardFill />
          </div>
          <h1>Cartão de Crédito</h1>
          <h2>Confirmação Imediata</h2>
        </button>
        <button className={`${methodPayment === 'pix' ? styles.cardSelected : styles.card}`} onClick={() => handleMethodPayment('pix')}>
          <div>
            <FaPix />
          </div>
          <h1>Pix</h1>
          <h2>Confirmação Imediata</h2>
        </button>
      </div>
      {methodPayment === 'prePago' && <CreditoPrePago validarCupon={validarCupon} couponCode={couponCode} setCouponCode={setCouponCode} items={items} handleNextStep={handleNextStep} />}
      {methodPayment === 'cartao' && <CartaoCredito validarCupon={validarCupon} couponCode={couponCode} setCouponCode={setCouponCode} items={items} handleNextStep={handleNextStep} />}
      {methodPayment === 'pix' && <Pix validarCupon={validarCupon} couponCode={couponCode} setCouponCode={setCouponCode} items={items} handleNextStep={handleNextStep} />}
    </>
  );
};

export default StepPagamento;
