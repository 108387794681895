import React, { useState } from 'react';
import styles from './style.module.scss';
import { Button } from 'antd';
import { MdOutlineFlipCameraAndroid } from 'react-icons/md';
import { Select, Space } from 'antd';

import logo from '../../assets/logo.png';

function CreditCard({ cardNumber, setCardNumber, cardHolderName, setCardHolderName, expiryDate, setExpiryDate, cvv, setCvv, cpf, setCpf, parcelas, setParcelas }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const formatCardNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(.{1,4})(.{0,4})(.{0,4})(.{0,4})$/);
    if (!match) return cleaned;
    const groups = match.slice(1).filter(Boolean).join(' ');
    return groups;
  };
  const formatExpiryDate = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(.{1,2})(.{0,2})$/);
    if (!match) return cleaned;
    const groups = match.slice(1).filter(Boolean).join('/');
    return groups;
  };

  const handleCardNumberChange = (e) => {
    const formatted = formatCardNumber(e.target.value);
    setCardNumber(formatted);
  };

  const handleExpiryDateChange = (e) => {
    const formatted = formatExpiryDate(e.target.value);
    setExpiryDate(formatted);
  };

  const handleChangeParcelas = (value) => {
    setParcelas(value)
  };

  const formatCPF = (cpfValue) => {
    return cpfValue
      .replace(/\D/g, '') // Remove caracteres que não são dígitos
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca ponto após o terceiro dígito
      .replace(/(\d{3})(\d)/, '$1.$2') // Coloca ponto após os próximos três dígitos
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca traço antes dos dois últimos dígitos
  };

  const handleCPFChange = (event) => {
    const cpfValue = event.target.value;
    const formattedCPF = formatCPF(cpfValue);
    setCpf(formattedCPF);
  };


  return (
    <>
      <div className={styles.cardContainer}>
        <div className={`${styles.creditCardWrapper} ${isFlipped ? styles.back : styles.front}`}>
          <div className={styles.headerCartao}>
            <svg xmlns='http://www.w3.org/2000/svg' width='42' height='34' viewBox='0 0 42 34' fill='none'>
              <path
                d='M37.6015 33.2874H3.92852C1.77095 33.2874 0 31.5165 0 29.3589V4.42839C0 2.27082 1.77095 0.499878 3.92852 0.499878H37.6015C39.7591 0.499878 41.5301 2.27082 41.5301 4.42839V29.3589C41.5301 31.5165 39.7591 33.2874 37.6015 33.2874Z'
                fill='#F6C859'
              />
              <path
                d='M15.9742 28.9097C15.7996 28.9097 15.625 28.81 15.5502 28.6478C15.4254 28.4109 15.5252 28.124 15.7622 27.9993C16.4606 27.6376 17.4084 26.9767 17.9571 25.8168C18.6556 24.3452 18.4685 22.7488 18.1816 22.6116C18.1068 22.5742 17.82 22.7239 17.6454 22.8112C17.1964 23.0481 16.5603 23.3599 15.837 23.1105C15.1261 22.8611 14.7769 22.225 14.6148 21.9257C13.5298 19.9303 13.193 14.7421 14.3404 12.1605C14.727 11.3 15.2384 10.8011 15.8869 10.6515C16.5479 10.5018 17.0841 10.7762 17.5082 11.0007C17.8574 11.1877 18.0445 11.2626 18.1442 11.2002C18.5932 10.9258 18.5807 9.27962 17.9571 7.97011C17.4084 6.81026 16.4606 6.14927 15.7622 5.7876C15.5252 5.66289 15.4379 5.37604 15.5502 5.13908C15.6749 4.90212 15.9617 4.81482 16.1987 4.92707C17.0218 5.3511 18.1567 6.14927 18.8177 7.55855C19.4787 8.94289 19.7905 11.3249 18.6306 12.0233C18.0445 12.3725 17.4957 12.0857 17.0467 11.8612C16.71 11.6866 16.3982 11.5245 16.0864 11.5993C15.6624 11.6991 15.3756 12.1855 15.201 12.5721C14.1908 14.8169 14.4776 19.7307 15.4379 21.4892C15.6 21.7885 15.7996 22.1128 16.1363 22.225C16.4606 22.3373 16.7599 22.2001 17.1964 21.9756C17.6204 21.7636 18.0943 21.5141 18.5932 21.7636C19.6657 22.2874 19.6034 24.6071 18.8177 26.2533C18.1567 27.6501 17.0218 28.4608 16.1987 28.8848C16.1238 28.8973 16.049 28.9097 15.9742 28.9097Z'
                fill='#7D662D'
              />
              <path
                d='M14.54 13.0336H5.88482C5.62292 13.0336 5.39844 12.8216 5.39844 12.5472C5.39844 12.2728 5.61045 12.0608 5.88482 12.0608H14.54C14.8019 12.0608 15.0264 12.2728 15.0264 12.5472C15.0264 12.8216 14.8019 13.0336 14.54 13.0336Z'
                fill='#7D662D'
              />
              <path
                d='M14.54 21.7012H5.88482C5.62292 21.7012 5.39844 21.4892 5.39844 21.2148C5.39844 20.9404 5.61045 20.7284 5.88482 20.7284H14.54C14.8019 20.7284 15.0264 20.9404 15.0264 21.2148C15.0264 21.4892 14.8019 21.7012 14.54 21.7012Z'
                fill='#7D662D'
              />
              <path
                d='M26.0759 28.9096C26.0011 28.9096 25.9263 28.8972 25.8514 28.8598C25.0283 28.4357 23.8934 27.6376 23.2324 26.2283C22.4467 24.582 22.3844 22.2623 23.4569 21.7385C23.9558 21.5016 24.4297 21.7385 24.8537 21.9506C25.2902 22.175 25.5895 22.3122 25.9138 22.2C26.2755 22.0753 26.4875 21.6887 26.6122 21.4642C27.5725 19.7057 27.8593 14.8044 26.8491 12.5471C26.6745 12.1604 26.3752 11.6741 25.9637 11.5743C25.7018 11.5119 25.5396 11.25 25.602 11.0006C25.6643 10.7387 25.9263 10.589 26.1757 10.6389C26.8242 10.7886 27.3355 11.2999 27.7221 12.1604C28.8695 14.742 28.5453 19.9302 27.4478 21.9256C27.2856 22.2249 26.9364 22.861 26.2256 23.1104C25.5022 23.3598 24.8662 23.048 24.4172 22.8111C24.2426 22.7238 23.9433 22.5741 23.8809 22.6115C23.5941 22.7487 23.407 24.3451 24.1054 25.8167C24.6542 26.9766 25.602 27.6376 26.3004 27.9992C26.5374 28.1239 26.6247 28.4108 26.5124 28.6478C26.4251 28.8099 26.2505 28.9096 26.0759 28.9096Z'
                fill='#7D662D'
              />
              <path
                d='M24.1544 11.1129C23.9548 11.1129 23.7678 10.9882 23.6929 10.7887C22.6578 7.69575 25.0773 5.31369 25.8505 4.9146C26.0875 4.78988 26.3743 4.88967 26.499 5.12663C26.6237 5.36359 26.524 5.65042 26.287 5.77513C25.9378 5.94973 23.7179 7.85787 24.6034 10.4894C24.6907 10.7388 24.5535 11.0132 24.304 11.1005C24.2542 11.1005 24.2043 11.1129 24.1544 11.1129Z'
                fill='#7D662D'
              />
              <path
                d='M36.1787 13.0336H27.5235C27.2616 13.0336 27.0371 12.8216 27.0371 12.5472C27.0371 12.2728 27.2491 12.0608 27.5235 12.0608H36.1787C36.4406 12.0608 36.6651 12.2728 36.6651 12.5472C36.6651 12.8216 36.4531 13.0336 36.1787 13.0336Z'
                fill='#7D662D'
              />
              <path
                d='M36.1787 21.7012H27.5235C27.2616 21.7012 27.0371 21.4892 27.0371 21.2148C27.0371 20.9404 27.2491 20.7284 27.5235 20.7284H36.1787C36.4406 20.7284 36.6651 20.9404 36.6651 21.2148C36.6651 21.4892 36.4531 21.7012 36.1787 21.7012Z'
                fill='#7D662D'
              />
            </svg>
            <img className={styles.creditCardLogo} src={logo} alt='Card Logo' />
          </div>

          <svg className={styles.svgQuatro} xmlns='http://www.w3.org/2000/svg' width='116' height='123' viewBox='0 0 116 123' fill='none'>
            <path d='M115.16 0.000244141C109.082 66.652 60.4244 119.139 0.240234 122.578V25.0769C0.240234 11.2351 10.2415 0.000244141 22.5634 0.000244141H115.16Z' fill='url(#paint0_linear_64_1268)' />
            <defs>
              <linearGradient id='paint0_linear_64_1268' x1='-12.6794' y1='108.06' x2='112.307' y2='-3.20216' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#1B1464' />
                <stop offset='1' stopColor='#83EFFF' />
              </linearGradient>
            </defs>
          </svg>
          <svg className={styles.svgPonta} xmlns='http://www.w3.org/2000/svg' width='158' height='31' viewBox='0 0 158 31' fill='none'>
            <path opacity='0.33' d='M157.643 81.0869H0.123047L68.172 5.27764C74.0628 -1.27256 83.6922 -1.27256 89.5829 5.27764L157.643 81.0869Z' fill='url(#paint0_linear_64_1269)' />
            <defs>
              <linearGradient id='paint0_linear_64_1269' x1='39.5049' y1='124.96' x2='126.734' y2='46.6603' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#02B6FD' />
                <stop offset='1' stopColor='#83EFFF' />
              </linearGradient>
            </defs>
          </svg>
          <svg className={styles.svgDois} xmlns='http://www.w3.org/2000/svg' width='356' height='82' viewBox='0 0 356 82' fill='none'>
            <path
              opacity='0.27'
              d='M371.75 104.091V107.019C371.75 120.856 361.79 132.087 349.519 132.087H22.4711C10.2001 132.087 0.240234 120.856 0.240234 107.019V8.41191C6.40326 4.71382 13.5238 1.89681 21.5027 0.904037C39.9917 -1.36694 54.4969 7.31985 71.5332 17.8309C104.208 37.9966 109.348 53.9431 127.826 67.1966C163.373 92.6985 192.582 68.3259 252.913 70.0136C298.366 71.3042 346.635 86.9405 371.75 104.091Z'
              fill='url(#paint0_linear_64_1270)'
            />
            <defs>
              <linearGradient id='paint0_linear_64_1270' x1='68.9574' y1='194.887' x2='283.521' y2='4.60318' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#1B1464' />
                <stop offset='1' stopColor='#83EFFF' />
              </linearGradient>
            </defs>
          </svg>
          <svg className={styles.svgTres} xmlns='http://www.w3.org/2000/svg' width='162' height='198' viewBox='0 0 162 198' fill='none'>
            <path
              d='M176.759 25.0983V188.583C141.951 203.543 102.714 198.498 93.1866 179.501C86.2031 165.573 100.444 154.714 96.4866 129.019C94.6009 116.843 89.6565 107.947 82.6071 99.5475C73.1678 88.2534 59.9352 77.8787 45.3432 61.7017C35.9587 51.3021 12.9579 25.8065 0.416016 0.000244141H154.613C158.988 0.000244141 163.077 1.44152 166.509 3.95132C172.681 8.37454 176.759 16.1897 176.759 25.0983Z'
              fill='url(#paint0_linear_64_1266)'
            />
            <defs>
              <linearGradient id='paint0_linear_64_1266' x1='52.8161' y1='134.827' x2='182.094' y2='20.7563' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#83EFFF' />
                <stop offset='1' stopColor='#1B1464' />
              </linearGradient>
            </defs>
          </svg>
          <svg className={styles.svgPonta} xmlns='http://www.w3.org/2000/svg' width='357' height='215' viewBox='0 0 357 215' fill='none'>
            <path
              opacity='0.53'
              d='M371.759 25.2469V241.833C371.759 255.769 361.799 267.08 349.528 267.08H22.4809C10.2099 267.08 0.25 255.769 0.25 241.833V25.2469C0.25 11.3111 10.2099 0 22.4809 0H349.539C353.931 0 358.036 1.44982 361.469 3.96201C367.654 8.42397 371.759 16.298 371.759 25.2469Z'
              fill='url(#paint0_linear_64_1271)'
            />
            <defs>
              <linearGradient id='paint0_linear_64_1271' x1='40.8515' y1='298.386' x2='367.897' y2='10.4099' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#00897F' />
                <stop offset='1' stopColor='#1BD78D' />
              </linearGradient>
            </defs>
          </svg>

          <input type='text' className={styles.cardNumber} placeholder='•••• •••• •••• ••••' value={cardNumber} onChange={handleCardNumberChange} maxLength={19} />
          <input type='text' className={styles.cardHolder} placeholder='Nome do titular' value={cardHolderName} onChange={(e) => setCardHolderName(e.target.value)} />
          <input type='text' className={styles.cardExpiry} placeholder='MM/AA' value={expiryDate} onChange={handleExpiryDateChange} maxLength={5} />
        </div>

        <div className={`${styles.creditCardWrapper} ${isFlipped ? styles.front : styles.back}`}>
          <div className={styles.cvvWrapper}>
            <Select
              defaultValue='Parcelas'
              className={styles.parcelas}
              onChange={handleChangeParcelas}
              options={[
                {
                  value: '01',
                  label: '01',
                },
                {
                  value: '02',
                  label: '02',
                },
                {
                  value: '03',
                  label: '03',
                },
                {
                  value: '04',
                  label: '04',
                },
                {
                  value: '05',
                  label: '05',
                },
                {
                  value: '06',
                  label: '06',
                },
                {
                  value: '07',
                  label: '07',
                },
                {
                  value: '08',
                  label: '08',
                },
                {
                  value: '09',
                  label: '09',
                },
                {
                  value: '10',
                  label: '10',
                },
                {
                  value: '11',
                  label: '11',
                },
                {
                  value: '12',
                  label: '12',
                },
              ]}
            />
            <input type='password' placeholder='CVV' value={cvv} onChange={(e) => setCvv(e.target.value)} maxLength={3} />
          </div>
        </div>
      </div>

      <Button icon={<MdOutlineFlipCameraAndroid />} onClick={toggleFlip} className={styles.buttonVirar}>
        Virar cartão
      </Button>
      <input className={styles.cpfInput} type='text' placeholder='CPF' value={cpf} onChange={handleCPFChange} maxLength={14} />{' '}
    </>
  );
}

export default CreditCard;
