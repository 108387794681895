import React, { useState, useCallback, Suspense, lazy, useEffect } from 'react';
import { Button, Col, Row, InputNumber, Spin } from 'antd';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { BsBarChart, BsSearch } from 'react-icons/bs';
import { FaRegCalendarAlt, FaPlus, FaMinus } from 'react-icons/fa';
import styles from './style.module.scss';
import { LuTicket } from 'react-icons/lu';
import { BsTrophy } from 'react-icons/bs';
import { IoRemoveOutline, IoSearch } from 'react-icons/io5';

const ModalVerBoloes = lazy(() => import('../ModalVerBoloes'));

const CardBoloes = ({ bolao, modalSetConcursoAndState, handleAddToCart, setApostaId, setModalVerApostas, quantidadeApostas, boloesArray, showModal, isLoading, setIsLoading, subBolaoData, setSubBolaoData, isModalVisible, setIsModalVisible }) => {
  // console.log('bolao', bolao);
  const [quantidades, setQuantidades] = useState({});


  const closeModal = useCallback(() => setIsModalVisible(false), []);

  const hexToRGBA = useCallback((hex, opacity) => {
    if (!hex) return '';
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }, []);

  const formatPrize = useCallback((value) => {
    if (!value) return;
    let numberPart,
      wordPart = '';
    if (value >= 1000000) {
      numberPart = (value / 1000000).toFixed(1);
      wordPart = numberPart > 1 ? 'Milhões' : 'Milhão';
    } else if (value >= 1000) {
      numberPart = (value / 1000).toFixed(1);
      numberPart = numberPart.endsWith('.0') ? numberPart.slice(0, -2) : numberPart;
      wordPart = 'Mil';
    } else {
      numberPart = value.toString();
    }
    return { number: numberPart, word: wordPart };
  }, []);

  const formatarComoMoeda = useCallback((valor) => {
    if (!valor) return 0;
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }, []);

  const updateQuantidade = useCallback((id, delta) => {
    setQuantidades((prev) => ({
      ...prev,
      [id]: (prev[id] || 1) + delta,
    }));
  }, []);

  // Filtra e ordena os bolões para que os esgotados apareçam por último
  const filteredBoloesArray = boloesArray.filter((b) => b.id === bolao.id);
  const sortedBoloesArray = filteredBoloesArray
    .flatMap((b) => b.boloes)
    .sort((a, b) => {
      // Primeiro, verifique se um bolão é esgotado e o outro não é
      if (a.cotas_restantes === 0 && b.cotas_restantes > 0) {
        return 1; // Se 'a' é esgotado e 'b' não é, 'a' deve vir depois
      } else if (a.cotas_restantes > 0 && b.cotas_restantes === 0) {
        return -1; // Se 'b' é esgotado e 'a' não é, 'b' deve vir depois
      } else if (a.cotas_restantes === 1 && b.cotas_restantes > 1) {
        return 1; // Se 'a' tem uma cota restante e 'b' tem mais de uma, 'a' deve vir depois
      } else if (a.cotas_restantes > 1 && b.cotas_restantes === 1) {
        return -1; // Se 'b' tem uma cota restante e 'a' tem mais de uma, 'b' deve vir depois
      } else {
        // Se ambos estiverem esgotados ou não, ordene pelo valor da cota
        return b.valor_cota - a.valor_cota; // Ordena do maior para o menor valor da cota
      }
    });

  const handleVerJogos = (subbolao) => {
    modalSetConcursoAndState(subbolao);
  };


  return (
    <Row gutter={[26, 26]} className={styles.boloesGrid}>
      {!boloesArray || (!bolao && <Spin />)}
      {sortedBoloesArray.map((subbolao, index) => (
        <Col key={subbolao.id} xs={20} sm={12} md={8} lg={6} xl={6}>
          <div className={styles.card}>
            <div className={styles.cardHeader} style={{ backgroundColor: hexToRGBA(bolao.cor, 1) }}>
              <h3 className={styles.cardTitle}>
                Grupo
                <br />
                {subbolao.grupo}
              </h3>
            </div>
            <div className={styles.cardBody} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.1) }}>
              <div className={styles.cardPrize} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.2) }}>
                <p className={styles.cardSubtitle} style={{ color: bolao.cor }}>
                  Concurso: {bolao.concurso}
                </p>
                <p className={styles.cardSubtitle} style={{ color: bolao.cor, marginBottom: 0 }}>
                  Cotas
                </p>
                <p className={styles.costValue} style={{ color: bolao.cor }}>
                  <LuTicket />
                  {subbolao.cotas_restantes} de {subbolao.cotas}
                </p>
              </div>
              <div className={styles.cardCost} style={{ backgroundColor: hexToRGBA(bolao.cor, 0.2), color: bolao.cor }}>
                <BsTrophy className={styles.cardIcon} style={{ backgroundColor: bolao.cor }} />
                <span className={styles.prizeAmount} style={{ color: bolao.cor }}>
                  {formatPrize(bolao.premiacao)?.number} {formatPrize(bolao.premiacao)?.word}
                </span>
              </div>

              <div className={styles.containerCotaValor}>
                <p>{formatarComoMoeda(subbolao.valor_cota)}</p>
              </div>

              <div className={styles.cardActions}>
                <div className={styles.quantityControl} style={{ borderColor: bolao.cor }}>
                  <Button icon={<FaMinus />} disabled={quantidades[subbolao.id] === 1} onClick={() => updateQuantidade(subbolao.id, -1)} className={styles.buttonAction} style={{ color: bolao.cor }} />
                  <IoRemoveOutline style={{ transform: 'rotate(90deg)', color: bolao.cor, fontSize: '1.5rem' }} />
                  <InputNumber min={1} max={subbolao.cotas_restantes} value={quantidades[subbolao.id] || 1} onChange={(value) => setQuantidades((prev) => ({ ...prev, [subbolao.id]: value }))} className={styles.inputNumber} />
                  <IoRemoveOutline style={{ transform: 'rotate(90deg)', color: bolao.cor, fontSize: '1.5rem' }} />
                  <Button icon={<FaPlus />} onClick={() => updateQuantidade(subbolao.id, 1)} className={styles.buttonAction} style={{ color: bolao.cor }} />
                </div>
                {subbolao.cotas_restantes === 0 ? (
                  <Button type='primary' disabled={subbolao.cotas_restantes === 0} className={styles.betButtonEsgotado} onClick={() => handleAddToCart(bolao.nome, subbolao, quantidades[subbolao.id] || 1, bolao.data_sorteio, bolao.nome)}>
                    Esgotado
                  </Button>
                ) : (
                  <Button
                    type='primary'
                    className={styles.betButton}
                    onClick={() => {
                      handleAddToCart(bolao.nome, subbolao, quantidades[subbolao.id] || 1, bolao.data_sorteio, bolao.nome);

                      // Envia evento para o GTM
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'purchase',
                        product: {
                          name: bolao.nome,
                          subBolao: subbolao,
                          quantidade: quantidades[subbolao.id] || 1,
                          dataSorteio: bolao.data_sorteio,
                          nome: bolao.nome,
                        },
                      });
                    }}
                  >
                    <MdOutlineShoppingCart size={18} />
                    Adicionar ao carrinho
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={styles.cardFooter}>
            <button className={styles.viewGamesButton} style={{ background: bolao.cor }} onClick={() => handleVerJogos(subbolao)}>
              <BsSearch style={{ fontSize: '16px', padding: '0px' }} />
              Ver jogos
            </button>

            <button className={styles.buttonEstrategia} onClick={() => showModal(subbolao)}>
              <BsBarChart style={{ fontSize: '18px' }} />
              Estratégia
            </button>
          </div>
        </Col>
      ))}
      <Suspense fallback={<Spin />}>
        {isModalVisible && (
          <ModalVerBoloes
            nome={bolao.nome}
            color={bolao.cor}
            regra={subBolaoData.regra}
            concurso={bolao.concurso}
            dataSorteio={bolao.data_sorteio}
            valor={`${formatPrize(bolao.premiacao)?.number} ${formatPrize(bolao.premiacao)?.word}`}
            isModalVisible={isModalVisible}
            closeModal={closeModal}
            cotas={subBolaoData.cotas}
            handleAddToCart={handleAddToCart}
            subBolaoCart={subBolaoData.subBolaoCart}
            quantidadeApostas={quantidadeApostas}
            modalSetConcursoAndState={modalSetConcursoAndState}
            setModalVerApostas={setModalVerApostas}
          />
        )}
      </Suspense>
    </Row>
  );
};

export default React.memo(CardBoloes);
