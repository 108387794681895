import React, { useState } from 'react';
import Slider from 'react-slick';
import styles from './style.module.scss';
import { Skeleton } from 'antd';
import { Height } from '@mui/icons-material';

const CarouselComponent = ({ images }) => {
  const [autoplay, setAutoplay] = useState(true);

  const settings = {
    dots: false, 
    infinite: true, 
    speed: 500,
    slidesToShow: 1.01, 
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    centerMode: true, 
  };

  return (
    <div className={styles.carouselWrapper}>
      {!images && <Skeleton.Input active size='large' block style={{ height: '15rem' }} />}

      <Slider {...settings}>
        {images &&
          images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <img src={image.url_imagem} alt={`Slide ${index}`} />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
