import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import styles from './style.module.scss';
import { Button } from 'antd';
import { MdShoppingCart, MdAttachMoney, MdDelete } from 'react-icons/md';
import { LuTicket } from 'react-icons/lu';
import { Divider } from 'antd';
import { CgArrowsVAlt } from 'react-icons/cg';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { PiTimerFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';


const StepCarrinho = ({ items, handleAddItem, handleRemoveItemComplete, handleRemoveItem, handleNextStep }) => {
  const navigate = useNavigate();

  function formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  // Função para calcular os totais por grupo
  const totals = items.reduce((acc, item) => {
    if (!acc[item.nome]) acc[item.nome] = 0;
    acc[item.nome] += item.quantidade * item.valor;
    return acc;
  }, {});
  console.log('totals', totals)

  // Total geral
  const grandTotal = Object.values(totals).reduce((acc, value) => acc + value, 0);

  const isVerticalDivider = window.innerWidth > 430;

  return (
    <div className={styles.container}>
      {items.length > 0 &&
        items.map((item) => (
          <div key={item.id} className={styles.containerCadaBolao}>
            <div className={styles.header}>
              <h1>Bolão: {item.nome}</h1>
            </div>
            <div className={styles.containerItems}>
              <div className={styles.grupo}>
                <h2>
                  <LuTicket />
                  Grupo
                </h2>
                <h1>{item.grupo}</h1>
              </div>
              <Divider type={isVerticalDivider ? 'vertical' : 'horizontal'} style={{ borderColor: '#B0DAD7' }} />

              <div className={styles.quantidade}>
                <h2>
                  <CgArrowsVAlt />
                  Quant.
                </h2>
                <h1>
                  <Button className={styles.buttonMinusPlus} onClick={() => handleRemoveItem(item.id)}>
                    <FiMinus />
                  </Button>
                  {item.quantidade}
                  <Button className={styles.buttonMinusPlus} onClick={() => handleAddItem(item)}>
                    <FiPlus />
                  </Button>
                </h1>
              </div>
              <Divider type={isVerticalDivider ? 'vertical' : 'horizontal'} style={{ borderColor: '#B0DAD7' }} />

              <div className={styles.valor}>
                <h2>
                  <MdAttachMoney />
                  Valor
                </h2>
                <h1>{formatCurrency(item.quantidade * item.valor)}</h1>
              </div>
              <div className={styles.remover}>
                <Button className={styles.removerButton} onClick={() => handleRemoveItemComplete(item.id)} icon={<MdDelete style={{ fontSize: '16px' }} />}>
                  Remover
                </Button>
                <BolaoCounter addedAt={item.addedAt} />
              </div>
            </div>
            {/* <BolaoCounter addedAt={item.addedAt} /> */}
          </div>
        ))}
      <Divider style={{ width: '100%', borderColor: '#00302C' }} />

      <div className={styles.totalContainer}>
        <h1>Resumo do pedido</h1>
        {Object.keys(totals).map((nome) => (
          <div key={nome} className={styles.containerTotalValorNomes}>
            <h2>{nome}:</h2>
            <p>{formatCurrency(totals[nome])}</p>
          </div>
        ))}
        <Divider style={{ width: '100%', borderColor: '#000' }} />

        <div className={styles.valorTotal}>
          <h3>Valor Total:</h3>
          <h5>{formatCurrency(grandTotal)}</h5>
        </div>
        <Button className={styles.containerButtonProximo} onClick={handleNextStep}>
          PRÓXIMO PASSO
        </Button>
        <Button className={styles.containerButtonProximo} style={{background:'#feac24'}} onClick={() => navigate('/')}>
          VOLTAR ÀS COMPRAS
        </Button>
      </div>
    </div>
  );
};

const BolaoCounter = ({ addedAt }) => {
  const countdownDuration = 15 * 60 * 1000; // 15 minutos em milissegundos
  const [timeLeft, setTimeLeft] = useState(() => {
    const now = Date.now();
    const elapsed = now - addedAt;
    return countdownDuration - elapsed;
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1000), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  return (
    <div className={styles.containerTimer}>
      <h1>
        <PiTimerFill />
        {Math.max(0, Math.floor(timeLeft / 60000))}:{String(Math.max(0, ((timeLeft % 60000) / 1000).toFixed(0))).padStart(2, '0')}
      </h1>
    </div>
  );
};

export default StepCarrinho;
