import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Row, Col } from 'antd';

import styles from './style.module.scss';
import logo from '../../assets/logo.png';

const WelcomeMessage = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const ageConfirmation = localStorage.getItem('ageConfirmation');
    if (!ageConfirmation) {
      setIsModalVisible(true);
    }
    if(ageConfirmation === 'no'){
      setIsModalVisible(true);

    }
  }, []);

  const handleConfirm = () => {
    localStorage.setItem('ageConfirmation', 'yes');
    setIsModalVisible(false);
  };

  const handleDeny = () => {
    localStorage.setItem('ageConfirmation', 'no');
    window.location.href = 'https://www.youtube.com/@TribodaSorte'; // Coloque a URL correta aqui
  };

  return (
    <Modal title={<h1 className={styles.header}>Seja bem-vindo ao Tribo da Sorte!</h1>} open={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)} width={900} className={styles.container}>
      <div className={styles.containerLogo}>
        <img src={logo} alt='logo tribo da sorte' />
      </div>
      <h1>Você tem 18 anos ou mais?</h1>
      <Row gutter={[24, 24]} className={styles.boloesGrid}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button className={styles.buttonSim} onClick={handleConfirm}>
            SIM
          </Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button className={styles.buttonNao} onClick={handleDeny}>
            NÃO
          </Button>
        </Col>
      </Row>

      <p>
        A Tribo da Sorte não possui qualquer vínculo com a Caixa Econômica Federal. Somos uma empresa independente que faz o trabalho de intermediação e representação. Todos os bolões aqui ofertados devem e são validados em Lotérica. Todos os
        direitos de loteria reservados à Caixa Econômica Federal.
      </p>
      <p>Nosso site usa cookies para melhorar sua experiência. Ao prosseguir você concorda com nossa política de privacidade.</p>
    </Modal>
  );
};

export default WelcomeMessage;
