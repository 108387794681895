import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import styles from './style.module.scss';
import { Button, Input, Divider } from 'antd';

const CardInsercaoSaldo = ({ valorSaldo, setValorSaldo, handleNextStep }) => {
  function formatarComoMoeda(valor) {
    // Certifica-se de que valor é tratado como uma string
    const valorString = String(valor);
    const numero = Number(valorString.replace(/\D/g, '')) / 100;
    return numero.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  const handleChangeSaldoValor = (e) => {
    const valorNumerico = e.target.value.replace(/\D/g, '');
    setValorSaldo(valorNumerico);
  };

  const isZero = Number(valorSaldo) === 0;

  return (
    <>
      <div className={styles.container}>
        <h1>Digite o valor que você deseja</h1>

        <div className={styles.containerValue}>
          <Input
            value={formatarComoMoeda(valorSaldo)}
            className={`${styles.inputValor} ${isZero ? styles.blinkingPlaceholder : ''}`}
            onChange={handleChangeSaldoValor}
            placeholder="R$ 0,00"
          />
        </div>
        <div className={styles.valoresPreSetados}>
          <Button onClick={() => setValorSaldo((prev) => String(Number(prev) + 500))}>+5</Button>
          <Button onClick={() => setValorSaldo((prev) => String(Number(prev) + 1000))}>+10</Button>
          <Button onClick={() => setValorSaldo((prev) => String(Number(prev) + 2000))}>+20</Button>
          <Button onClick={() => setValorSaldo((prev) => String(Number(prev) + 5000))}>+50</Button>
        </div>
        <Button 
          className={styles.buttonAdicionar} 
          onClick={() => {
            handleNextStep();

            // Envia evento para o GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'adicionarSaldo',
              product: {
                value: valorSaldo,
                currency: 'BRL',
              }
            });
          }}
        >
          Adicionar ao carrinho
        </Button>
      </div>
    </>
  );
};

export default CardInsercaoSaldo;
