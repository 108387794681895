import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import styles from './style.module.scss';
import { Button, Input, Divider, Checkbox } from 'antd';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { FaPix } from 'react-icons/fa6';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.png';
import { useSaldo } from '../../contexts/SaldoContext';

const CreditoPrePago = ({ items, handleNextStep, couponCode, setCouponCode, validarCupon }) => {
  const { clearCart, coupon } = useCart();
  const { fetchSaldo } = useSaldo();
  const navigate = useNavigate();

  const [isSaldoRoute, setIsSaldoRoute] = useState(false);

  useEffect(() => {
    setIsSaldoRoute(window.location.href.includes('/saldo'));
  }, []);

  const [loading, setLoading] = useState(false);

  function formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  // Função para calcular os totais por grupo
  const totals = items.reduce((acc, item) => {
    if (!acc[item.nome]) acc[item.nome] = 0;
    acc[item.nome] += item.quantidade * item.valor;
    return acc;
  }, {});

  const initialGrandTotal = Object.values(totals).reduce((acc, value) => acc + value, 0);
  const totalQuantidade = items.reduce((acc, item) => acc + item.quantidade, 0);

  let grandTotal = initialGrandTotal;
  let grandTotalBeforeDiscount = initialGrandTotal;
  let desconto = 0;
  if (coupon && coupon.isValid) {
    if (coupon.is_porcentagem === 1) {
      desconto = initialGrandTotal * (coupon.discount / 100);
      grandTotal -= desconto;
    } else {
      desconto = coupon.discount;
      grandTotal -= desconto;
    }
  }

  const handlePayCard = async () => {
    setLoading(true);
    if (!aceiteTermos) {
      toast.warn(`Por favor aceite os termos de uso e a política de privacidade`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);

      return;
    }
    try {
      const responsePayment = await api.post('/pagamento_com_saldo', {
        boloes: items,
        quantity: totalQuantidade,
        total_desconto: grandTotal,
        cupom_desconto_id: coupon['id'],
        total: grandTotalBeforeDiscount,
      });
      clearCart();
      fetchSaldo();

      if (responsePayment.status === 200 && responsePayment.data.status === 'approved' && responsePayment.data.status_info === 'pago totalmente') {
        clearCart();
        toast.success(`Compra feita com sucesso`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        handleNextStep('saldo');
        clearCart();
        window.scrollTo(0, 0);
        navigate(`/minha-conta`);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.log('e', e.message);
    }
  };

  const [cupon, setCupom] = useState('');
  const [cupomAplicado, setCupomAplicado] = useState(false);

  const handleCupon = async () => {
    const validar = await validarCupon(cupon);
    console.log('validar', validar);
    if (validar) {
      setCupomAplicado(true);
      return;
    }
  };

  const [aceiteTermos, setAceiteTermos] = useState(false);

  return (
    <>
      <div className={styles.container}>
        {!isSaldoRoute && (
          <div className={styles.containerCupom}>
            <h1>Você tem algum cupom de desconto?</h1>
            <div>
              <Input className={styles.containerInput} onChange={(e) => setCouponCode(e.target.value)} disabled={cupomAplicado} />
              <Button className={styles.button} onClick={handleCupon} disabled={cupomAplicado}>
                Aplicar Cupom
              </Button>
            </div>
          </div>
        )}
        <Checkbox onChange={(e) => setAceiteTermos(e.target.checked)}>
          Li e concordo com os &nbsp;
          <strong>Termos de Uso</strong>
        </Checkbox>
        <div className={styles.totalContainer}>
          <h1>Resumo do pedido</h1>
          {Object.keys(totals).map((nome) => (
            <div key={nome} className={styles.containerTotalValorNomes}>
              <h2>{nome}:</h2>
              <p>{formatCurrency(totals[nome])}</p>
            </div>
          ))}
          <div className={styles.containerTotalValorNomes}>
            {coupon && coupon.isValid && (
              <>
                <h2 style={{ color: 'green' }}>CUPOM: </h2>
                <p style={{ color: 'green' }}>
                  {couponCode} -{formatCurrency(desconto)}
                </p>
              </>
            )}
          </div>
          <Divider style={{ width: '100%', borderColor: '#000' }} />

          <div className={styles.valorTotal}>
            <h3>Valor Total:</h3>
            <h5>{formatCurrency(grandTotal)}</h5>
          </div>
          <Button loading={loading} disabled={loading} className={styles.containerButtonProximo} onClick={handlePayCard}>
            Confirmar compra
          </Button>
        </div>
        <p className={styles.aviso}>Após finalizar a compra não é possivel estorná-la</p>
      </div>
    </>
  );
};

export default CreditoPrePago;
