import React, { useState, useEffect } from 'react';
import { Spin, Table } from 'antd';
import { MdOutlineMonetizationOn } from 'react-icons/md';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import styles from './style.module.scss';
import moment from 'moment';

const Saldo = () => {
  const [saldoData, setSaldoData] = useState([]);
  const [usuarioSaldo, setUsuarioSaldo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAndCombineData = async () => {
      setLoading(true);
      try {
        const { data: usuarioSaldoResponse } = await api.get('/saldo');
        setUsuarioSaldo(usuarioSaldoResponse);
        const usuarioId = usuarioSaldoResponse.usuario_id;

        const { data: saldos } = await api.get(`/extrato_cliente/${usuarioId}`);
        const { data: transacoesCliente } = await api.get(`/transacoes_cliente`);

        const transacoesSaldo = transacoesCliente.filter((transacao) => transacao.tipo_pagamento === 'saldo');

        // Combinar e adicionar chave de data
        const extratoCombinado = [...saldos, ...transacoesSaldo].map((item) => ({
          ...item,
          dataTransacao: item.created_at || item.data_pagamento, // Ajustar de acordo com o campo disponível
        }));

        // Ordenar pelo campo de data
        extratoCombinado.sort((a, b) => new Date(b.dataTransacao) - new Date(a.dataTransacao));

        setSaldoData(extratoCombinado);
      } catch (error) {
        toast.error('Erro ao buscar dados');
      } finally {
        setLoading(false);
      }
    };

    fetchAndCombineData();
  }, []);

  function formatarComoMoeda(valor) {
    return valor ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';
  }

  const columns = [
    {
      title: 'Data',
      dataIndex: 'dataTransacao',
      key: 'dataTransacao',
      width: 100,
      render: (text) => moment(text).format('DD/MM/YY'),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo_pagamento',
      key: 'tipo_pagamento',
      width: 100,
      render: (text, record) => record.tipo_pagamento ? 'Compra' : record.operacao,
    },
    {
      title: 'Valor',
      dataIndex: 'total',
      key: 'total',
      width: 120,
      render: (text, record) => formatarComoMoeda(record.total || record.valor),
    },
    {
      title: 'Produto',
      dataIndex: 'produto',
      key: 'produto',
      width: 150,
      render: (text) => text || '-',
    },

    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      width: 200,
      render: (text) => text || '-',
    },
  ];

  return (
    <div className={styles.container}>
      <h1>Seu Saldo</h1>
      <div className={styles.containerTextSaldos}>
        <p>Saldo em conta</p>
        <h2>{loading ? <Spin /> : formatarComoMoeda(usuarioSaldo.saldo)}</h2>
      </div>
      <div className={styles.containerTabela}>
        {loading ? (
          <Spin />
        ) : (
          <Table columns={columns} dataSource={saldoData} rowKey="dataTransacao" />
        )}
      </div>
    </div>
  );
};

export default Saldo;
