// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.style_container__\\+rw0J {
  border-radius: 0.5rem;
  background: var(--Foundation-Teal-Green---Neutral-T30, #ebf6f5);
  -webkit-backdrop-filter: blur(10.5px);
          backdrop-filter: blur(10.5px);
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 1.5rem;
}
.style_container__\\+rw0J .style_input__H1p7J {
  border-radius: 0.5rem;
  border: 1px solid var(--System-colors-Gray-300, #cdd1d6);
  background: var(--black-white-white, #fff);
  margin-bottom: 2rem;
  /* 🌞 Drop shadow [Light]/xs */
  box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);
  width: 100%;
}
.style_container__\\+rw0J .style_input__H1p7J svg {
  color: #a3d5d1;
}
.style_container__\\+rw0J .style_input__H1p7J svg::placeholder {
  color: #a3d5d1;
}
.style_container__\\+rw0J .style_buttonEntrar__Gc9K3 {
  border-radius: 2.9375rem;
  background: var(--Foundation-Teal-Green-Normal, #00897f);
  box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);
  width: 100%;
  color: var(--black-white-white, #fff);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/Pages/TrocarSenha/style.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,qBAAA;EACA,+DAAA;EACA,qCAAA;UAAA,6BAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EAEA,WAAA;AACF;AAAE;EACE,qBAAA;EACA,wDAAA;EACA,0CAAA;EACA,mBAAA;EACA,8BAAA;EACA,kDAAA;EACA,WAAA;AAEJ;AADI;EACE,cAAA;AAGN;AAFM;EACE,cAAA;AAIR;AACE;EACE,wBAAA;EACA,wDAAA;EACA,kDAAA;EACA,WAAA;EACA,qCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":[".container {\n  border-radius: 0.5rem;\n  background: var(--Foundation-Teal-Green---Neutral-T30, #ebf6f5);\n  backdrop-filter: blur(10.5px);\n  width: 70%;\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 2rem;\n  // margin-top: 2rem;\n  gap: 1.5rem;\n  .input {\n    border-radius: 0.5rem;\n    border: 1px solid var(--System-colors-Gray-300, #cdd1d6);\n    background: var(--black-white-white, #fff);\n    margin-bottom: 2rem;\n    /* 🌞 Drop shadow [Light]/xs */\n    box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);\n    width: 100%;\n    svg {\n      color: #a3d5d1;\n      &::placeholder {\n        color: #a3d5d1;\n      }\n    }\n  }\n\n  .buttonEntrar {\n    border-radius: 2.9375rem;\n    background: var(--Foundation-Teal-Green-Normal, #00897f);\n    box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);\n    width: 100%;\n    color: var(--black-white-white, #fff);\n    font-family: Inter;\n    font-size: 1rem;\n    font-style: normal;\n    font-weight: 500;\n    padding: 1.5rem 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: row;\n    // margin-top: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__+rw0J`,
	"input": `style_input__H1p7J`,
	"buttonEntrar": `style_buttonEntrar__Gc9K3`
};
export default ___CSS_LOADER_EXPORT___;
