import React from 'react';
import { Layout, Button, Row, Col, Input } from 'antd';
import { FaArrowRight } from 'react-icons/fa';

import styles from './style.module.scss';
import logo from '../../assets/logo.png';
import { IoCardOutline } from 'react-icons/io5';
import { MdOutlinePix } from 'react-icons/md';
import { BsUpcScan } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

const { Search } = Input;

const { Footer } = Layout;

const FooterComponent = () => {
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const navigate = useNavigate();

  // navigate(`/bolao/${id}`);

  return (
    <Footer style={{ textAlign: 'center' }} className={styles.container}>
      <Row gutter={26} className={styles.containerRow}>
        <Col xs={24} sm={12} md={6} lg={4} xl={4} className={styles.rows}>
          <img src={logo} alt='logo' />
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4} className={styles.containerTextos}>
          <h3>Jogos</h3>
          <Button className={styles.button} onClick={() => navigate('minha-conta')}>
            Minha Conta
          </Button>
          <Button className={styles.button} onClick={() => navigate('saldo')}>
            Inserir Créditos
          </Button>
          <Button className={styles.button} onClick={() => navigate('/')}>
            Bolões
          </Button>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4} className={styles.containerTextos}>
          <h3>Sobre a empresa</h3>
          <Button className={styles.button} onClick={() => navigate('conheca')}>
            Quem somos
          </Button>
          <Button className={styles.button}>
            <a href='https://sojogosonline.com.br/' target='_blank' rel='noopener noreferrer'>
              Blog
            </a>
          </Button>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={4} className={styles.containerTextos}>
          <h3>Suporte</h3>
          <Button className={styles.button}>
            <a href='https://api.whatsapp.com/send?phone=5534991294718&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es.' target='_blank' rel='noopener noreferrer'>
              Fale Conosco
            </a>
          </Button>
          <Button className={styles.button} onClick={() => navigate('conheca')}>
            Como Funciona
          </Button>
        </Col>
        {/* <Col xs={24} sm={12} md={6} lg={4} xl={6} className={styles.rowNewsLetter}>
          <h1>Inscreva-se em nossa newsletter.</h1>
          <p>
            Quer ficar atualizado com novidades e promoções sobre nossos bolões?
            <br />
            Se inscreva.
          </p>

          <Input size='large' placeholder='Digite seu e-mail' suffix={<FaArrowRight />} />
          <p>Ao subscrever a nossa newsletter concorda com a nossa política de privacidade e receberá comunicações comerciais.</p>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className={styles.containerPayments}>
          <div className={styles.textosPayments}>
            <IoCardOutline />
            <p>
              Cartão de
              <br />
              <span>Crédito</span>
            </p>
          </div>
          <div className={styles.textosPayments}>
            <MdOutlinePix />
            <p>
              Transferência
              <br />
              <span>Pix</span>
            </p>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

export default FooterComponent;
