import React, { useEffect, useState } from 'react';
import { Layout, Button, Input, Spin, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { CiSearch } from 'react-icons/ci';
import { MdShoppingCart } from 'react-icons/md';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useCart } from '../../contexts/CartProvider';
import styles from './style.module.scss';
import { MdOutlinePayment } from 'react-icons/md';
import { BsPerson } from 'react-icons/bs';

import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import { useSaldo } from '../../contexts/SaldoContext';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/logo.png';
import DropDownCarrinho from '../DropDownCarrinho';

const { Search } = Input;
const { Header } = Layout;

const HeaderComponent = ({ collapsed, toggleCollapsed, onResetStep }) => {
  const { user, logout, isAuthenticated } = useAuth();
  const { items } = useCart();
  const [visible, setVisible] = useState(false);
  const [loadingName, setLoadingName] = useState(false);
  const { saldo, loading, fetchSaldo } = useSaldo();

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const formatarComoMoeda = (valor) => {
    if (valor == null) return '';
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };
  useEffect(() => {
    fetchSaldo();
  }, []);

  const cartContent = <DropDownCarrinho onResetStep={onResetStep} setVisible={setVisible} />;

  return (
    <Header className={styles.container}>
      <div className={styles.containerHeader}>
        <div className={styles.containerButtons}>
          {isMobile && collapsed && (
            <div className={styles.logoContainer}>
              <Link to='/'>
                <img src={logo} alt='Logo da empresa' className={styles.logo} />
              </Link>
            </div>
          )}
          {isMobile && collapsed && (
            <Button icon={<MdOutlinePayment style={{ fontSize: '16px' }} />} className={styles.buttonInserirCreditos}>
              <Link to='/saldo'>Inserir créditos</Link>
            </Button>
          )}
          {isDesktop && (
            <Button icon={<MdOutlinePayment style={{ fontSize: '16px' }} />} className={styles.buttonInserirCreditos}>
              <Link to='/saldo'>Inserir créditos</Link>
            </Button>
          )}
          <Input size='large' className={styles.containerSearch} placeholder='Pesquisar por bolões' prefix={<CiSearch style={{ fontSize: '24px', color: '#DDDDDD' }} />} />
        </div>

        <div className={styles.containerCarrinho}>
          <Popover content={cartContent} trigger='click' visible={visible} onVisibleChange={handleVisibleChange} overlayClassName={styles.customPopover}>
            <Button icon={<MdShoppingCart style={{ fontSize: '34px' }} />} className={styles.buttonCarrinho} />
          </Popover>
          <div className={styles.circleBackground}>
            <p>{items?.length}</p>
          </div>
        </div>
        <div className={styles.containerCarrinho}></div>

        <div className={styles.containerRegister}>
          {isMobile ? (
            <Button type='text' onClick={toggleCollapsed} style={{ fontSize: '24px', color: '#000' }} className={styles.menuButton}>
              <RxHamburgerMenu />
            </Button>
          ) : (
            <Link to='/minha-conta?destino=perfil' style={{ fontSize: '34px', color: '#000' }}>
              <BsPerson />
            </Link>
          )}
          <div className={styles.containerAuth}>
            {isAuthenticated ? (
              <>
                <Link to='/minha-conta?destino=perfil'>
                  <h4>Olá, {loadingName ? <Spin /> : user.nome?.length > 10 ? `${user.nome.substring(0, 10)}...` : user.nome}</h4>
                  <p>
                    Saldo
                    <span>{loading ? <Spin /> : formatarComoMoeda(saldo)}</span>
                  </p>
                </Link>
              </>
            ) : (
              <>
                <Button className={styles.buttonEnter}>
                  <Link to='/login'>
                    Entre <span className={styles.span}>ou</span>
                  </Link>
                </Button>
                <Button className={styles.buttonCadastrar}>
                  <Link to='/cadastrar'>cadastre-se </Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
