import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { MdAccessTime } from 'react-icons/md';

const CountDown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const distance = new Date(targetDate) - now;

      if (distance < 0) {
        clearInterval(timer);
        setTimeLeft('EXPIRADO');
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      setTimeLeft(`${days}d ${hours}h ${minutes}m `);
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className={styles.countDown}>
      <div>
        <MdAccessTime size={22} />
      </div>

      <h2 >{timeLeft}</h2>
    </div>
  );
};

export default CountDown;
