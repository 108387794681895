import React, { useState } from 'react';
import { Button, Col, Row, InputNumber, Modal, Divider } from 'antd';
import { FaRegCalendarAlt, FaShoppingCart, FaPlus, FaMinus } from 'react-icons/fa';
import styles from './style.module.scss';
import { HiOutlineTicket } from "react-icons/hi2";
import { IoCalendarOutline } from "react-icons/io5";
import { BsQuestionOctagonFill, BsLightbulbFill } from 'react-icons/bs';
import { MdSearch, MdShoppingCart } from 'react-icons/md';

const ModalVerBoloes = ({ nome, modalSetConcursoAndState, regra, concurso, dataSorteio, valor, isModalVisible, closeModal, color, cotas, handleAddToCart, subBolaoCart, setModalVerApostas, quantidadeApostas }) => {
  const formatDate = (dateString) => {
    const options = { month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const hexToRGBA = (hex, opacity) => {
    if (!hex) return '';
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const formatarRegra = (regra) => {
    if (!regra) return '';
    const regraFormatada = regra
      .replace(/\[\[([^\]]+)\]\]/g, '<h2>$1</h2>')
      .replace(/\*\*\*/g, '<p>')
      .replace(/\*\*/g, '</p>')
      .replace(/\{\{/g, '<strong>')
      .replace(/\}\}/g, '</strong>')
      .replace(/\/\/\s*/g, '<br/>')
      .replace(/\|\|\s*/g, '<hr/>')
      .replace('<h2>DESCRIÇÃO DA ESTRATÉGIA</h2>', '');

    const returnRegra = regraFormatada.split(/<h2>MATRIZ/);

    const primeiroTexto = returnRegra[0];
    let segundoTexto = '';
    for (let i = 1; i < returnRegra.length; i++) {
      segundoTexto += returnRegra[i];
    }

    return [primeiroTexto, segundoTexto];
  };

  const handleVerJogos = () => {
    closeModal();

    modalSetConcursoAndState(subBolaoCart);
  };

  const regrasFormatadas = formatarRegra(regra);
  

  return (
    <Modal
      title={
        <h1 className={styles.header} style={{ background: color }}>
          {nome}
        </h1>
      }
      open={isModalVisible}
      onOk={closeModal}
      onCancel={closeModal}
      width={900}
      footer=''
      className={styles.container}
      style={{ zIndex: 1000 }}
    >
      <div className={styles.cardHeader} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h1 style={{ color: color }}>{valor}</h1>
        <div className={styles.divider}>
          <Divider style={{ background: color }} />
        </div>
        <div>
          <IoCalendarOutline size={30} className={styles.icon} style={{ backgroundColor: color }}/>
          <p style={{ color: color }}>Sorteio {formatDate(dataSorteio)}</p>
          <HiOutlineTicket size={30} className={styles.icon} style={{ backgroundColor: color }}/>
          <p style={{ color: color }}>Concurso {concurso}</p>
        </div>
      </div>

      <div className={styles.containerButtons}>
        <h1>Quando você compara, aposta com a Tribo</h1>
        <div className={styles.rowButtons}>
        <Button className={styles.buttonComprar} onClick={handleVerJogos}>
            <MdSearch style={{ fontSize: '20px', marginRight: '.5rem' }} />
            Ver jogos
          </Button>
          <Button
            className={styles.buttonComprar}
            style={{ background: '#FEAC24' }}
            onClick={() => {
              handleAddToCart(nome, subBolaoCart, 1);

              // Envia evento para o GTM
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'purchase',
                product: {
                  name: nome,
                  subBolao: subBolaoCart,
                  quantidade: 1,
                  valor: valor,
                }
              });
            }}
          >
            <MdShoppingCart style={{ fontSize: '20px', marginRight: '.5rem' }} />
            Add carrinho
          </Button>
          
        </div>
      </div>


      <div className={styles.card} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h2>
          <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
          Nossa Estratégia
        </h2>
        <div dangerouslySetInnerHTML={{ __html: regrasFormatadas[0] }} className={styles.regras} />
      </div>

      {regrasFormatadas[1] != '' && (
        <div  className={styles.containerCard}>
          <div className={styles.card} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
            <h2>
              <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
              Nossas Matrizes
            </h2>
            <div dangerouslySetInnerHTML={{ __html: regrasFormatadas[1] }} className={styles.regras} />
          </div>
        </div>
      )}

      <div className={styles.pagamento} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h2>
          <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
          Pagamento
        </h2>
        <div>
        <h4>Os Pagamentos das premiações serão lançados no SALDO de sua Conta, logo no primeiro dia útil após o sorteio.</h4>
        <p>Nossos bolões possuem alta chance de premiação e estão relacionados com estatísticas de cada loteria.</p>
        </div>
      </div>

      {/* <p className={styles.cotasText}>Cartões para Conferência de Premiações</p>
      <p className={styles.cotasText}>Total de {quantidadeApostas} jogos</p> */}


    </Modal>
  );
};

export default ModalVerBoloes;
