import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Input, Spin, Modal } from 'antd';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import { api } from '../../services/apiClient';

const Perfil = () => {
  const [usuarioData, setUsuarioData] = useState({
    nome: '',
    rg: '',
    cpf: '',
    nascimento: '',
    email: '',
    celular: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    contato: '',
  });
  const [senha, setSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get('/usuario');
        setUsuarioData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
        // toast.error('Erro ao buscar dados');
      }
    };

    fetchData();
  }, []);

  const handleChange = (field, value) => {
    setUsuarioData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    if (senha !== confirmacaoSenha) {
      toast.error('As senhas não coincidem!');
      return;
    }

    const updatedData = {
      ...usuarioData,
      password: senha || null,
      password_confirmation: confirmacaoSenha || null,
    };

    Object.keys(updatedData).forEach((key) => {
      if (!updatedData[key]) updatedData[key] = null;
    });

    try {
      const response = await api.put(`/usuario/${usuarioData.id}`, updatedData);
      toast.success('Dados atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar os dados', error.message);
      toast.error('Erro ao atualizar os dados');
    }
  };

  return (
    <div className={styles.container}>
      <h1>Perfil</h1>
      {loading ? (
        <Spin size='large' />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {/* Exemplo de alguns campos */}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Nome</label>
                <Input value={usuarioData.nome || ''} onChange={(e) => handleChange('nome', e.target.value)} className={styles.inputNome} />
              </div>
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>RG</label>
                <Input value={usuarioData.rg || ''} onChange={(e) => handleChange('rg', e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>CPF</label>
                <Input value={usuarioData.cpf || ''} onChange={(e) => handleChange('cpf', e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Data de nascimento</label>
                <Input value={usuarioData.nascimento || ''} onChange={(e) => handleChange('nascimento', e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>E-mail</label>
                <Input value={usuarioData.email || ''} onChange={(e) => handleChange('email', e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Celular</label>
                <Input value={usuarioData.celular || ''} onChange={(e) => handleChange('celular', e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>{' '}
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Senha</label>
                <Input.Password value={senha} onChange={(e) => setSenha(e.target.value)} className={styles.inputNome} />
              </div>{' '}
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Confirmação de senha</label>
                <Input.Password value={confirmacaoSenha} onChange={(e) => setConfirmacaoSenha(e.target.value)} className={styles.inputNome} />
              </div>
            </Col>
          </Row>
          <Button onClick={handleSave} className={styles.saveButton}>
            Salvar
          </Button>
        </>
      )}
    </div>
  );
};

export default Perfil;
