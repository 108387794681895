import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { MdShowChart } from 'react-icons/md';
import { FileSearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import moment from 'moment';

const SolicitacoesResgate = ({ solicitacoes, showTable, setShowTable }) => {
  console.log('solicitacoes', solicitacoes)
  console.log('solicitacoes', solicitacoes.length)
  
  const columns = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => moment(text).format('DD/MM/YY'),
    },
    {
      title: 'Método de pagamento',
      dataIndex: 'metodo_pagamento',
      key: 'metodo_pagamento',
    },
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      render: (value) =>
        `${value?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        if(value === 0 && record.recusar_pagamento === 2){
          return <p className={styles.cancelado}>Recusado</p>;

        }
        if (value === 0 && record.recusar_pagamento === 0) {
          return <p className={styles.pendente}>Pendente</p>;
        } else if(value === 1 && record.esta_pago === 1) {
          return <p className={styles.aprovado}>Aprovado</p>;
        }
      },
    },
    {
      title: 'Comprovante',
      dataIndex: 'comprovante_url',
      key: 'comprovante_url',
      render: (text) =>
        text ? (
          <Tooltip title="Ver comprovante">
            <a href={text} target='_blank' rel='noopener noreferrer'>
              <FileSearchOutlined style={{ fontSize: '20px', color: '#1890ff' }} />
            </a>
          </Tooltip>
        ) : (
          <Tooltip title="Nenhum comprovante disponível">
            <FileSearchOutlined disabled style={{ fontSize: '20px', color: 'lightgray' }} />
          </Tooltip>
        ),
    },
  ];

  return (
    <div className={styles.container}>
      <Button className={styles.divisorioSolicitacao} onClick={() => setShowTable(!showTable)}>
        <div className={styles.icon}>
          <MdShowChart />
        </div>
        <h1>Solicitações de saques realizados</h1>
      </Button>
      {showTable && (
        <Table
        dataSource={solicitacoes[0] === 'nenhuma solicitação encontrada' ? [] : solicitacoes}
        columns={columns}
          rowKey='id'
          pagination={{ pageSize: 10 }} // Configura a paginação, se necessário
          className={styles.tabelaResgate}
        />
      )}
    </div>
  );
};

export default SolicitacoesResgate;
