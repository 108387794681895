import React, { useState } from 'react';
import { Button, Col, Row, InputNumber, Modal, Rate } from 'antd';
import styles from './style.module.scss';
import { MdFavoriteBorder } from 'react-icons/md';
import usuario from '../../assets/usuario.png';
import { FaUser, FaUserAlt } from 'react-icons/fa';

const CardAvaliacao = ({ nome, tipo, estrelas, mensagem, tags }) => {
  return (
    <div className={styles.container}>
      {nome && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <div className={styles.areaApostador}>
              {/* <img src={usuario} className={styles.fotoApostados} /> */}
              <div className={styles.imageUser}>
                <FaUserAlt />
              </div>
              <h1>{nome}</h1>
              <h2>{tipo}</h2>
              <Button className={styles.button} icon={<MdFavoriteBorder />}>
                Curtir
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={18} md={18} lg={18} xl={18}>
            <div className={styles.areaTextoAvaliacao}>
              <div className={styles.estrelas}>
                <Rate allowHalf defaultValue={estrelas} />
              </div>
              <div className={styles.texto}>
                <p>{mensagem}, {mensagem} </p>
              </div>
              <div className={styles.tags}>
                <Row gutter={[16, 16]}>
                  {tags &&
                    tags.map((item) => (
                      <Col key={item.id} xs={24} sm={12} md={10} lg={10} xl={10}>
                          <h3>{item}</h3>
                        {/* <div className={styles.containerh3}>
                        </div> */}
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CardAvaliacao;
