import React, { useState } from 'react';
import { Button, Popconfirm, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import styles from './style.module.scss';

const ExcluirConta = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [confirmado, setConfirmado] = useState(false);
  const [email, setEmail] = useState('');

  const handleNegar = () => {
    navigate('/');
  };

  const handleAprovar = () => {
    setConfirmado(true);
  };

  const handleNegarConfirmado = () => {
    setConfirmado(false);
  };


  const handleAprovarConfirmado = async () => {
    try {
      const responseExcluirUsuario = await api.post('/usuario/excluir');
      console.log('responseExcluirUsuario', responseExcluirUsuario);
      logout();
      handleNegar()
    } catch (error) {
      console.log('Erro ao excluir conta', error.message);
      toast.error('Erro ao excluir conta');
    }
  };

  return (
    <div className={styles.container}>
      {confirmado ? (
        <>
          <h1>Adicione seu e-mail para excluir sua conta</h1>
          
          <div className={styles.container_input}>
          <label>Confirme seu e-mail</label>
          <Input className={styles.input}
            prefix= {<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M10.5 10C11.4946 10 12.4484 9.60491 13.1517 8.90165C13.8549 8.19839 14.25 7.24456 14.25 6.25C14.25 5.25544 13.8549 4.30161 13.1517 3.59835C12.4484 2.89509 11.4946 2.5 10.5 2.5C9.50544 2.5 8.55161 2.89509 7.84835 3.59835C7.14509 4.30161 6.75 5.25544 6.75 6.25C6.75 7.24456 7.14509 8.19839 7.84835 8.90165C8.55161 9.60491 9.50544 10 10.5 10ZM13 6.25C13 6.91304 12.7366 7.54893 12.2678 8.01777C11.7989 8.48661 11.163 8.75 10.5 8.75C9.83696 8.75 9.20107 8.48661 8.73223 8.01777C8.26339 7.54893 8 6.91304 8 6.25C8 5.58696 8.26339 4.95107 8.73223 4.48223C9.20107 4.01339 9.83696 3.75 10.5 3.75C11.163 3.75 11.7989 4.01339 12.2678 4.48223C12.7366 4.95107 13 5.58696 13 6.25ZM18 16.25C18 17.5 16.75 17.5 16.75 17.5H4.25C4.25 17.5 3 17.5 3 16.25C3 15 4.25 11.25 10.5 11.25C16.75 11.25 18 15 18 16.25ZM16.75 16.245C16.7487 15.9375 16.5575 15.0125 15.71 14.165C14.895 13.35 13.3613 12.5 10.5 12.5C7.6375 12.5 6.105 13.35 5.29 14.165C4.4425 15.0125 4.2525 15.9375 4.25 16.245H16.75Z" fill="#DEDEDE"/>
            </svg>}
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu e-mail"
          />
          </div>
          <Button className={styles.sim} onClick={handleAprovarConfirmado}>
          Confirmar exclusão de conta
            </Button>
          <Button className={styles.nao} onClick={handleNegarConfirmado}>
            Cancelar
          </Button>
        </>
      ) : (
        <>
          <h1>Deseja excluir sua conta?</h1>
          <Button className={styles.nao} onClick={handleNegar}>
            Não
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir sua conta? Esta ação é irreversível."
            onConfirm={handleAprovar}
            onCancel={handleNegar}
            okText="Sim"
            cancelText="Não"
          >
            <Button className={styles.sim}>
              Sim
            </Button>
          </Popconfirm>
        </>
      )}


    </div>
    // <div className={styles.container}>
    //   <h1>Deseja excluir sua conta?</h1>
    //   <Button className={styles.nao} onClick={handleNegar}>
    //     Não
    //   </Button>
    //   <Popconfirm
    //     title="Tem certeza que deseja excluir sua conta? Esta ação é irreversível."
    //     onConfirm={handleAprovar}
    //     onCancel={handleNegar}
    //     okText="Sim"
    //     cancelText="Não"
    //   >
    //     <Button className={styles.sim}>
    //       Sim
    //     </Button>
    //   </Popconfirm>
    // </div>
  );
};

export default ExcluirConta;
