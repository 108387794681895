import React, { useState } from 'react';
import styles from './style.module.scss';
import { Button, Col, Row, Card } from 'antd';
// import depoimentos from './depoimentos.json';
import { FaUserCircle } from 'react-icons/fa';
import Slider from 'react-slick';
import { BiSolidQuoteRight } from "react-icons/bi";

const DepoimentosHome = ({depoimentos}) => {
  const [autoplay, setAutoplay] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Aumentado para mostrar mais slides
    autoplay: autoplay,
    autoplaySpeed: 3000,
    centerPadding: '60px',
    centerMode: true,
    // cssEase: 'linear',
    // rows: 2, // Ajustado para 1 para simplificar a visualização
    // slidesPerRow: 2, // Ajustado para 1 para ter controle melhor por slide
    // centerMode: false, // Desativado para alinhamento padrão
  };

  return (
    <div className={styles.container}>
      <h1>
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='2' viewBox='0 0 20 2' fill='none'>
          <path d='M0 1H20' stroke='#9F9FA2' />
        </svg>
        Clientes realizados
      </h1>
      <h3>Quem compra recomenda</h3>
      <div className={styles.carouselWrapper}>
        <Slider {...settings} className={styles.containerCarousel}>
          {depoimentos && depoimentos.map((depoimento, index) => (
            <div key={depoimento.id} className={`${styles.depoimentoCard} ${index % 2 === 0 ? styles.evenSlide : styles.oddSlide}`}>
              <h1>{depoimento.depoimento.slice(0, 20)}...</h1>
              <h2>{depoimento.depoimento}</h2>
              <div className={styles.containerUsuarioDepoimento}>
                <FaUserCircle size={40} className={styles.userCircle} />
                <h3>{depoimento.nome}</h3>
              </div>

              <BiSolidQuoteRight className={styles.quotes} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DepoimentosHome;
