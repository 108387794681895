  // menuData.js
  import { useAuth } from './contexts/AuthContext';
  import { MdPayment, MdOutlineReviews } from 'react-icons/md';
  import { BsShopWindow } from 'react-icons/bs';
  import { FiBarChart2 } from 'react-icons/fi';
  import { IoBagAddOutline } from 'react-icons/io5';

  // Supondo que `userImage` e outras variáveis dinâmicas sejam gerenciadas aqui ou passadas de alguma forma

  export const useMenuData = () => {
    const { user } = useAuth();

    const menuData = [
      {
        key: 'saldo',
        icon: <MdPayment style={{ fontSize: '24px' }} />,
        title: 'Saldo',
        to: '/minha-conta?tab=saldo',
        isVisible: true,
        pageTitle: 'Saldo'
      },
      {
        key: 'boloes',
        icon: <BsShopWindow style={{ fontSize: '24px' }} />,
        title: 'Bolões',
        to: '/',
        isVisible: true,
        pageTitle: 'Bolões'
      },
      {
        key: 'resultados',
        icon: <FiBarChart2 style={{ fontSize: '24px' }} />,
        title: 'Resultados',
        to: '/resultados',
        isVisible: true,
        pageTitle: 'Resultados'
      },
      {
        key: 'avaliacoes',
        icon: <MdOutlineReviews style={{ fontSize: '24px' }} />,
        title: 'Avaliações',
        to: '/avaliacoes',
        isVisible: true,
        pageTitle: 'Avaliações'
      },
      {
        key: 'conheca',
        icon: <IoBagAddOutline style={{ fontSize: '24px' }} />,
        title: 'Conheça',
        to: '/conheca',
        isVisible: true,
        pageTitle: 'Conheça'
      },
    ];

    return menuData;
  };
