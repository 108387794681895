import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/logo.png';
import { Helmet } from 'react-helmet';
import { CiUser } from 'react-icons/ci';
import styles from './style.module.scss';
import { useMenuData } from '../../menuData';
import { MdOutlineLogout, MdOutlineMessage, MdOutlinePerson } from 'react-icons/md';
import { BsQuestionOctagon } from 'react-icons/bs';
import { CiLogin } from 'react-icons/ci';
import { useMediaQuery } from 'react-responsive';

const { Sider } = Layout;

const SidebarOfficial = ({ collapsed, toggleCollapsed }) => {
  const { user, logout, isAuthenticated } = useAuth();
  const menuData = useMenuData();
  const location = useLocation();

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const currentPageTitle = menuData.find((item) => item.to === location.pathname)?.pageTitle || 'Tribo da sorte';

  const handleLogout = () => {
    logout();
  };

  const handleMenuClick = () => {
    if (isMobile) {
      toggleCollapsed();
    }
  };

  const renderMenuItem = (item) => (
    <Menu.Item key={item.key} icon={item.icon} className={styles.leftAlign} onClick={handleMenuClick}>
      {item.to ? <Link to={item.to}>{item.title}</Link> : item.title}
    </Menu.Item>
  );

  const renderSubMenu = (submenu) => {
    const visibleChildren = submenu.children.filter((child) => child.isVisible);

    if (!submenu.isVisible || visibleChildren.length === 0) {
      return null;
    }

    return (
      <Menu.SubMenu key={submenu.key} icon={submenu.icon} title={submenu.title} className={styles.leftAlign}>
        {visibleChildren.map((child) => renderMenuItem(child))}
      </Menu.SubMenu>
    );
  };

  const renderMenuItems = () => {
    const filteredItems = menuData.filter((item) => item.isVisible);

    return filteredItems.flatMap((item) => {
      const menuItem = item.children ? renderSubMenu(item) : renderMenuItem(item);
      return menuItem;
    });
  };

  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  function formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  const renderUserInfo = () => {
    if (isMobile && user) {
      return (
        <div className={styles.userInfo}>
          <p className={styles.userName}>{user.nome}</p>
          <p className={styles.saldo}>Saldo: {formatCurrency(user.saldo)}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Sider width={256} collapsible reverseArrow={false} collapsed={collapsed} onCollapse={toggleCollapsed} breakpoint='md' collapsedWidth='0' theme='light' className={styles.sidebar}>
      <Helmet>
        <title>{currentPageTitle}</title>
      </Helmet>
      <div className={styles.containerLogo}>
        <Link to='/' className={styles.containerLogo}>
          <img src={logo} alt='logo tribo da sorte' />
        </Link>
      </div>

      <Menu inlineCollapsed={true} theme='light' mode='inline' defaultSelectedKeys={['dashboard']} style={{ minHeight: '60vh', overflowX: 'auto', borderRight: 0, backgroundColor: '#fff', padding: '.5rem 1.5rem' }}>
        {renderUserInfo()}

        {isMobile && !user ? (
          <div>
            <Link to='/login'>
              <Button icon={<CiLogin style={{ fontSize: '24px' }} />} type='text' className={styles.buttonAuth} onClick={handleMenuClick}>
                Entrar
              </Button>
            </Link>
            <Link to='/cadastrar'>
              <Button icon={<CiUser style={{ fontSize: '24px' }} />} type='text' className={styles.buttonAuth} onClick={handleMenuClick}>
                Cadastrar
              </Button>
            </Link>
          </div>
        ) : null}

        {user && (
          <Link to='/minha-conta?tab=padrao' className={styles.linkMinhaConta} onClick={handleMenuClick}>
            <Button icon={<MdOutlinePerson style={{ fontSize: '24px' }} />} type='text' className={styles.buttonMinhaConta}>
              Minha conta
            </Button>
          </Link>
        )}

        {renderMenuItems()}
      </Menu>
      <div className={styles.sidebarFooter}>
        <Button icon={<MdOutlineMessage style={{ fontSize: '24px' }} />} type='text' className={styles.buttonOutline} onClick={() => handleRedirect('https://sojogosonline.com.br/')}>
          Notícias
        </Button>
        <Button
          icon={<BsQuestionOctagon style={{ fontSize: '24px' }} />}
          type='text'
          className={styles.buttonOutline}
          onClick={() => handleRedirect('https://api.whatsapp.com/send?phone=5534991294718&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es.')}
        >
          Fale conosco
        </Button>

        {user ? (
          <Button
            icon={<MdOutlineLogout />}
            type='text'
            onClick={() => {
              handleLogout();
              handleMenuClick();
            }}
            className={styles.buttonAuth}
          >
            Sair da conta
          </Button>
        ) : null}

        {!isMobile && !user ? (
          <Link to='/login' style={{width: '100%'}}>
            <Button icon={<CiLogin style={{ fontSize: '24px' }} />} type='text' className={styles.buttonAuth} onClick={handleMenuClick}>
              Entrar
            </Button>
          </Link>
        ) : null}
      </div>
    </Sider>
  );
};

export default SidebarOfficial;
