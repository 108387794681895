import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { api } from '../services/apiClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const logout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('tribodasorte');
    setIsAuthenticated(false);
    window.location.href = '/';
  }, []);


  const checkAuthentication = useCallback(async () => {
    const authToken = localStorage.getItem('tribodasorte');
    if (authToken) {
      try {
        const response = await api.get('/usuario', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { saldo, token, token_type, usuario, contato, cpf, saldo_id, email, id, nascimento, nome, status, tipo } = response.data;

        setUser({
          saldo, token, token_type, usuario, contato, cpf, saldo_id, email, id, nascimento, nome, status, tipo
        });
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Error on authentication check:', error);
        logout();
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [logout]);


  const login = useCallback(async (emailUser, senha) => {
    try {
      const response = await api.post('/login', {
        user: emailUser,
        password: senha,
      });

      const { token, token_type } = response.data;

      localStorage.setItem('tribodasorte', token);

      const userResponse = await api.get('/usuario', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { saldo, usuario, contato, cpf, saldo_id, email, id, nascimento, nome, status, tipo } = userResponse.data;

      setUser({
        saldo, token, token_type, usuario, contato, cpf, saldo_id, email, id, nascimento, nome, status, tipo
      });
      setIsAuthenticated(true);

      checkAuthentication(); // Chama a verificação de autenticação

      return true;
    } catch (error) {
      console.log('Login failed:', error);
      setIsAuthenticated(false);
      return false;
    }
  }, [checkAuthentication]);


  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, checkAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
}


export function useAuth() {
  return useContext(AuthContext);
}
