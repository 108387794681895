import React, { useState } from 'react';
import styles from './style.module.scss';
import { toast } from 'react-toastify';
import { Button, Input, Steps, Checkbox } from 'antd';
import { useAuth } from '../../contexts/AuthContext';
import { CiUser } from 'react-icons/ci';
import { AiOutlineLock } from 'react-icons/ai';
import { BsArrowUpRight } from 'react-icons/bs';
import { api } from '../../services/apiClient';
import logo from '../../assets/logo.png';
import { MailLock, PhoneAndroidOutlined } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { useNavigate, Link } from 'react-router-dom';

const Cadastrar = () => {
  const { login } = useAuth();
  const [step, setStep] = useState(0);
  const [aceiteTermos, setAceiteTermos] = useState(false);
  const [aceitePolitica, setAceitePolitica] = useState(false);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [loading, setLoading] = useState(false);

  const somenteNumeros = (str) => str.replace(/\D/g, '');

  const handleFinalizarCadastro = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!aceiteTermos || !aceitePolitica) {
      toast.warn(`Por favor aceite os termos de uso e a política de privacidade`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.post('/registrar', {
        nome,
        email,
        contato: somenteNumeros(celular),
        cpf: somenteNumeros(cpfCnpj),
        rg: '',
        nascimento: somenteNumeros(dataNascimento),
        password: senha,
        password_confirmation: confirmarSenha,
        cep: '',
        cidade: '',
        endereco: '',
        numero: '',
        complemento: '',
        uf: '',
      });
      setLoading(false);

      if (response.status === 200) {
        if (response.data.errors) {
          const errorArray = Object.keys(response.data.errors).reduce((acc, key) => {
            const errorsForKey = response.data.errors[key].map((error) => ({ field: key, message: error }));
            return acc.concat(errorsForKey);
          }, []);
          errorArray.map((erro) => {
            toast.error(erro.message, { position: 'top-right' });
          });
          return;
        }
        toast.success(`Cadastro feito com sucesso!!`, { position: 'top-right' });
        window.location.href = '/';
      } else if (response.status === 201) {
        toast.success(`Cadastro feito com sucesso!!`, { position: 'top-right' });
      } else if (response.status === 500) {
        toast.success(`Cadastro feito com sucesso!!`, { position: 'top-right' });
        window.location.href = '/';
      } else {
        toast.warn(`Falha ao fazer o cadastro, confirme seus dados`, { position: 'top-right' });
      }
    } catch (error) {
      setLoading(false);
      if (error.message === 'Request failed with status code 500') {
        toast.success(`Cadastro feito com sucesso!!`, { position: 'top-right' });
        window.location.href = '/';
      } else {
        toast.error('Erro ao tentar fazer cadastro', { position: 'top-right' });
      }
    }
  };

  return (
    <div className={styles.container}>
      <img src={logo} alt='logo' />
      <div className={styles.containerInputs}>
        <Steps
          current={step}
          items={[
            {
              title: '',
            },
            {
              title: '',
            },
            {
              title: '',
            },
          ]}
        />
      </div>
      {step === 0 && (
        <>
          <div className={styles.containerInputs}>
            <label>Nome</label>
            <Input className={styles.inputs} size='large' placeholder='Insira seu nome' prefix={<CiUser />} onChange={(e) => setNome(e.target.value)} />
          </div>
          <div className={styles.containerInputs}>
            <label>E-mail</label>
            <Input className={styles.inputs} size='large' placeholder='Insira seu e-mail' prefix={<MailLock />} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className={styles.containerInputs}>
            <label>Celular</label>
            <InputMask mask='(99) 99999-9999' value={celular} onChange={(e) => setCelular(e.target.value)}>
              {(inputProps) => <Input {...inputProps} className={styles.inputs} size='large' placeholder='Insira seu celular' prefix={<PhoneAndroidOutlined />} />}
            </InputMask>
          </div>
        </>
      )}
      {step === 1 && (
        <>
          <div className={styles.containerInputs}>
            <label>CPF ou CNPJ</label>
            <InputMask mask={cpfCnpj.length <= 14 ? '999.999.999-99' : '99.999.999/9999-99'} value={cpfCnpj} onChange={(e) => setCpfCnpj(e.target.value)}>
              {(inputProps) => <Input {...inputProps} className={styles.inputs} size='large' placeholder='Insira seu cpf ou cnpj' prefix={<CiUser />} />}
            </InputMask>
          </div>
          <div className={styles.containerInputs}>
            <label>Data de nascimento</label>
            <InputMask mask='99/99/9999' value={dataNascimento} onChange={(e) => setDataNascimento(e.target.value)}>
              {(inputProps) => <Input {...inputProps} className={styles.inputs} size='large' placeholder='Insira sua data de nascimento' prefix={<AiOutlineLock />} />}
            </InputMask>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className={styles.containerInputs}>
            <label>Escolha uma senha</label>
            <Input.Password className={styles.inputs} size='large' placeholder='Digite sua senha' prefix={<AiOutlineLock />} onChange={(e) => setSenha(e.target.value)} />
          </div>
          <div className={styles.containerInputs}>
            <label>Confirme sua senha</label>
            <Input.Password className={styles.inputs} size='large' placeholder='Confirme a senha' prefix={<AiOutlineLock />} onChange={(e) => setConfirmarSenha(e.target.value)} />
          </div>
          <Checkbox onChange={(e) => setAceitePolitica(e.target.checked)}>
            Li e concordo com as&nbsp;
            <Link to='/politica-de-privacidade'>
              <strong>Políticas de Privacidade</strong>
            </Link>
          </Checkbox>
          <Checkbox onChange={(e) => setAceiteTermos(e.target.checked)}>
            Li e concordo com os &nbsp;
            <Link to='/termos-de-uso'>
              <strong>Termos de Uso</strong>
            </Link>
          </Checkbox>
        </>
      )}
      {step !== 2 && (
        <>
          <Button className={styles.buttonEntrar} icon={<BsArrowUpRight />} onClick={() => setStep(step + 1)}>
            Proximo
          </Button>
        </>
      )}
      {step === 2 && (
        <>
          <Button className={styles.buttonEntrar} icon={<BsArrowUpRight />} onClick={handleFinalizarCadastro}>
            Finalizar cadastro
          </Button>
        </>
      )}
      {step !== 0 && (
        <>
          <Button className={styles.buttonEntrar} icon={<BsArrowUpRight />} onClick={() => setStep(step - 1)}>
            Voltar
          </Button>
        </>
      )}
    </div>
  );
};

export default Cadastrar;
