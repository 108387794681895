import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Tabs, Col, Row } from 'antd';
import styles from './style.module.scss';

const Conheca = () => {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Conheça</title>
      </Helmet>
      <h1>Quem somos</h1>
      <p>
        Você já sonhou em ser um ganhador da loteria? Pois bem, você e todos os brasileiros sonham com esse dia para poder realizar os sonhos, quitar as dívidas e viver uma vida mais confortável. Por trás da Tribo da Sorte tem um especialista,
        Eldelvan. Criador e administrador do canal de dicas das loterias no YouTube, com mais de 100 mil inscritos. O único canal com dicas gratuitas que alcançou os 15 pontos da Lotofácil! Eldelvan cria bolões profissionais e inteligentes através de
        estratégias para melhorar a possibilidade de premiações. Não são bolões feitos ao acaso, e sim com método e objetivo de aumentar as chances para todos ganharem juntos. Sempre disposto a aprimorar, Eldelvan compartilha seus conhecimentos pelo
        YouTube e também disponibiliza bolões prontos aqui no site da Tribo da Sorte, para que você possa apostar no conforto da sua casa e com facilidade. Uma história de trabalho e dedicação para realizar os sonhos de milhares de pessoas, Eldelvan
        segue criando e compartilhando planilhas e matrizes para que você também aprenda a apostar. Com a felicidade de já ter conquistado inúmeras premiações e ter ajudado muitas pessoas, a maior alegria da Tribo da Sorte é continuar fazendo um bom
        trabalho e, juntos, realizar o sonho de vencer na loteria! Nossa missão é proporcionar oportunidades reais de premiações nas loterias, sempre garantido jogos estrategicamente elaborados para mudar a vida das pessoas e realizar seus sonhos! A
        nossa visão é ser referência como o melhor site para bolões, promovendo soluções e com ótimas garantias. Prezamos pelos valores da responsabilidade e valorização dos apostadores(as), com compromisso, ética, seriedade, transparência,
        excelência, qualidade, idoneidade e muito trabalho!
      </p>

      <h1>Missão</h1>
      <p>
        Proporcionar reais oportunidades de ganhos nas loterias brasileiras, com a garantia de sempre ter ótimos jogos e estrategicamente elaborados, visando grandes premiações que possa mudar as vidas das pessoas, realizando seus sonhos e objetivos
        familiares e profissionais.
      </p>
      <h1>Visão</h1>
      <p>Ser referência como melhor site de bolões, com grandes e inúmeras premiações e promovendo soluções para as principais necessidades dos apostadores (as), com ótimas garantias incontestáveis.</p>

      <h1>Valores</h1>
      <p>Responsabilidade e valorização dos apostadores (as);Compromisso, Trabalho e Seriedade;Transparência, ética e simplicidade;Idoneidade;Excelência e Qualidade.</p>

      <h1>Perguntas Frequentes</h1>
      <h2>O que é um bolão?</h2>
      <p>
        Bolão é um investimento realizado por um conjunto de pessoas, ou seja, vários apostadores se juntam para adquirir uma série de jogos (apostas) , aumentando assim a probabilidade de acertos, e com posterior divisão dos prêmios. Em nosso caso
        especificamente realizamos os bolões baseados nos jogos oficiais das Loterias da Caixa Econômica Federal. O bolão é o caminho mais curto para melhores resultados nas loterias e com menor desembolso de valores.
      </p>

      <h2>Qual a garantia de receber o prêmio?</h2>
      <p>
        Sempre que confirmar sua escolha por um de nossos bolões, você receberá um e-mail confirmando a sua participação. A lista dos jogos e todo seu histórico de participação estará em MINHA CONTA. Os jogos registrados ficam de posse da empresa que
        é responsável pela sua guarda e recebimento da premiação. Informações da premiação e ou resultado do sorteio, estarão disponíveis em MINHA CONTA. Sua premiação será convertida em créditos para bolões futuros, caso não solicite o saque.
      </p>

      <h2>O que é um bolão?</h2>
      <p>
        Bolão é um investimento realizado por um conjunto de pessoas, ou seja, vários apostadores se juntam para adquirir uma série de jogos (apostas) , aumentando assim a probabilidade de acertos, e com posterior divisão dos prêmios. Em nosso caso
        especificamente realizamos os bolões baseados nos jogos oficiais das Loterias da Caixa Econômica Federal. O bolão é o caminho mais curto para melhores resultados nas loterias e com menor desembolso de valores. Atenção: Para sua segurança não
        realizamos transferências e ou depósitos em contas de terceiros.
      </p>

      <h2>Como resgatar minha premiação?</h2>
      <p>
        Em “MINHA CONTA”, acesse a opção SOLICITAR SAQUE e preencha os campos solicitados. Será cobrada taxa de saque com valor informado no momento do pedido de resgate. O site te demonstrará o valor a ser resgatado já deduzido a taxa de resgate.
      </p>

      <h2>Se eu não tiver conta bancário ou carteira digital, como recebo meu prêmio?</h2>
      <p>
        Poderá ser solicitado por Ordem de Pagamento, nesse caso haverá dedução das cobranças de taxa pelo banco ou instituição transferidora e ou recebedora. As despesas com transferências bancárias, impostos ou outras porventura devidas em
        decorrência do pagamento de prêmio aos vencedores, serão deduzidas do pagamento da premiação.
      </p>

      <h2>Posso transferir créditos para outra pessoa?</h2>
      <p>Os valores de seus créditos/saldo são intransferíveis.</p>

      <h2>O que acontece se sobrarem cotas de um bolão? (COTAS NÃO VENDIDAS)</h2>
      <p>
        A princípio o bolão será registrado após ter todas suas cotas vendidas. Caso as cotas não sejam todas vendidas, você terá sua participação garantida para o concurso sequente à quitação de todas as cotas. Outra opção é a empresa absorver as
        cotas não vendidas. Nesse caso a divisão se manterá exatamente como na sua escolha de cotas. Sendo o bolão premiado, o valor total do prêmio será dividido pelo número total de cotas e cada participante receberá sua parte de acordo com quantas
        cotas adquiridas.
      </p>
      <h2>Como recuperar minha senha?</h2>
      <p>
        Clique no botão [ENTRAR]. Logo abaixo dos campos [Login] e [Senha] clique em [ESQUECEU SUA SENHA?]. Preencha o campo com o e-mail que você se cadastrou no site e depois clique em [Recuperar senha]. Uma mensagem contendo sua senha chegará por
        e-mail. Você pode alterar sua senha a qualquer momento. A senha deve conter de 8 até 14 caracteres (misturando letras maiúsculas, minúsculas, número e caracteres especiais). Todas as senhas são criptografadas para maior segurança. Guarde sua
        senha com cuidado e não a revele a terceiros.
      </p>
    </div>
  );
};

export default Conheca;
