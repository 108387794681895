import React, { useEffect, useState, memo, useCallback } from 'react';
import { Spin, Divider } from 'antd';
import styles from './style.module.scss';

const VerApostasBolao = ({ apostas = [], isModalVisible, closeModal, color, bolaoName }) => {
  const [localApostas, setLocalApostas] = useState([]);

  useEffect(() => {
    // Apenas atualiza o estado se as condições são realmente necessárias
    if (isModalVisible && apostas.length > 0 && apostas[0].nome === bolaoName) {
      setLocalApostas(apostas);
    } else if (!isModalVisible) {
      // Resetar o estado quando o modal é fechado
      setLocalApostas([]);
    }
    // Evitar adicionar `apostas` diretamente na lista de dependências, se for complexo
  }, [isModalVisible, bolaoName, apostas]);

  const hexToRGBA = useCallback((hex, opacity) => {
    if (!hex) return '';
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }, []);

  const ApostaItem = memo(({ numero, color }) => {
    const isMonth = isNaN(numero);

    return (
      <div className={styles.apostaItem}>
        <div style={{ background: hexToRGBA(color, 0.1), borderColor: color }} className={`${styles.apostasNumero} ${isMonth ? styles.month : styles.number}`}>
          <h2 style={{ color: color }}>{numero}</h2>
        </div>
      </div>
    );
  });

  const renderAposta = useCallback(
    (aposta, index) => {
      if (!aposta) return null;
      return (
        <div key={index} className={styles.jogos}>
          <div className={styles.jogosArea}>
            {aposta.map((linha, idx) => (
              <div className={styles.jogosValores} key={`${index}-${idx}`} style={{ background: hexToRGBA(color, 0.1), borderColor: color }}>
                <h4 className={styles.titleJogos} style={{ background: color, color: '#fff', padding: '8px' }}>
                  {(idx + 1).toString().padStart(3, '0')}
                </h4>
                <Divider type='vertical' className={styles.dividervertical} style={{ borderColor: color, height: '8rem', width: '1px' }} />
                <Divider type='horizontal' className={styles.dividerhorizontal} style={{ borderColor: color, height: '2px', width: '1px' }} />
                <div className={styles.row}>
                  {linha.numeros.map((item) => (
                    <div key={item} className={styles.col}>
                      <ApostaItem numero={item} color={color} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    },
    [color, hexToRGBA]
  );

  return (
    <div>
      {isModalVisible && (
        <div className={styles.customModal} style={{ zIndex: 99999 }}>
          <div className={styles.modalOverlay} onClick={closeModal}></div>
          <div className={styles.modalContent}>
            <h1 className={styles.header} style={{ background: color }}>
              JOGOS
            </h1>
            <div className={styles.container}>{localApostas.length === 0 ? <Spin /> : <div className={styles.list}>{localApostas.map((aposta, index) => renderAposta(aposta.apostas, index))}</div>}</div>
            <button onClick={closeModal} className={styles.closeButton}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(VerApostasBolao);
