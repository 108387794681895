import axios from 'axios';
import { parseCookies, destroyCookie } from 'nookies';
import { AuthTokenError } from './errors/AuthTokenErrors';

// const url = 'https://api-sbx.tribodasorteonline.com.br/api';
const url = 'https://api.tribodasorteonline.com.br/api';
// const url = 'http://127.0.0.1:8000/api'
// const url = 'http://localhost:5000'
export function setupAPIClient(ctx = undefined) {
  const api = axios.create({
    baseURL: url,
  });

  // Adiciona um interceptor de requisição para injetar o token de autenticação
  api.interceptors.request.use(config => {
    const authToken = localStorage.getItem('tribodasorte'); // Obtem o token de autenticação atualizado
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  });

  // Configuração do interceptor de resposta continua a mesma
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        if (typeof window !== 'undefined') {
          try {
            // Você mencionou que queria importar a função de logout, ela deve ser descomentada e usada aqui
            // const { logout } = require('../contexts/AuthContext');
            // await logout();
          } catch (e) {
            console.error('Erro ao fazer logout:', e);
          }
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );

  return api;
}

export function destroyAPIClient() {
  destroyCookie(undefined, '@crmtessa.token'); // Substitua '@crmtessa.token' pelo nome correto do seu cookie, se necessário
}
