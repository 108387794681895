import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Tabs, Col, Row, Button, Spin } from 'antd';
import styles from './style.module.scss';
import { BsPersonFill, BsTrophyFill } from 'react-icons/bs';
import { MdAttachMoney, MdOutlineTrendingUp } from 'react-icons/md';
import { useSaldo } from '../../contexts/SaldoContext';

const CardsValoresMinhaConta = ({ valorPremiacoes, totalApostas, setShowTablePremios, setShowTableBoloes, setShowResgatarPremios }) => {
  const { saldo, loading } = useSaldo();
  const premiosRef = useRef(null);

  function formatCurrency(value) {
    if (!valorPremiacoes) return 0;
    return Number(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  const formatarComoMoeda = (valor) => {
    if (valor == null) return '';
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const handleShowTablePremio = () => {
    setShowTableBoloes(false);
    setShowTablePremios(true);
    setShowResgatarPremios(false)
    if (premiosRef.current) {
      premiosRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className={styles.container}>
      <Row gutter={[12, 12]} justify='center'>
        <Col xs={20} sm={12} md={8} lg={8} xl={8} styles={{ padding: '0 !important' }}>
          <div className={styles.cardValores}>
            <div className={styles.icon}>
              <BsTrophyFill />
            </div>
            <p>Resumo de premiações</p>
            <h1>{valorPremiacoes || valorPremiacoes === 0 ? formatCurrency(valorPremiacoes) : <Spin />}</h1>
            <Button onClick={handleShowTablePremio}>Ver Prêmios</Button>
          </div>
        </Col>
        <Col xs={20} sm={12} md={8} lg={8} xl={8}>
          <div className={styles.cardValores}>
            <div className={styles.icon}>
              <MdAttachMoney />
            </div>
            <p>Saldo disponível</p>
            <h1>{loading ? <Spin /> : formatarComoMoeda(saldo)}</h1>
            <Button onClick={() => setShowResgatarPremios(true)}>Resgatar Prêmio</Button>
          </div>
        </Col>
        <Col xs={20} sm={12} md={8} lg={8} xl={8}>
          <div className={styles.cardValores}>
            <div className={styles.icon}>
              <BsPersonFill />
            </div>
            <p>Suas apostas</p>
            <h2>{totalApostas || totalApostas === 0 ? totalApostas : <Spin />}</h2>
          </div>
        </Col>
      </Row>
      <div ref={premiosRef}></div>
    </div>
  );
};

export default CardsValoresMinhaConta;
