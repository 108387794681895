import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import CardAvaliacao from '../../Components/CardAvaliacao';
import { api } from '../../services/apiClient';

const Avaliacao = () => {
  const [depoimentos, setDepoimentos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [depoimentosRes] = await Promise.all([api.get('/list_depoimentos')]);

        setDepoimentos(depoimentosRes.data);
      } catch (error) {
        console.error('Erro ao buscar dados', error);
      }
    };

    fetchData();
  }, []);

  // Função para gerar tags fictícias baseadas na avaliação
  const generateTags = (avaliacao, depoimento) => {
    let tags = [];
    if (avaliacao >= 4) {
      tags.push('Excelente', 'Recomendado', 'Top Avaliado');
    } else if (avaliacao >= 2) {
      tags.push('Bom', 'Satisfatório');
    } else {
      tags.push('Pode Melhorar');
    }


    return tags;
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Avaliações</title>
      </Helmet>
      <h1>Avaliações sobre nossa plataforma</h1>
      {depoimentos.map((depoimento, index) => (
        <CardAvaliacao
          key={depoimento.key}
          nome={depoimento.nome}
          tipo={depoimento.avaliacao >= 4 ? "premium" : "standard"}
          estrelas={depoimento.avaliacao}
          mensagem={depoimento.depoimento}
          tags={generateTags(depoimento.avaliacao)} // Adicionando tags dinamicamente
        />
      ))}
    </div>
  );
};

export default Avaliacao;
