import React, { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../services/apiClient'; // Importa sua API client
import { toast } from 'react-toastify';
import { AuthProvider, useAuth } from './AuthContext';

export const SaldoContext = createContext();

export const useSaldo = () => useContext(SaldoContext);

export const SaldoProvider = ({ children }) => {
  const [saldo, setSaldo] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, user } = useAuth();

  const fetchSaldo = async () => {
    if (user) {
      try {
        setLoading(true);
        const response = await api.get('/saldo');
        setSaldo(response.data.saldo);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao buscar saldo');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSaldo(); // Busca o saldo ao montar o componente

    const interval = setInterval(() => {
      fetchSaldo();
    }, 60000); // Atualiza o saldo a cada 60 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);
  useEffect(() => {
    fetchSaldo(); // Limpa o intervalo quando o componente for desmontado
  }, [user]);

  const value = {
    saldo,
    fetchSaldo,
    loading,
  };

  return <SaldoContext.Provider value={value}>{children}</SaldoContext.Provider>;
};
