// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__n5iDU {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.style_container__n5iDU > h1 {
  color: #575755;
  text-align: center;
  /* H1/Bold */
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
  > h1 {
    font-size: 1.5rem; /* Reduzindo o tamanho da fonte para telas menores */
    margin-top: 1rem; /* Reduzindo a margem superior */
    margin-bottom: 1rem; /* Reduzindo a margem inferior */
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/MinhaConta/style.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EAcA,aAAA;EACA,sBAAA;EACA,SAAA;AAdF;AADE;EACE,cAAA;EACA,kBAAA;EAEA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AAOA;EACE;IACE,iBAAA,EAAA,oDAAA;IACA,gBAAA,EAAA,gCAAA;IACA,mBAAA,EAAA,gCAAA;EAJF;AACF","sourcesContent":["// @import '../../index.scss'; // Importe o arquivo de variáveis globais\n\n.container {\n  max-width: 1024px;\n  > h1 {\n    color: #575755;\n    text-align: center;\n\n    /* H1/Bold */\n    font-family: Inter;\n    font-size: 2rem;\n    font-style: normal;\n    font-weight: 700;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n  }\n\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n}\n\n@media screen and (max-width: 1024px) {\n  > h1 {\n    font-size: 1.5rem; /* Reduzindo o tamanho da fonte para telas menores */\n    margin-top: 1rem; /* Reduzindo a margem superior */\n    margin-bottom: 1rem; /* Reduzindo a margem inferior */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__n5iDU`
};
export default ___CSS_LOADER_EXPORT___;
