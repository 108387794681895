import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider';
import styles from './style.module.scss';
import { Button } from 'antd';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { PiTimerFill } from 'react-icons/pi';
import { useNavigate, Link } from 'react-router-dom'; 
import { MdShoppingCart } from 'react-icons/md';

const DropDownCarrinho = ({ onResetStep, setVisible }) => {
  const { items, removeFromCart, removeItemFromCart, addToCart } = useCart();
  const navigate = useNavigate(); // Obtenção do objeto navigate para navegação

  const handleRemoveItem = (itemId) => {
    const itemToRemove = items.find((item) => item.id === itemId);
    if (itemToRemove) {
      if (itemToRemove.quantidade > 1) {
        removeItemFromCart(itemId, 1);
      } else {
        removeFromCart(itemId);
      }
    }
  };

  const handleAddItem = (item) => {
    addToCart({ ...item, quantidade: 1 });
  };

  const navigateToCart = () => {
    onResetStep();
    setVisible(false);
    navigate('/carrinho');
  };

  return (
    <div className={styles.container}>
      {items.length > 0 &&
        items.map((item) => (
          <div key={item.addedAt}>
            <h1 className={styles.titleCarrinho}>Meu carrinho</h1>
            <div key={item.id} className={styles.containerCadaBolao}>
              <div className={styles.containerItems}>
                <div className={styles.grupo}>
                  <h1>{item.grupo}</h1>
                  <h2>{item.nome}</h2>
                </div>

                <div className={styles.quantidade}>
                  <Button className={styles.buttonMinusPlus} onClick={() => handleRemoveItem(item.id)}>
                    <FiMinus />
                  </Button>
                  <h1>{item.quantidade}</h1>
                  <Button className={styles.buttonMinusPlus} onClick={() => handleAddItem(item)}>
                    <FiPlus />
                  </Button>
                </div>
                <div className={styles.remover}>
                  <BolaoCounter addedAt={item.addedAt} />
                </div>
              </div>
            </div>
            <button onClick={navigateToCart} className={styles.containerConfirmarCompra}>
              Confirmar Compra
            </button>
          </div>
        ))}
      {items.length === 0 && (
        <div className={styles.containerVazio}>
          <h2>O seu carrinho está vazio.</h2>
          <p>Deseja olhar os bolões?</p>
          <Link to='/'>
            <Button icon={<MdShoppingCart style={{ fontSize: '30px' }} />} className={styles.buttonBuyCarrinho}>
              Continuar Comprando
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

const BolaoCounter = ({ addedAt }) => {
  const countdownDuration = 15 * 60 * 1000; // 15 minutos em milissegundos
  const [timeLeft, setTimeLeft] = useState(() => {
    const now = Date.now();
    const elapsed = now - addedAt;
    return countdownDuration - elapsed;
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1000), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  return (
    <div className={styles.containerTimer}>
      <h1>
        <PiTimerFill />
        {Math.max(0, Math.floor(timeLeft / 60000))}:{String(Math.max(0, ((timeLeft % 60000) / 1000).toFixed(0))).padStart(2, '0')}
      </h1>
    </div>
  );
};

export default DropDownCarrinho;
