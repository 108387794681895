import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Input, Spin, Modal, Select } from 'antd';
import { MdAttachMoney, MdOutlineCall, MdShoppingBag } from 'react-icons/md';
import { BsPerson, BsTrophyFill } from 'react-icons/bs';
import styles from './style.module.scss';
import moment from 'moment';
import { FaTrophy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import { PixOutlined } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const ResgatarPremios = () => {
  const { user } = useAuth();

  const [usuarioSaldoData, setUsuarioData] = useState([]);
  const [partePreencherDados, setPartePreencherDados] = useState(false);
  const [valorResgatar, setValorResgatar] = useState(0);
  const [email, setEmail] = useState();
  const [celular, setCelular] = useState();

  const [tipoPix, setTipoPix] = useState();
  const [chavePix, setChavePix] = useState();
  const [loading, setLoading] = useState();
  const tiposChavePix = [
    { key: 'cpf', text: 'CPF' },
    { key: 'cnpj', text: 'CNPJ' },
    { key: 'email', text: 'E-mail' },
    { key: 'telefone', text: 'Telefone' },
    { key: 'aleatoria', text: 'Chave Aleatória' },
  ];
  const fetchData = async () => {
    try {
      // setLoading(true);
      const usuarioSaldo = await api.get('/saldo');
      setUsuarioData(usuarioSaldo.data.saldo);

      // setLoading(false);
    } catch (error) {
      console.log('error', error.message);
      toast.error('Erro ao buscar dados');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function formatarComoMoeda(valor) {
    if (!valor) return;
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  const handleSaque = async () => {
    try {
      setLoading(true);
      if(valorResgatar < 3.5){
        toast.error(`Solicitação não pode ser menor que R$3.50!!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return
      }
      if (!valorResgatar || !celular || !chavePix || !email) {
        toast.error(`Preencha todos os dados`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        setLoading(false);
        return;
      }
      
      const dataSolicitacaoSaque = {
        valor: valorResgatar,
        contato: celular,
        metodo_pagamento: 'Pix',
        banco: 'Banco do Brasil',
        tipo_conta: 'Conta Poupança',
        titular: user.nome,
        cpf_cnpj: user.cpf,
        agencia: '',
        conta: '',
        chave_pix: chavePix,
        email: email,
      };
  
      const solicitouSaque = await api.post('/solicitacoes_saques', dataSolicitacaoSaque);
      toast.success(`Solicitação feita com sucesso!!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setLoading(false);
      window.location.reload(); // Adicione esta linha para recarregar a página
    } catch (e) {
      setLoading(false);
      console.log('e', e.message);
    }
  };
  
  const handleValorResgatarChange = (e) => {
    const valorInput = parseFloat(e.target.value.replace(',', '.'));
    const valorMaximo = usuarioSaldoData - 3.5;
    
    // Verificar se o valor é maior que o máximo permitido
    if (valorInput > valorMaximo) {
      setValorResgatar(valorMaximo);
      toast.error(`O valor máximo permitido para resgate é ${formatarComoMoeda(valorMaximo)}.`);
    } else {
      setValorResgatar(valorInput);
    }
  };
  

  return (
    <div className={styles.container}>
      {!partePreencherDados ? (
        <>
          <h1>Esse é o valor que você tem em prêmios:</h1>
          <h2>{usuarioSaldoData > 0 ? formatarComoMoeda(usuarioSaldoData) : 'Você não tem saldo suficiente para resgatar'}</h2>
          <Button disabled={usuarioSaldoData <= 0} className={styles.resgatarSaldos} onClick={() => setPartePreencherDados(true)}>
            Resgatar prêmios
          </Button>
        </>
      ) : (
        <>
          <h1>Esse é o valor que você tem em prêmios:</h1>

          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={styles.containerInput}>
                <label>Qual valor deseja resgatar?</label>
                <Input
                  prefix={<MdAttachMoney />}
                  value={valorResgatar}
                  onChange={handleValorResgatarChange}
                  className={styles.inputNome}
                  type='number' // Assegura que apenas números possam ser digitados
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Seu E-mail</label>
                <Input type='email' prefix={<BsPerson />} value={email} onChange={(e) => setEmail(e.target.value)} className={styles.inputNome} />
              </div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className={styles.containerInput}>
                <label>Celular</label>
                <InputMask mask='(99) 99999-9999' value={celular} onChange={(e) => setCelular(e.target.value)} className={styles.inputNome}>
                  {(inputProps) => <Input {...inputProps} prefix={<MdOutlineCall />} />}
                </InputMask>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={styles.containerInputRedondo}>
                <label>Metodo de pagamento</label>
                <Input style={{ textAlign: 'center' }} value='Pix' className={styles.inputNome} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={styles.containerInputRedondo}>
                <label>Qual tipo é sua chave pix?</label>
                <Select defaultValue={tipoPix} onChange={(value) => setTipoPix(value)} className={styles.inputSelect}>
                  {tiposChavePix.map((tipo) => (
                    <Option key={tipo.key} value={tipo.key}>
                      {tipo.text}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className={styles.containerInput}>
                <label>Qual é sua chave Pix?</label>
                <Input prefix={<PixOutlined />} value={chavePix} onChange={(e) => setChavePix(e.target.value)} className={styles.inputNome} />
              </div>
            </Col>
          </Row>
          <Button loading={loading} className={styles.resgatarSaldos} onClick={handleSaque}>
            Realizar Resgate
          </Button>
        </>
      )}
    </div>
  );
};

export default ResgatarPremios;
