import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import BoloesHome from '../../Components/BoloesHome';
import DepoimentosHome from '../../Components/DepoimentosHome';
import { api } from '../../services/apiClient';
import Carrousel from '../../Components/Carrousel';

const Home = ({ deviceType }) => {
  const [boloes, setBoloes] = useState([]);
  const [banners, setBanners] = useState([]);
  const [depoimentos, setDepoimentos] = useState([]);

  useEffect(() => {
    const fetchBoloes = async () => {
      try {
        const [jogosRes, jogosEspecialRes] = await Promise.all([api.get('/jogos_get'), api.get('/jogos_especial_get')]);

        // Adiciona a propriedade 'especial' aos jogos especiais
        const jogosEspecial = jogosEspecialRes.data.map((bolao) => ({ ...bolao, especial: 1 }));

        // Combina os dois arrays de bolões
        const combinedBoloes = [...jogosRes.data, ...jogosEspecial];

        // Cria um mapa para garantir a unicidade e priorizar bolões especiais
        const bolaoMap = new Map();
        combinedBoloes.forEach((bolao) => {
          if (!bolaoMap.has(bolao.id) || bolao.especial) {
            bolaoMap.set(bolao.id, bolao);
          }
        });

        // Converte o mapa de volta para um array
        const uniqueBoloes = Array.from(bolaoMap.values());

        setBoloes(uniqueBoloes);
      } catch (error) {
        console.log('Erro ao buscar bolões', error.message);
      }
    };

    const fetchBanners = async () => {
      try {
        const bannersRes = await api.get(`/banners_ismobile/${deviceType === 'desktop' ? '0' : '1'}`);
        setBanners(bannersRes.data);
      } catch (error) {
        console.log('Erro ao buscar banners', error.message);
      }
    };

    const fetchDepoimentos = async () => {
      try {
        const depoimentosRes = await api.get('/list_depoimentos');
        setDepoimentos(depoimentosRes.data);
      } catch (error) {
        console.log('Erro ao buscar depoimentos', error.message);
      }
    };

    fetchBoloes();
    fetchBanners();
    fetchDepoimentos();
  }, [deviceType]);

  return (
    <div className={styles.container}>
      <Carrousel images={banners.banners} />
      <BoloesHome boloes={boloes} />
      <DepoimentosHome depoimentos={depoimentos} />
    </div>
  );
};

export default Home;
