import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from '../../contexts/CartProvider';
import styles from './style.module.scss';
import { Button } from 'antd';
import { MdShoppingCart } from 'react-icons/md';
import StepCarrinho from '../../Components/StepCarrinho';
import { Link } from 'react-router-dom';
import LabelStepsCarrinho from '../../Components/LabelStepsCarrinho';
import StepMinhConta from '../../Components/StepMinhConta';
import { AuthProvider, useAuth } from '../../contexts/AuthContext';
import StepPagamento from '../../Components/StepPagamento';
import CompraConcluida from '../../Components/CompraConcluida';

const Carrinho = ({ onResetStep }) => {
  const { items, addToCart, removeFromCart, removeItemFromCart, clearCoupon } = useCart();
  const [step, setStep] = useState(0);
  const [idPagamento, setIdPagamento] = useState(0);
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    clearCoupon();

    // Obtendo o parâmetro 'step' da URL
    const params = new URLSearchParams(location.search);
    const stepParam = params.get('step');
    if (stepParam === 'pagamento') {
      setStep(2);
    } else {
      setStep(0);
    }
  }, [location.search]);

  const handleAddItem = (item) => {
    addToCart({ ...item, quantidade: 1 });
  };

  const handleRemoveItem = (itemId) => {
    const itemToRemove = items.find((item) => item.id === itemId);
    if (itemToRemove) {
      if (itemToRemove.quantidade > 1) {
        removeItemFromCart(itemId, 1);
      } else {
        removeFromCart(itemId);
      }
    }
  };

  const handleRemoveItemComplete = (itemId) => {
    removeFromCart(itemId);
  };

  const handleNextStep = (idPagamentoConcluido) => {
    const hasSaldoItem = items.some(item => item.nome === 'Saldo');
    const valorSaldo = items.find(item => item.nome === 'Saldo');
    const saldoValue = valorSaldo?.valor;
    console.log('step', step);

    if (hasSaldoItem) {
      navigate(`/saldo?saldoInserir=${saldoValue}`);
    } else {
      if (step === 0 && user) {
        const currentUrl = new URL(window.location);
        currentUrl.searchParams.set('step', 'pagamento');
        window.history.replaceState({}, '', currentUrl);
        setStep(2);
        return;
      }

      const params = new URLSearchParams(location.search);
      const stepParam = params.get('step');
      if (stepParam !== 'pagamento' && step === 2) {
        setStep(0);
        return;
      }

      const nextStep = step + 1;
      if (idPagamentoConcluido && nextStep === 3) {
        setIdPagamento(idPagamentoConcluido);
      }
      setStep(nextStep);
    }
  };

  const resetStep = () => {
    setStep(0);
    const currentUrl = new URL(window.location);
    currentUrl.searchParams.delete('step');
    window.history.replaceState({}, '', currentUrl);
  };

  useEffect(() => {
    if (onResetStep) {
      onResetStep(resetStep);
    }
  }, [onResetStep]);

  const onChangeStep = (value) => {
    console.log('\n\nvalue', value);
    setStep(value);
  };

  return (
    <div className={styles.container}>
      {items.length > 0 ? (
        <div className={styles.containerCarrinho}>
          <LabelStepsCarrinho onChangeStep={onChangeStep} step={step} labelConta={!user} />
          {step === 0 ? (
            <StepCarrinho items={items} handleNextStep={() => handleNextStep()} handleAddItem={handleAddItem} handleRemoveItemComplete={handleRemoveItemComplete} handleRemoveItem={handleRemoveItem} />
          ) : step === 1 ? (
            <StepMinhConta items={items} handleNextStep={() => handleNextStep()} />
          ) : step === 2 ? (
            <StepPagamento items={items} handleNextStep={(idPagamento) => handleNextStep(idPagamento)} />
          ) : (
            <CompraConcluida items={items} idPagamento={idPagamento} />
          )}
        </div>
      ) : (
        <div className={styles.containerVazio}>
          <h2>O seu carrinho está vazio.</h2>
          <p>Deseja olhar os bolões?</p>
          <Link to='/'>
            <Button icon={<MdShoppingCart style={{ fontSize: '30px' }} />} className={styles.buttonBuyCarrinho}>
              Continuar Comprando
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Carrinho;
