import React from 'react';
import { createRoot } from 'react-dom/client'; // Importando a nova API
import './index.scss';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './Components/ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';

// Guardar o console.error original
const originalConsoleError = console.error;

// Substituir console.error para ignorar mensagens de erro de ResizeObserver
console.error = (...args) => {
  const firstArgument = args.length > 0 ? args[0] : null;
  console.log('firstArgument', firstArgument);
  if (typeof firstArgument === 'string' && firstArgument.includes('ResizeObserver loop completed with undelivered notifications')) {
    // Ignorar especificamente o erro de ResizeObserver
    return;
  }
  originalConsoleError.apply(console, args);
};

function RootComponent() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

const container = document.getElementById('root');
const root = createRoot(container); // Usando a nova API

root.render(<RootComponent />);
