import React, { useState, useEffect } from 'react';
import { useCart } from '../../contexts/CartProvider'; // Atualize com o caminho correto para o seu CartContext
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import styles from './style.module.scss';
import { Button, Divider, QRCode, Input, Checkbox } from 'antd';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { FaPix } from 'react-icons/fa6';
import { api } from '../../services/apiClient';
import { toast } from 'react-toastify';

import logo from '../../assets/logo.png';
import CreditCard from '../CartaoGiratorio';
import logoSvg from '../../assets/tribodasorte 2.svg';
import { useSaldo } from '../../contexts/SaldoContext';

const Pix = ({ items, handleNextStep, couponCode, setCouponCode, validarCupon, handleRemoveItemComplete }) => {
  const [urlPix, setUrlPix] = useState('');
  const [pixId, setPixId] = useState();
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const { clearCart, coupon } = useCart();
  const navigate = useNavigate(); // Cria uma instância do navigate

  const { fetchSaldo } = useSaldo();

  const [isSaldoRoute, setIsSaldoRoute] = useState(false);

  useEffect(() => {
    // Verifica se a URL contém '/saldo'
    setIsSaldoRoute(window.location.href.includes('/saldo'));
  }, []);

  const [paymentStatus, setPaymentStatus] = useState('Aguardando pagamento');
  const [counter, setCounter] = useState(30); // Estado para o contador

  function formatCurrency(value, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  // Função para calcular os totais por grupo
  const totals = items.reduce((acc, item) => {
    if (!acc[item.nome]) acc[item.nome] = 0;
    acc[item.nome] += item.quantidade * item.valor;
    return acc;
  }, {});

  // Cálculo inicial do total geral
  const initialGrandTotal = Object.values(totals).reduce((acc, value) => acc + value, 0);
  const totalQuantidade = items.reduce((acc, item) => acc + item.quantidade, 0);

  // Verifica se o cupom é válido e aplica o desconto ao total geral
  let grandTotal = initialGrandTotal;
  if (coupon && coupon.isValid) {
    coupon.is_porcentagem === 1 ? (grandTotal = initialGrandTotal - initialGrandTotal * (coupon.discount / 100)) : (grandTotal = initialGrandTotal - coupon.discount);
  }

  const desconto = initialGrandTotal * (coupon.discount / 100);

  const handlePayCard = async () => {
    setLoading(true);
    if (!aceiteTermos) {
      toast.warn(`Por favor aceite os termos de uso e a política de privacidade`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setLoading(false);
      return;
    }
    try {
      console.log('coupon', coupon['id']);
      if (isSaldoRoute) {
        const responsePayment = await api.post('/carrega_saldo_pix', {
          total: grandTotal,
        });

        if (responsePayment.data.id && responsePayment.data.init_point) {
          setUrlPix(responsePayment.data.init_point);
          setPixId(responsePayment.data.id);
        }
        fetchSaldo();
        setLoading(false);
      } else {
        const dataQrCode = {
          cupom_desconto_id: coupon['id'],
          boloes: items,
          quantity: totalQuantidade,
          total: grandTotal,
        };
        const responsePayment = await api.post('/comprar_qr_code', dataQrCode);

        if (responsePayment.data.id && responsePayment.data.init_point) {
          fetchSaldo();

          setUrlPix(responsePayment.data.init_point);
          setPixId(responsePayment.data.id);
        }
        setLoading(false);
        setCounter(30); // Reinicia o contador ao gerar o QR Code
      }
    } catch (e) {
      setLoading(false);
      console.log('e', e.message);
    }
  };

  const handleCopyPixCode = async () => {
    try {
      await navigator.clipboard.writeText(urlPix);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000); // Reset the copied state after 5 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (pixId) {
        // Verifica se pixId está definido
        checkPaymentStatus();
      }
    }, 10000); // Executa a cada 10 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [pixId]); // Adiciona pixId como dependência

  useEffect(() => {
    if (urlPix !== '') {
      const timer = setInterval(() => {
        setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 30));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [urlPix]);

  const checkPaymentStatus = async () => {
    try {
      const response = await api.post('/verificar_pix', { id_pix: pixId });
      if (response.data.ok === true) {
        clearCart();
        window.scrollTo(0, 0);

        fetchSaldo();
        setPaymentStatus('Pago com sucesso');
        navigate(`/compra_concluida?idPagamento=${pixId}`); // Redireciona para a página de compra concluída com o ID do pagamento

        toast.success(`Pagamento efetuado com sucesso`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (error) {
      console.log('Error checking payment status:', error.message);
    }
  };

  const [cupon, setCupom] = useState('');
  const [cupomAplicado, setCupomAplicado] = useState(false);

  const handleCupon = async () => {
    const validar = await validarCupon(true);
    console.log('validar', validar);
    if (validar) {
      setCupomAplicado(true);
      return;
    }
  };

  const [aceiteTermos, setAceiteTermos] = useState(false);

  return (
    <>
      <br />
      <div className={styles.container}>
        {!urlPix == '' ||
          (!isSaldoRoute && (
            <div className={styles.containerCupom}>
              <h1>Você tem algum cupom de desconto?</h1>
              <div>
                <Input className={styles.containerInput} onChange={(e) => setCouponCode(e.target.value)} disabled={cupomAplicado} />
                <Button className={styles.button} onClick={handleCupon} disabled={cupomAplicado}>
                  Aplicar Cupom
                </Button>
              </div>
            </div>
          ))}

        {urlPix != '' && (
          <div className={styles.containerPix}>
            <br />
            <div className={styles.borderPix}>
              <QRCode errorLevel='H' value={urlPix} icon={logoSvg} />
            </div>
            {
              <div className={styles.containerTotalValorNomes}>
                <p>{formatCurrency(grandTotal)}</p>
              </div>
            }
            <h1>Escaneie o QR Code e faça seu pix</h1>
            <p>Logo abaixo no resumo do pedido você pode acompanhar em tempo real a atualização do seu pagamento.</p>
            <h2>{paymentStatus}</h2>
            <h3>Verificação em {counter} segundos...</h3> {/* Contador de tempo */}
            <div className={styles.rowPix}>
              <Input value={urlPix} className={styles.inputPix} />
              <Button className={styles.buttonPix} onClick={handleCopyPixCode}>
                {copied ? 'Código Copiado!' : 'Código Copia e Cola'}
              </Button>
            </div>
          </div>
        )}
        {urlPix == '' && (
          <Checkbox onChange={(e) => setAceiteTermos(e.target.checked)}>
            Li e concordo com os &nbsp;
            <strong>Termos de Uso</strong>
          </Checkbox>
        )}

        {urlPix == '' && (
          <div className={styles.totalContainer}>
            <h1>Resumo do pedido</h1>
            {Object.keys(totals).map((nome) => (
              <div key={nome} className={styles.containerTotalValorNomes}>
                <h2>{nome}:</h2>
                <p>{formatCurrency(totals[nome])}</p>
              </div>
            ))}
            <div className={styles.containerTotalValorNomes}>
              {coupon && coupon.isValid && (
                <>
                  <h2 style={{ color: 'green' }}>CUPOM: </h2>
                  <p style={{ color: 'green' }}>
                    {couponCode} -{formatCurrency(desconto)}
                  </p>
                </>
              )}
            </div>
            <Divider style={{ width: '100%', borderColor: '#000' }} />
            <div className={styles.valorTotal}>
              <h3>Valor Total:</h3>
              <h5>{formatCurrency(grandTotal)}</h5>
            </div>
            <Button loading={loading} disabled={urlPix !== ''} className={styles.containerButtonProximo} onClick={handlePayCard}>
              Confirmar compra
            </Button>
          </div>
        )}

        <p className={styles.aviso}>Após finalizar a compra não é possível estorná-la</p>
      </div>
    </>
  );
};

export default Pix;
