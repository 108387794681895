import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { api } from '../../services/apiClient';
import styles from './style.module.scss';
import CardsMinhaConta from '../../Components/CardsMinhaConta';
import CardsValoresMinhaConta from '../../Components/CardsValoresMinhaConta';
import SolicitacoesResgate from '../../Components/SolicitacoesResgate';
import MeusBoloesPremios from '../../Components/MeusBoloesPremios';
import Perfil from '../../Components/Perfil';
import Compras from '../../Components/Compras';
import Saldo from '../../Components/Saldo';
import ExcluirConta from '../../Components/ExcluirConta';
import ResgatarPremios from '../../Components/ResgatarPremios';

const MinhaConta = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const destino = queryParams.get('tab');

  const [apostas, setApostas] = useState([]);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [usuarioSaldo, setUsuarioSaldo] = useState(null);
  const [showTableSolicitacoes, setShowTableSolicitacoes] = useState(false);
  const [showTableBoloes, setShowTableBoloes] = useState(false);
  const [showTablePremios, setShowTablePremios] = useState(false);
  const [showResgatarPremios, setShowResgatarPremios] = useState(false);
  const [cardSelected, setCardSelected] = useState(destino || 'padrao');

  // Referências para os elementos de destino
  const perfilRef = useRef(null);
  const comprasRef = useRef(null);
  const saldoRef = useRef(null);
  const excluirContaRef = useRef(null);

  useEffect(() => {
    setCardSelected(destino || 'padrao');
  }, [destino]);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  useEffect(() => {

    // Rolar para a seção apropriada com base no card selecionado
    switch (cardSelected) {
      case 'perfil':
        scrollToSection(perfilRef);
        break;
      case 'compras':
        console.log('compras')
        scrollToSection(comprasRef);
        break;
      case 'saldo':
        scrollToSection(saldoRef);
        break;
      case 'excluir':
        scrollToSection(excluirContaRef);
        break;
      default:
        break;
    }
  }, [cardSelected]);

  const fetchApostas = useCallback(async () => {
    try {
      const response = await api.get('/info_apostas/');
      setApostas(response.data);
    } catch (error) {
      console.log('Erro ao buscar apostas:', error.message);
      toast.error('Erro ao buscar apostas');
    }
  }, []);

  const fetchSolicitacoes = useCallback(async () => {
    try {
      const response = await api.get('/get_solicitacoes/');
      setSolicitacoes(response.data);
    } catch (error) {
      console.log('Erro ao buscar solicitações:', error.message);
      toast.error('Erro ao buscar solicitações');
    }
  }, []);

  const fetchUsuarioSaldo = useCallback(async () => {
    try {
      const response = await api.get('/saldo');
      setUsuarioSaldo(response.data.saldo);
    } catch (error) {
      console.log('Erro ao buscar saldo:', error.message);
      toast.error('Erro ao buscar saldo');
    }
  }, []);

  useEffect(() => {
    fetchApostas();
    fetchSolicitacoes();
    fetchUsuarioSaldo();
  }, [fetchApostas, fetchSolicitacoes, fetchUsuarioSaldo]);

  const handleSelect = useCallback(
    (item) => {
      navigate(`/minha-conta?tab=${item}`);
    },
    [navigate]
  );

  const cardContent = useMemo(() => {
    switch (cardSelected) {
      case 'perfil':
        return (
          <div ref={perfilRef}>
            <Perfil />
          </div>
        );
      case 'compras':
        return (
          <div ref={comprasRef}>
            <Compras handleSelect={handleSelect} comprasRef={comprasRef} scrollToSection={scrollToSection} setShowTableBoloes={setShowTableBoloes} setCardSelected={setCardSelected} />
          </div>
        );
      case 'saldo':
        return (
          <div ref={saldoRef}>
            <Saldo />
          </div>
        );
      case 'excluir':
        return (
          <div ref={excluirContaRef}>
            <ExcluirConta />
          </div>
        );
      default:
        return (
          <>
            <CardsValoresMinhaConta setShowResgatarPremios={setShowResgatarPremios} setShowTableBoloes={setShowTableBoloes} setShowTablePremios={setShowTablePremios} valorPremiacoes={apostas.premios_ganhos} totalApostas={apostas.total_apostas} />
            {showResgatarPremios ? (
              <ResgatarPremios />
            ) : (
              <>
                <SolicitacoesResgate showTable={showTableSolicitacoes} setShowTable={setShowTableSolicitacoes} solicitacoes={solicitacoes} />
                <MeusBoloesPremios showTableBoloes={showTableBoloes} setShowTableBoloes={setShowTableBoloes} showTablePremios={showTablePremios} setShowTablePremios={setShowTablePremios} />
              </>
            )}
          </>
        );
    }
  }, [cardSelected, apostas, showResgatarPremios, showTableSolicitacoes, solicitacoes, showTableBoloes, showTablePremios, handleSelect]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Minha conta</title>
      </Helmet>
      <h1>Painel do apostador</h1>
      <CardsMinhaConta handleSelect={handleSelect} setShowTableBoloes={setShowTableBoloes} />
      {cardContent}
    </div>
  );
};

export default MinhaConta;
