import React from 'react';
import styles from './style.module.scss';
import error from '../../assets/error.png';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log('error', error)
    // Verifica se o erro é relacionado ao ResizeObserver e o ignora
    if (error && error.message && error.message.includes('ResizeObserver loop completed with undelivered notifications')) {
      console.warn('Ignorando erro de ResizeObserver:', error);
      return;
    }

    // Logar outros erros
    console.error("ErrorBoundary capturou um erro", error, errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <img src={error} alt='logo' style={{ width: '300px', height: 'auto', marginBottom: '20px' }} />
          <h1>Ops, algo deu errado.</h1>
          <p>Estamos trabalhando para resolver o problema.</p>
          <p>Por favor, tente novamente mais tarde.</p>
          <button
            className={styles.button_error}
            onClick={() => window.location.href = '/'} // redireciona para a página inicial
          >
            Ir para a página inicial
          </button>
          {process.env.NODE_ENV === 'development' && (
            <div className={styles.errorDetails}>
              <p>{this.state.error && this.state.error.toString()}</p>
              <p>{this.state.errorInfo && this.state.errorInfo.componentStack}</p>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
