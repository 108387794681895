import { useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, Col, Row, Badge } from 'antd';
import styles from './style.module.scss';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { LuTicket } from 'react-icons/lu';
import CountDown from './CountDown';

const TabsBolao = ({ boloes, setSubBoloesSelect, activeKey, setActiveKey }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const containerRef = useRef(null);

  const isToday = (date) => {
    const today = new Date();
    const someDate = new Date(date);
    return someDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
  };

  const formatarDate = (date) => {
    if (isToday(date)) {
      return 'Hoje';
    } else {
      const d = new Date(date);
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }
  };

  const formatPrize = (value) => {
    let numberPart;
    let wordPart;

    if (value >= 1000000) {
      numberPart = (value / 1000000).toFixed(1);
      numberPart = parseFloat(numberPart).toLocaleString('pt-BR');
      wordPart = numberPart > 1 ? 'Milhões' : 'Milhão';
    } else if (value >= 1000) {
      numberPart = (value / 1000).toFixed(1);
      numberPart = parseFloat(numberPart).toLocaleString('pt-BR');
      wordPart = 'Mil';
    } else {
      numberPart = value.toString();
      wordPart = '';
    }

    return `R$ ${numberPart} ${wordPart}`.trim();
  };

  const hexToRGBA = (hex, opacity) => {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const handleTabChange = (key) => {
    navigate(`/bolao/${key}`, { replace: true });
    const selectedBolao = boloes.find((b) => b.id.toString() === key);
    if (selectedBolao) {
      setSubBoloesSelect(selectedBolao);
      setActiveKey(key);
    }
  };

  const sortedBoloes = [...boloes].sort((a, b) => b.especial - a.especial);

  const tabTitle = (nome, date, cor, premiacao) => (
    <Badge.Ribbon placement='end' text={formatPrize(premiacao)} offset={[40, 40]}>
      <div className={styles.containerTitleTab} style={{ background: cor }}>
        <h4>
          {nome}
          <p>
            {!isToday(date) && <FaRegCalendarAlt />}
            <span>{formatarDate(date)}</span>
          </p>
        </h4>
      </div>
    </Badge.Ribbon>
  );

  // Converte os bolões para o novo formato de itens
  const items = sortedBoloes.map((bolao) => ({
    key: bolao.id.toString(),
    label: tabTitle(bolao.nome, bolao.data_sorteio, bolao.cor, bolao.premiacao),
    children: (
      <Row
        className={styles.boloesGrid}
        style={{
          borderColor: bolao.cor,
          borderWidth: '1px',
          borderStyle: 'solid',
          background: bolao.cor,
          borderRadius: '0rem 0rem 0.59181rem 0.59181rem',
          width: '100%',
          overflow: 'visible',
        }}
      >
        <Col className={styles.col}>
          <div className={styles.bolaoName}>
            <h2 style={{ color: bolao.cor }}>{bolao.nome}</h2>
          </div>
          <div className={styles.bolaoName}>
            <h2 style={{ color: bolao.cor }}>{formatPrize(bolao.premiacao)}</h2>
          </div>
        </Col>
        <Col className={styles.col}>
          <div className={styles.bolaoName}>
            <LuTicket size={22} style={{ color: bolao.cor }} />
            <h2 style={{ color: bolao.cor }}>Concurso {bolao.concurso}</h2>
          </div>
          <div className={styles.bolaoName}>
            <FaRegCalendarAlt size={22} style={{ color: bolao.cor }} />
            <h2 style={{ color: bolao.cor }}>Sorteio {formatarDate(bolao.data_sorteio)}</h2>
          </div>
        </Col>
        <Col className={styles.col}>
          {bolao.acumulou === 1 && (
            <div className={styles.bolaoName}>
              <h2 style={{ color: bolao.cor }}>Acumulado</h2>
            </div>
          )}
          <CountDown targetDate={bolao.data_sorteio} />
        </Col>
      </Row>
    ),
  }));

  return (
    <div className={styles.container} ref={containerRef}>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        type='card'
        animated={{ tabPane: true }}
        onChange={handleTabChange}
        className={styles.tabContainer}
        items={items} // Usando o novo formato de itens
      />
    </div>
  );
};

export default TabsBolao;
