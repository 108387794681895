import React, { useState } from 'react';
import styles from './style.module.scss';
import { Button, Input } from 'antd';
import { CiUser } from 'react-icons/ci';
import { AiOutlineLock } from 'react-icons/ai';
import { BsArrowUpRight } from 'react-icons/bs';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';

const StepMinhConta = ({ items, handleNextStep }) => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const logged = await login(email, password);
      if (logged) {
        // Chama a função para ir para o próximo passo ao invés de redirecionar para a home
        handleNextStep();
        toast.success(`Login feito com sucesso!`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.warn(`Falha ao fazer login, confirme seus dados`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <img src={logo} alt='logo' />
      <div className={styles.containerInputs}>
        <label>E-mail, celular, CPF ou Cnpj</label>
        <Input value={email} onChange={handleEmailChange} className={styles.inputs} style={{ marginBottom: '1rem' }} size='large' placeholder='Insira seu E-mail, celular, CPF ou CPNPJ' prefix={<CiUser />} />
      </div>
      <div className={styles.containerInputs}>
        <label>Senha</label>
        <Input.Password value={password} onChange={handlePasswordChange} className={styles.inputs} size='large' placeholder='Senha' prefix={<AiOutlineLock />} />
      </div>

      <Button type="primary" htmlType="submit" loading={loading} className={styles.buttonEntrar} icon={<BsArrowUpRight />}>
        Entrar
      </Button>
      <h4>Não tem uma conta?</h4>
      <Button type="default" onClick={handleNextStep} loading={loading} className={styles.buttonCadastrar} icon={<BsArrowUpRight />}>
        Cadastre-se
      </Button>
      <h4>
        Esqueceu a senha?<Button>Clique aqui</Button>
      </h4>
      <h4>
        Esqueceu seu E-mail?<Button>Clique aqui e converse conosco</Button>
      </h4>
    </form>
  );
};

export default StepMinhConta;
