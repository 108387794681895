import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartProvider';
import { SaldoProvider } from './contexts/SaldoContext';

import SidebarOfficial from './Components/Sidebar';
import HeaderComponent from './Components/Header';
import FooterComponent from './Components/Footer';

import Login from './Pages/Login';
import Home from './Pages/Home';
import Bolao from './Pages/Bolao';
import Carrinho from './Pages/Carrinho';

import ptBR from 'antd/lib/locale/pt_BR';
import Resultados from './Pages/Resultados';
import Saldo from './Pages/Saldo';
import Avaliacao from './Pages/Avaliacao';
import Conheca from './Pages/Conheca';
import Cadastrar from './Pages/Cadastrar';
import MinhaConta from './Pages/MinhaConta';
import WelcomeMessage from './Components/WelcomeMessage';
import ErrorBoundary from './Components/ErrorBoundary';
import CompraConcluida from './Components/CompraConcluida';

import styles from './App.scss';
import TrocarSenha from './Pages/TrocarSenha';
import PoliticaPrivacidade from './Pages/PoliticaPrivacidade';
import TermoUso from './Pages/TermoUso';
import { api } from './services/apiClient';

const { Content, Header, Footer } = Layout;

const customLocale = {
  ...ptBR,
  Pagination: {
    ...ptBR.Pagination,
    items_per_page: '/ página',
    colorPrimary: '#00897F',
  },
};

const tokenConfig = {
  token: {
    colorPrimary: '#00897F',
    colorPrimaryBorder: 'rgba(0, 137, 127, 0.53)',
    colorPrimaryHover: '#00897F',
  },
};

const EmailConfirmation = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      const confirmEmail = async () => {
        try {
          const response = await api.post('/confirma_email', {
            token,
          });
          console.log('Email confirmado com sucesso:', response.data);
        } catch (error) {
          console.error('Erro ao confirmar o e-mail:', error);
        }
      };

      confirmEmail();
    }
  }, [location.search]);

  return null;
};

const App = () => {
  const { isAuthenticated, user } = useAuth();
  const [collapsed, setCollapsed] = useState(false); // Default state for sidebar
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const resetStepRef = useRef(() => {});
  const [deviceType, setDeviceType] = useState('desktop');
  const location = useLocation();

  useEffect(() => {
    const registerView = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const localidade = params.get('localidade'); // Captura o valor do parâmetro "localidade"
        console.log('params', params)
        console.log('localidade', localidade)

        if (localidade) {
          await api.post('/insert-view', {
            parametro: localidade, // Envia o valor de "localidade" como parâmetro
            numero_de_views: 1,
          });

          console.log('View registrada ou atualizada com sucesso.');
        }
      } catch (error) {
        console.log('error', error.message)
        console.error('Erro ao registrar a view:', error);
      }
    };

    registerView();
  }, [location]); // Executa o efeito sempre que a localização mudar

  const PrivateRoute = ({ children }) => {
    const { isAuthenticated, checkAuthentication } = useAuth();

    useEffect(() => {
      checkAuthentication();
    }, [checkAuthentication]);

    if (isAuthenticated === null) {
      return <div>Carregando...</div>;
    }

    return isAuthenticated ? children : <Login />;
  };

  return (
    <ErrorBoundary>
      <CartProvider>
        <AuthProvider>
          <SaldoProvider>
            <ConfigProvider locale={customLocale} theme={tokenConfig}>
              <Layout style={{ minHeight: '100vh' }}>
                <SidebarOfficial collapsed={collapsed} toggleCollapsed={toggleCollapsed} userLevel={user ? user.tipoUsuario : 'visitante'} />

                <Layout>
                  <HeaderComponent collapsed={collapsed} toggleCollapsed={toggleCollapsed} onResetStep={() => resetStepRef.current()} />
                  <Content className='containerLayout'>
                    <WelcomeMessage />
                    <EmailConfirmation />
                    <Routes>
                      <Route path='/' element={<Home deviceType={deviceType} />} />
                      <Route path='/bolao/:id?' element={<Bolao />} />
                      <Route path='carrinho' element={<Carrinho onResetStep={(resetStep) => (resetStepRef.current = resetStep)} />} />
                      <Route path='resultados' element={<Resultados />} />
                      <Route path='saldo' element={<Saldo />} />
                      <Route path='avaliacoes' element={<Avaliacao />} />
                      <Route path='conheca' element={<Conheca />} />
                      <Route path='login' element={<Login />} />
                      <Route path='cadastrar' element={<Cadastrar />} />
                      <Route path='alterarsenha' element={<TrocarSenha />} />
                      <Route path='politica-de-privacidade' element={<PoliticaPrivacidade />} />
                      <Route path='termos-de-uso' element={<TermoUso />} />
                      <Route
                        path='minha-conta'
                        element={
                          <PrivateRoute>
                            <MinhaConta />
                          </PrivateRoute>
                        }
                      />
                      <Route path='/compra_concluida' element={<CompraConcluida />} />
                    </Routes>
                    <ToastContainer />
                  </Content>
                </Layout>
              </Layout>
              <FooterComponent />
            </ConfigProvider>
          </SaldoProvider>
        </AuthProvider>
      </CartProvider>
    </ErrorBoundary>
  );
};

export default App;
