import React from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import { GiInjustice } from "react-icons/gi";

const TermoUso = () => {
  return (
    <div className={styles.container}>
      <Row justify='center'>
        <Col span={18}>
          <h1>TERMO DE USO</h1>
          <ol>
            <p><strong>1.</strong> O presente Instrumento e suas respectivas disposições passam a reger a relação entre a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI. ("Tribo da Sorte") e os usuários da Internet ("Usuários") que passem a utilizar seus serviços, após cadastro, disponibilizados no sitio (www.tribodasorteonline.com.br) ("Site").</p>

            <p><strong>2.</strong> O objeto dos presentes Termos e regulamentações se efetiva na prestação dos Serviços oferecidos através do site, e que consistem na realização, por conta e ordem dos Usuários, de serviços de assessoria em jogos lotéricos oficiais, formalizados da seguinte maneira:</p>
            <ol type="a">
              <li>Assessoria matemática na criação de jogos e bolões;</li>
              <li>Disponibilização de opções de jogos e bolões para aquisição pelo Usuário;</li>
              <li>Formalização do jogo lotérico em unidade autorizada pela Caixa Econômica Federal;</li>
              <li>Distribuição de premiação, quando houver.</li>
            </ol>
            <p><strong>3.</strong> A TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI, na qualidade de mandatária do Usuário, compromete-se a efetivar os jogos lotéricos regularmente indicados apenas junto a Casas Lotéricas credenciadas pela Caixa Econômica Federal, idôneas e que estejam atuando com autorização daquela, e com observância da legislação vigente.</p>
            <p><strong>4.</strong> A TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI formalizará os jogos lotéricos utilizando-se de ferramentas matemáticas que consistem na análise estatística e no estudo de combinações de resultados, com base no histórico da loteria.</p>
            <p><strong>5.</strong> O Usuário outorga poderes a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI para que esta, em seu nome, efetive junto à instituição lotérica oficial o jogo lotérico indicado.</p>
            <p><strong>6.</strong> O Usuário cadastrará uma senha de caráter pessoal e intransferível, de forma livre, devendo a senha conter um mínimo de 8 (oito) caracteres, de sua exclusiva responsabilidade.</p>
            <p><strong>7.</strong> Ao usar os Serviços da TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI, o Usuário afirma e garante que:</p>
            <ol type='a'>
              <li>É responsável pela veracidade, exatidão, vigência e autenticidade dos dados do cadastro por ele preenchido;</li>
              <li>Manterá a precisão de tais informações;</li>
              <li>Manterá a sua senha confidencial e será responsável por todo o uso da sua senha e conta;</li>
              <li>É maior de 18 anos</li>
              <li>Não agir de má fé, cometer irregularidades ou fraudes no processo de compra.</li>
              <li>A participação no bolão não garante premiação. Caso seja descoberta irregularidade no processo de compra, a participação será cancelada.</li>
            </ol>
            <p><strong>8.</strong> Na hipótese de ser fornecida qualquer informação que seja falsa, imprecisa, não atualizada ou incompleta, ou a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI tiver indícios justificados de que tais informações são falsas, imprecisas, não estão atualizadas ou são incompletas, a empresa poderá suspender ou encerrar a conta do Usuário.</p>
            <p><strong>9.</strong> O Usuário desde já declara ter ciência do teor do Site, incluindo todas as seções, ciente de que as seções podem conter informações relevantes sobre a utilização e funcionamento dos Serviços além do previsto neste Termo.</p>

            <p><strong>10.</strong> Ao concordar com os presentes Termos, o Usuário declara estar ciente da atuação e legalidade do Site, não tendo dúvidas e estando apto a participar dos sorteios das Loterias administradas pela Caixa Econômica Federal através do Site.</p>

            <p><strong>11.</strong> Eventuais dúvidas sobre os Serviços oferecidos poderão ser esclarecidas através do próprio Site, por meio de acesso ao menu "COMO PARTICIPAR" ou, ainda, através da Central de Atendimento ao Cliente (mediante Canal de Relacionamento indicado no site).</p>

            <p><strong>12.</strong> São obrigações da TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI:</p>
            <ol type='a'>
              <li>Zelar pela correta prestação dos serviços contratados;</li>
              <li>Comunicar o Usuário toda e qualquer situação que altere a natureza dos serviços prestados;</li>
              <li>Fazer o repasse de premiação, quando houver, seja diretamente, seja comunicando a Caixa Econômica Federal.</li>
            </ol>

            <p><strong>13.</strong> Pela prestação dos serviços oferecidos aos Usuários através do Site, à TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI, na qualidade de mandatária, será devido o montante fixado para cada jogo ou bolão, já incluído nesse valor a taxa cobrada pela Caixa Econômica Federal para efetivação do jogo.</p>

            <p><strong>14.</strong> Os pagamentos serão feitos mediante depósito ou transferência bancária, aplicativos , Mercado Pago, Paypal e PagSeguro, Cartões de crédito e boletos, na forma eletrônica ou presencial, e somente serão aceitos formas de depósitos e transferências regulamentados pelo Banco Central do Brasil.</p>

            <p><strong>15.</strong> Os pagamentos somente se converterão em créditos após a efetiva compensação e identificação pela equipe TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI ou Operadora Financeira contratada.</p>

            <p><strong>16.</strong> Os valores pagos pela prestação dos serviços não serão reembolsados.</p>

            <p><strong>17.</strong> As despesas com transferências bancárias, impostos ou outras porventura devidas em decorrência do pagamento de prêmio aos vencedores, serão deduzidas do pagamento da premiação.</p>

            <p><strong>18.</strong> Em caso de premiação, nenhum valor será devido à TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI a título de remuneração ou comissão.</p>

            <p><strong>19.</strong> Em caso de premiação, a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI comunicará o Usuário contemplado, que poderá, através do Site, indicar a destinação do valor do prêmio, podendo reutilizá-lo através de mais créditos ou ser creditado em conta mantida em instituição financeira devidamente autorizada a funcionar no Brasil pelo Banco Central do Brasil, observado o valor mínimo para resgate. O Usuário que fizer jus à premiação e não solicitar o resgate do prêmio terá o respectivo valor automaticamente convertido em créditos para reutilização no Site, podendo solicitar seu resgate quando desejar.</p>

            <p><strong>20.</strong> Os valores devidos a título de premiação somente serão transferidos para conta bancária ou carteira digital, de titularidade do Usuário, conforme cadastro realizado.</p>

            <p><strong>21.</strong> Em caso de cancelamento do cadastro, os créditos do usuário não serão reembolsados.</p>

            <p><strong>22.</strong> Caso o bolão, escolhido pelo Usuário, não atinja premiação, não compete ao Usuário exigir reembolso ou qualquer tipo de pagamento.</p>

            <p><strong>23.</strong> Todas as disposições aqui previstas, além de quaisquer outras regras contidas no Site constituem, doravante, as normas que regem a relação entre o Usuário e a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI, ficando desde já acordado que quaisquer outros entendimentos havidos entre o Usuário e a TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI não serão considerados para efeitos legais, declarando o Usuário, de forma irrevogável e irretratável, sua concordância com tudo o aqui descrito.</p>

            <p><strong>24.</strong> A incapacidade ou a impossibilidade da TRIBO DA SORTE CONSULTORIA E INTERMEDIAÇÃO EIRELI exercer ou fazer cumprir qualquer direito ou disposição ora prevista, ou ainda a tolerância com atos praticados pelo Usuário, não representam renúncia desse direito ou disposição, tratando-se de mera Novação.</p>

            <p><strong>25.</strong> A declaração de nulidade ou inaplicabilidade de que qualquer item, termo ou disposição ora previsto não implicará a nulidade ou inaplicabilidade de quaisquer outros itens, termos ou disposições aqui contidos, os quais permanecerão em plena vigor e cujos efeitos não serão atingidos.</p>

            <p><strong>26.</strong> As partes elegem o Foro da Comarca de Frutal no estado de Minas Gerais como sendo o único competente para dirimir quaisquer litígios e/ou demandas que venham a envolver as Partes em relação ao uso dos Serviços ora contratados.</p>

          </ol>
        </Col>
      </Row>
    </div>
  );
};

export default TermoUso;
