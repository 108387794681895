import React, { useState, useRef, useEffect } from 'react';
import styles from './style.module.scss';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Input, Form, Modal } from 'antd';
import { useAuth } from '../../contexts/AuthContext';
import { CiUser } from 'react-icons/ci';
import { AiOutlineLock } from 'react-icons/ai';
import { BsArrowUpRight } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { api } from '../../services/apiClient';
import { useSaldo } from '../../contexts/SaldoContext';

const Login = () => {
  const [form] = Form.useForm();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { fetchSaldo } = useSaldo();

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const { email, password } = values;
    try {
      const logged = await login(email, password);
      if (logged) {
        navigate('/');
        fetchSaldo();
        toast.success('Login feito com sucesso!');
        setLoading(false);
      } else {
        toast.warn('Falha ao fazer login, confirme seus dados');
        setLoading(false);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      setLoading(false);
    }
  };

  const handleForgotPassword = async (email) => {
    console.log(email);
    const enviarEmail = await api.post('/password/enviar', { email });
    console.log('enviarEmail', enviarEmail);
    if (enviarEmail.data.ok) {
      toast.success('E-mail enviado com sucesso');
      setIsModalVisible(false);
    }
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <img src={logo} alt='logo' />
      <Form form={form} onFinish={handleSubmit} layout='vertical' className={styles.form}>
        <Form.Item label='E-mail, celular, CPF ou CNPJ' className={styles.containerInputs} name='email' rules={[{ required: true, message: 'Por favor, insira seu e-mail ou telefone!' }]}>
          <Input className={styles.inputs} size='large' placeholder='Insira seu E-mail, celular, CPF ou CPNPJ' prefix={<CiUser />} />
        </Form.Item>
        <Form.Item label='Senha' name='password' className={styles.containerInputs} rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}>
          <Input.Password className={styles.inputs} size='large' placeholder='Senha' prefix={<AiOutlineLock />} />
        </Form.Item>

        <Button type='primary' htmlType='submit' loading={loading} className={styles.buttonEntrar} icon={<BsArrowUpRight />}>
          Entrar
        </Button>
      </Form>
      <h4>Não tem uma conta?</h4>
      <Link to='/cadastrar' className={styles.buttonCadastrar}>
        Cadastrar
      </Link>
      <h4>
        Esqueceu a senha?<Button onClick={showModal}>Clique aqui</Button>
      </h4>
      <Modal title={<h1 className={styles.header}>Recuperação de senha</h1>} open={isModalVisible} onCancel={handleCancel} footer={null} width={900}>
        <Form onFinish={({ email }) => handleForgotPassword(email)}>
          <Form.Item name='email' rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}>
            <Input placeholder='Digite seu e-mail' />
          </Form.Item>
          <Button className={styles.buttonEnviarSenha} htmlType='submit'>
            Enviar
          </Button>
        </Form>
      </Modal>
      <h4>
        Esqueceu seu E-mail?<Button>Clique aqui e converse conosco</Button>
      </h4>
    </div>
  );
};

export default Login;
